import React, { memo } from "react";

//components
import { GoHome } from "react-icons/go";
import { FaCarAlt } from "react-icons/fa";
import { BiMoney, BiBuildings, BiStore } from "react-icons/bi";

//utils
import "./../../pages/Homepage/Homepage.css";
import { useAste } from "../../context/useAste";
import { CHANGE_GENERE } from "../../context/actions";

const FilterRicercaGenere = memo(() => {
  const { asteState, asteDispatch } = useAste();

  const cardItems = [
    {
      text: "Beni Immobili",
      Icon: GoHome,
      value: "IMMOBILI",
    },
    {
      text: "Beni Mobili",
      Icon: FaCarAlt,
      value: "MOBILI",
    },
    {
      text: "Valori/Crediti",
      Icon: BiMoney,
      value: "VALORI/CREDITI",
    },
    {
      text: "Aziende",
      Icon: BiBuildings,
      value: "AZIENDE",
    },
    {
      text: "Altro",
      Icon: BiStore,
      value: "ALTRO",
    },
  ];

  const changeGenereHandler = (genere) => {
    asteDispatch({ type: CHANGE_GENERE, payload: genere });
  };



  return (
    <React.Fragment>
      <div className="container_filter">
        <div className="container_filter_box">
          <div className="section_filter_cards">
            {cardItems.map((item, index) => (
              <div
                key={index}
                className={`filter_item ${
                  asteState.genere === item.value ? "active" : ""
                }`}
                onClick={() => {
                  changeGenereHandler(item.value);
                }}
              >
                <div className="filter_icon">
                  <item.Icon />
                </div>
                <div className="filter_text">{item.text}</div>
              </div>
            ))}
          </div>
          
        </div>
      </div>
    </React.Fragment>
  );
});

export default FilterRicercaGenere;
