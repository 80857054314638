import { useMediaQuery } from "@mui/material";

export const useBreakPoints = () => {
  const sm = useMediaQuery("(min-width:600px)");
  const md = useMediaQuery("(min-width:900px)");

  return {
    sm,
    md,
  };
};
