import React, { useState, useEffect } from "react";
import { getTimer, pauseAsta, riavviaAsta } from '../services/timerServices'; // Assicurati che il percorso sia corretto

import { useAuth } from '../context/useAuth'

import { useAsta } from "../context/useAsta";

const dhm = (ms) => {
  const days = Math.floor(ms / (24 * 60 * 60 * 1000));
  const daysms = ms % (24 * 60 * 60 * 1000);
  const hours = Math.floor(daysms / (60 * 60 * 1000));
  const hoursms = ms % (60 * 60 * 1000);
  const minutes = Math.floor(hoursms / (60 * 1000));
  const minutesms = ms % (60 * 1000);
  const sec = Math.floor(minutesms / 1000);
  return `${days}d:${hours}h:${minutes}m:${sec}s`;
};

const CountDown = ({ astaId }) => {
  const [isActive, setIsActive] = useState(true);
  const { isReferente } = useAuth()
  const { asta, onTimeLeft, setTimeLeft } = useAsta()

  useEffect(() => {
    let timer;
    if (isActive) {
      timer = setInterval(async () => {
        try {
          const data = await getTimer(asta.idInserzioneEspVendita);
          setTimeLeft(data.timer);
          console.log('002 - ' + data.timer)

        } catch (error) {
          console.log(error);
        }
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [isActive]);

  useEffect(() => {
    const getInitialTime = async () => {
      try {
        const data = await getTimer(asta.idInserzioneEspVendita);
        console.log('001 - ' + data.timer)
        setTimeLeft(data.timer);
      } catch (error) {
        console.log(error);
      }
    };

    getInitialTime();
  }, []);

  return (
    <>
        {isReferente &&
      <button onClick={async () => {
            setIsActive(!isActive)
            isActive ?  await pauseAsta(asta.idInserzioneEspVendita): await riavviaAsta(asta.idInserzioneEspVendita)    
        }}>
        {isActive ? 'Disattiva' : 'Attiva'}
      </button>}
      {onTimeLeft !== 0 && <div>{dhm(onTimeLeft)}</div>}
      {onTimeLeft === 0 && <div>Gara terminata</div>}
    </>
  );
};

export default CountDown;
