import React, { useState, useEffect } from "react";

//hooks
import { useFormik } from "formik";
import { memo } from "react";

//@mui
import { DatePicker } from "@mui/x-date-pickers";
import { Button, Grid, MenuItem, TextField, Box } from "@mui/material";

//utils
import * as Yup from "yup";
import { NAZIONI, REGIONI } from "../../../../utils/DATA";
import { getComuni, getProvincie } from "../../../../services/formServices";
import "./OfferteCartacee.css";
import { toast } from "react-toastify";
import moment from "moment";
import accounting from "accounting";

const DatiOfferente = memo(
  ({
    offerenti,
    tabValue,
    aggiornaOfferente,
    aggiungiOfferente,
    setSelectedTab,
  }) => {
    const [provincie, setProvincie] = useState([]);
    const [comuni, setComuni] = useState([]);

    //***********************CHECK OFFERENTE GIA' ESISTE  ***************/
    const offerente = offerenti[tabValue - 3];

    const initialValues = {
      tipoOfferente: offerente?.tipoOfferente || "",

      //Dati Anagrafici
      nome: offerente?.datiAnagrafici?.nome || "",
      cognome: offerente?.datiAnagrafici?.cognome || "",
      codiceFiscale: offerente?.datiAnagrafici?.codiceFiscale || "",
      dataNascita: offerente
        ? moment(
            new Date(
              `${offerente?.datiAnagrafici?.meseNascita}/${offerente?.datiAnagrafici?.giornoNascita}/${offerente?.datiAnagrafici?.annoNascita}`
            )
          ).format("MM/DD/YYYY")
        : "",
      comuneNascita: offerente?.datiAnagrafici?.comuneNascita || "",
      comuneNascitaEstero: offerente?.datiAnagrafici?.comuneNascitaEstero || "",
      provinciaNascita: offerente?.datiAnagrafici?.provinciaNascita || "",
      regioneNascita: offerente?.datiAnagrafici?.regioneNascita || "",
      nazioneNascita: offerente?.datiAnagrafici?.nazioneNascita || "",
      cittadinanza1: offerente?.datiAnagrafici?.cittadinanza1 || "",
      cittadinanza2: offerente?.datiAnagrafici?.cittadinanza2 || "",
      partitaIva: offerente?.datiAnagrafici?.partitaIva || "",
      denominazione: offerente?.datiAnagrafici?.denominazione || "",

      //CONTATTI
      email: offerente?.contatti?.email || "",
      pec: offerente?.contatti?.pec || "",
      cellulare: offerente?.contatti?.cellulare || "",
      telefono: offerente?.contatti?.telefono || "",

      /************************ INDIRIZZO RESIDENZA ****************************************/

      indirizzoResidenza: offerente?.residenza?.indirizzoResidenza || "",
      nazioneResidenza: offerente?.residenza?.nazioneResidenza || "",

      //ITALIA
      civicoResidenza: offerente?.residenza?.civicoResidenza || "",
      comuneResidenza: offerente?.residenza?.comuneResidenza || "",
      provinciaResidenza: offerente?.residenza?.provinciaResidenza || "",
      regioneResidenza: offerente?.residenza?.regioneResidenza || "",
      capZipCodeResidenza: offerente?.residenza?.capZipCodeResidenza || "",

      //ESTERO
      comuneEsteroResidenza: offerente?.residenza?.comuneEsteroResidenza || "",
      capZipCodeEsteroResidenza:
        offerente?.residenza?.capZipCodeEsteroResidenza || "",

      /************************ INDIRIZZO DOMICILIO ****************************************/

      indirizzoDomicilio: offerente?.domicilio?.indirizzoDomicilio || "",
      nazioneDomicilio: offerente?.domicilio?.nazioneDomicilio || "",

      //ITALIA
      civicoDomicilio: offerente?.domicilio?.civicoDomicilio || "",
      comuneDomicilio: offerente?.domicilio?.comuneDomicilio || "",
      provinciaDomicilio: offerente?.domicilio?.provinciaDomicilio || "",
      regioneDomicilio: offerente?.domicilio?.regioneDomicilio || "",
      capZipCodeDomicilio: offerente?.domicilio?.capZipCodeDomicilio || "",

      //ESTERO
      comuneEsteroDomicilio: offerente?.domicilio?.comuneEsteroDomicilio || "",
      capZipCodeEsteroDomicilio:
        offerente?.domicilio?.capZipCodeEsteroDomicilio || "",

      dirittoProprieta: offerente?.dirittoProprieta || "",
      quotaPartecipazione: Number(offerente?.quotaPartecipazione) || "",
      titolo: offerente?.titolo || "",
    };

    const validationSchema = Yup.object({
      tipoOfferente: Yup.string().required("Inserisci tipo offerente"),

      //************************************* DATI ANAGRAFICI  **********************************/
      nazioneNascita: Yup.string().required("Inserisci nazione nascita"),
      cittadinanza1: Yup.string().required("Inserisci cittadinanza"),
      cittadinanza2: Yup.string(),
      nome: Yup.string().required("Inserisci nome"),
      cognome: Yup.string().required("Inserisci cognome"),
      dataNascita: Yup.string().required("Inserisci data di nascita"),
      codiceFiscale: Yup.string().required("Inserisci codice fiscale"),
      comuneNascitaEstero: Yup.string().when("nazioneNascita", {
        is: (nazioneNascita) => nazioneNascita !== "Italia",
        then: Yup.string().required("Inserisci comune estero"),
      }),
      regioneNascita: Yup.string().when("nazioneNascita", {
        is: (nazioneNascita) => nazioneNascita === "Italia",
        then: Yup.string().required("Inserisci regione "),
      }),
      provinciaNascita: Yup.string().when("nazioneNascita", {
        is: (nazioneNascita) => nazioneNascita === "Italia",
        then: Yup.string().required("Inserisci regione "),
      }),
      comuneNascita: Yup.string().when("nazioneNascita", {
        is: (nazioneNascita) => nazioneNascita === "Italia",
        then: Yup.string().required("Inserisci regione "),
      }),
      partitaIva: Yup.string().when("tipoOfferente", {
        is: (tipoOfferente) =>
          tipoOfferente === "persona giuridica o altra impresa collettiva",
        then: Yup.string().required("Inserisci partita iva "),
      }),
      denominazione: Yup.string().when("tipoOfferente", {
        is: (tipoOfferente) =>
          tipoOfferente === "altri enti collettivi diversi da impresa",
        then: Yup.string().required("Inserisci partita iva "),
      }),

      //************************************* CONTATTI  **********************************/
      email: Yup.string(),
      pec: Yup.string().required("Inserisci e-mail"),
      cellulare: Yup.string().required("Inserisci cellulare"),
      telefono: Yup.string(),

      /************************ INDIRIZZO RESIDENZA ****************************************/

      indirizzoResidenza: Yup.string().required(
        "Inserisci indirizzo residenza"
      ),
      nazioneResidenza: Yup.string().required("Inserisci nazione residenza"),

      //RESIDENZA--ITALIA
      civicoResidenza: Yup.string(),
      comuneResidenza: Yup.string(),
      provinciaResidenza: Yup.string(),
      regioneResidenza: Yup.string(),
      capZipCodeResidenza: Yup.string(),

      //RESIDENZA--ESTERO
      comuneEsteroResidenza: Yup.string(),
      capZipCodeEsteroResidenza: Yup.string(),

      /************************ INDIRIZZO DOMICILIO ****************************************/
      indirizzoDomicilio: Yup.string().required(
        "Inserisci indirizzo domicilio"
      ),
      nazioneDomicilio: Yup.string().required("Inserisci nazione domicilio"),

      //RESIDENZA--ITALIA
      civicoDomicilio: Yup.string(),
      comuneDomicilio: Yup.string(),
      provinciaDomicilio: Yup.string(),
      regioneDomicilio: Yup.string(),
      capZipCodeDomicilio: Yup.string(),

      //RESIDENZA--ESTERO
      comuneEsteroDomicilio: Yup.string(),
      capZipCodeEsteroDomicilio: Yup.string(),

      dirittoProprieta: Yup.string().required("Inserisci diritto proprietà"),
      quotaPartecipazione: Yup.string().required(
        "Inserisci quota partecipazione"
      ),
      titolo: Yup.string().required("Inserisci titolo"),
    });

    const submitHandler = (value) => {
      //***********************CHECK OFFERENTE GIA' ESISTE  ***************/
      const offerenteEsistente = offerenti[tabValue - 3];

      const checkDateNascita = moment(value.dataNascita).format("DD/MM/YYYY");

      const arrayData = checkDateNascita.split("/");
      var meseNascita = Number(arrayData[1]);
      var giornoNascita = Number(arrayData[0]);
      var annoNascita = Number(arrayData[2]);

      const offerenteData = {
        datiAnagrafici: {
          nome: value.nome,
          cognome: value.cognome,
          codiceFiscale: value.codiceFiscale,
          meseNascita,
          giornoNascita,
          annoNascita,
          comuneNascita: value.comuneNascita,
          comuneNascitaEstero: value.comuneNascitaEstero,
          provinciaNascita: value.provinciaNascita,
          regioneNascita: value.regioneNascita,
          nazioneNascita: value.nazioneNascita,
          cittadinanza1: value.cittadinanza1,
          cittadinanza2: value.cittadinanza2,
          partitaIva: value.partitaIva,
          denominazione: value.denominazione,
        },
        contatti: {
          email: value.email,
          pec: value.pec,
          cellulare: value.cellulare,
          telefono: value.telefono,
        },
        residenza: {
          indirizzoResidenza: value.indirizzoResidenza,
          nazioneResidenza: value.nazioneResidenza,
          civicoResidenza:value.civicoResidenza,
          comuneResidenza:
            value.nazioneResidenza === "Italia" ? value.comuneResidenza : "",
          provinciaResidenza:
            value.nazioneResidenza === "Italia"
              ? value.provinciaResidenza
              : "",
          regioneResidenza:
            value.nazioneResidenza === "Italia" ? value.regioneResidenza : "",
          capZipCodeResidenza:
            value.nazioneResidenza === "Italia"
              ? value.capZipCodeResidenza
              : "",
          comuneEsteroResidenza:
            value.nazioneResidenza !== "Italia"
              ? value.comuneEsteroResidenza
              : "",
          capZipCodeEsteroResidenza:
            value.nazioneResidenza !== "Italia"
              ? value.capZipCodeEsteroResidenza
              : "",
        },
        domicilio: {
          indirizzoDomicilio: value.indirizzoDomicilio,
          nazioneDomicilio: value.nazioneDomicilio,
          civicoDomicilio:
            value.civicoDomicilio,
          comuneDomicilio:
            value.nazioneDomicilio === "Italia" ? value.comuneDomicilio : "",
          provinciaDomicilio:
            value.nazioneDomicilio === "Italia"
              ? value.provinciaDomicilio
              : "",
          regioneDomicilio:
            value.nazioneDomicilio === "Italia" ? value.regioneDomicilio : "",
          capZipCodeDomicilio:
            value.nazioneDomicilio === "Italia"
              ? value.capZipCodeDomicilio
              : "",
          comuneEsteroDomicilio:
            value.nazioneDomicilio !== "Italia"
              ? value.comuneEsteroDomicilio
              : "",
          capZipCodeEsteroDomicilio:
            value.nazioneDomicilio !== "Italia"
              ? value.capZipCodeEsteroDomicilio
              : "",
        },
        tipoOfferente: value.tipoOfferente,
        dirittoProprieta: value.dirittoProprieta,
        quotaPartecipazione: accounting.formatMoney(
          String(value.quotaPartecipazione),
          " ",
          1
        ),
        titolo: value.titolo,
      };

      if (offerenteEsistente) {
        aggiornaOfferente(offerenteData, tabValue-3);

        toast.success("L'offerente è stato aggiornato");
      } else {
        aggiungiOfferente(offerenteData);
        toast.success("L'offerente è stato aggiunto");
      }

      setSelectedTab("2");
    };

    const formik = useFormik({
      initialValues: initialValues,
      validationSchema: validationSchema,
      onSubmit: submitHandler,
    });

    const { setFieldValue } = formik;

    useEffect(() => {
      (async () => {
        if (formik.values.regioneNascita !== "") {
          setFieldValue("provinciaNascita", "");
          setFieldValue("comuneNascita", "");

          try {
            const data = await getProvincie(formik.values.regioneNascita);
            setProvincie(data);
          } catch (error) {
            console.log(error);
          }
        }
      })();
    }, [formik.values.regioneNascita, setFieldValue]);

    useEffect(() => {
      (async () => {
        if (formik.values.provinciaNascita !== "") {
          setFieldValue("comuneNascita", "");
          try {
            const data = await getComuni(formik.values.provinciaNascita);
            setComuni(data);
          } catch (error) {
            console.log(error);
          }
        }
      })();
    }, [formik.values.provinciaNascita, setFieldValue]);

    return (
      <form onSubmit={formik.handleSubmit}>
        <div className="modal_title_presentatore">Tipo offerente</div>
        <Grid container padding={3} spacing={2}>
          <Grid item xs={12} md={12}>
            <TextField
              select
              className="select"
              label="Tipo Offerente"
              name="tipoOfferente"
              value={formik.values.tipoOfferente}
              onChange={formik.handleChange}
              helperText={
                formik.touched.tipoOfferente && formik.errors.tipoOfferente
              }
              error={
                formik.touched.tipoOfferente &&
                Boolean(formik.errors.tipoOfferente)
              }
              fullWidth
            >
              <MenuItem value="persona fisica">Persona Fisica</MenuItem>
              <MenuItem value="persona giuridica o altra impresa collettiva">
                Persona giuridica o altra impresa collettiva
              </MenuItem>
              <MenuItem value="altri enti collettivi diversi da impresa">
                Altri enti collettivi diversi da impresa
              </MenuItem>
            </TextField>
          </Grid>
        </Grid>

        <div className="modal_title_presentatore">Dati Anagrafici</div>
        <Grid container padding={3} spacing={3}>
          <Grid item xs={12} md={4}>
            <TextField
              id="nome"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.nome}
              label={
                formik.values.tipoOfferente === "persona fisica"
                  ? "Nome"
                  : "Nome Rappresentante Legale"
              }
              error={formik.touched.nome && Boolean(formik.errors.nome)}
              helperText={formik.touched.nome && formik.errors.nome}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              id="cognome"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.cognome}
              label={
                formik.values.tipoOfferente === "persona fisica"
                  ? "Cognome"
                  : "Cognome Rappresentante Legale"
              }
              error={formik.touched.cognome && Boolean(formik.errors.cognome)}
              helperText={formik.touched.cognome && formik.errors.cognome}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              id="codiceFiscale"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.codiceFiscale}
              label="Codice Fiscale"
              error={
                formik.touched.codiceFiscale &&
                Boolean(formik.errors.codiceFiscale)
              }
              helperText={
                formik.touched.codiceFiscale && formik.errors.codiceFiscale
              }
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              select
              className="select"
              label="Nazione di nascita"
              name="nazioneNascita"
              value={formik.values.nazioneNascita}
              onChange={formik.handleChange}
              helperText={
                formik.touched.nazioneNascita && formik.errors.nazioneNascita
              }
              error={
                formik.touched.nazioneNascita &&
                Boolean(formik.errors.nazioneNascita)
              }
              fullWidth
            >
              {NAZIONI.map((nazione) => (
                <MenuItem value={nazione.name} key={nazione.id}>
                  {" "}
                  {nazione.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              select
              className="select"
              label="Paese di cittadinanza 1"
              name="cittadinanza1"
              value={formik.values.cittadinanza1}
              onChange={formik.handleChange}
              helperText={
                formik.touched.cittadinanza1 && formik.errors.cittadinanza1
              }
              error={
                formik.touched.cittadinanza1 &&
                Boolean(formik.errors.cittadinanza1)
              }
              fullWidth
            >
              {NAZIONI.map((nazione) => (
                <MenuItem value={nazione.name} key={nazione.id}>
                  {" "}
                  {nazione.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              select
              className="select"
              label="Paese di cittadinanza 2"
              name="cittadinanza2"
              value={formik.values.cittadinanza2}
              onChange={formik.handleChange}
              helperText={
                formik.touched.cittadinanza2 && formik.errors.cittadinanza2
              }
              error={
                formik.touched.cittadinanza2 &&
                Boolean(formik.errors.cittadinanza2)
              }
              fullWidth
            >
              {NAZIONI.map((nazione) => (
                <MenuItem value={nazione.name} key={nazione.id}>
                  {" "}
                  {nazione.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={12}>
            <DatePicker
              label="Data di Nascita"
              inputFormat="dd/MM/yyyy"
              name="dataNascita"
              toolbarPlaceholder="Data Nascita"
              id="dataNascita"
              value={formik.values.dataNascita}
              onChange={(val) => formik.setFieldValue("dataNascita", val)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={
                    formik.touched.dataNascita &&
                    Boolean(formik.errors.dataNascita)
                  }
                  fullWidth
                  helperText={
                    formik.touched.dataNascita && formik.errors.dataNascita
                  }
                />
              )}
            />
          </Grid>
          {formik.values.nazioneNascita === "Italia" && (
            <>
              <Grid item xs={12} md={4}>
                <TextField
                  select
                  className="select"
                  label="Regione Nascita"
                  name="regioneNascita"
                  value={formik.values.regioneNascita}
                  onChange={formik.handleChange}
                  helperText={
                    formik.touched.regioneNascita &&
                    formik.errors.regioneNascita
                  }
                  error={
                    formik.touched.regioneNascita &&
                    Boolean(formik.errors.regioneNascita)
                  }
                  defaultValue=""
                  fullWidth
                >
                  {REGIONI.map((regione) => (
                    <MenuItem value={regione.nome} key={regione.id}>
                      {" "}
                      {regione.nome}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  select
                  className="select"
                  label="Provincia Nascita"
                  name="provinciaNascita"
                  value={formik.values.provinciaNascita}
                  onChange={formik.handleChange}
                  helperText={
                    formik.touched.provinciaNascita &&
                    formik.errors.provinciaNascita
                  }
                  error={
                    formik.touched.provinciaNascita &&
                    Boolean(formik.errors.provinciaNascita)
                  }
                  defaultValue=""
                  fullWidth
                >
                  {provincie &&
                    provincie.map((provincia) => (
                      <MenuItem value={provincia.nome} key={provincia.codice}>
                        {" "}
                        {provincia.nome}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  select
                  className="select"
                  label="Comune Nascita"
                  name="comuneNascita"
                  value={formik.values.comuneNascita}
                  onChange={formik.handleChange}
                  helperText={
                    formik.touched.comuneNascita && formik.errors.comuneNascita
                  }
                  error={
                    formik.touched.comuneNascita &&
                    Boolean(formik.errors.comuneNascita)
                  }
                  fullWidth
                  defaultValue=""
                >
                  {comuni &&
                    comuni.map((comune) => (
                      <MenuItem value={comune.nome} key={comune.codice}>
                        {" "}
                        {comune.nome}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
            </>
          )}
          {formik.values.nazioneNascita !== "Italia" && (
            <>
              <Grid item xs={12} md={12}>
                <TextField
                  type={"text"}
                  label="Comune Nascita Estero"
                  name="comuneNascitaEstero"
                  value={formik.values.comuneNascitaEstero}
                  onChange={formik.handleChange}
                  helperText={
                    formik.touched.comuneNascitaEstero &&
                    formik.errors.comuneNascitaEstero
                  }
                  error={
                    formik.touched.comuneNascitaEstero &&
                    Boolean(formik.errors.comuneNascitaEstero)
                  }
                  fullWidth
                ></TextField>
              </Grid>
            </>
          )}

          {formik.values.tipoOfferente !== "persona fisica" && (
            <React.Fragment>
              <Grid item xs={12} md={6}>
                <TextField
                  id="partitaIva"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.partitaIva}
                  label="Partita Iva"
                  error={
                    formik.touched.partitaIva &&
                    Boolean(formik.errors.partitaIva)
                  }
                  helperText={
                    formik.touched.partitaIva && formik.errors.partitaIva
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="denominazione"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.denominazione}
                  label="Denominazione"
                  error={
                    formik.touched.denominazione &&
                    Boolean(formik.errors.denominazione)
                  }
                  helperText={
                    formik.touched.denominazione && formik.errors.denominazione
                  }
                  fullWidth
                />
              </Grid>
            </React.Fragment>
          )}
        </Grid>

        {/*********************** CONTATTI ********************/}

        <div className="modal_title_presentatore">Contatti</div>
        <Grid container padding={3} spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              type={"email"}
              label="Pec"
              name="pec"
              value={formik.values.pec}
              onChange={formik.handleChange}
              helperText={formik.touched.pec && formik.errors.pec}
              error={formik.touched.pec && Boolean(formik.errors.pec)}
              fullWidth
            ></TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              type={"email"}
              label="Email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              helperText={formik.touched.email && formik.errors.email}
              error={formik.touched.email && Boolean(formik.errors.email)}
              fullWidth
            ></TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              type={"text"}
              label="Cellulare"
              name="cellulare"
              value={formik.values.cellulare}
              onChange={formik.handleChange}
              helperText={formik.touched.cellulare && formik.errors.cellulare}
              error={
                formik.touched.cellulare && Boolean(formik.errors.cellulare)
              }
              fullWidth
            ></TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              type={"text"}
              label="Telefono"
              name="telefono"
              value={formik.values.telefono}
              onChange={formik.handleChange}
              helperText={formik.touched.telefono && formik.errors.telefono}
              error={formik.touched.telefono && Boolean(formik.errors.telefono)}
              fullWidth
            ></TextField>
          </Grid>
        </Grid>

        {/*********************** INDIRIZZI DOMICILIO ********************/}

        <div className="modal_title_presentatore">Indirizzi</div>
        <Grid container padding={3} spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              type={"text"}
              label="Indirizzo Domicilio"
              name="indirizzoDomicilio"
              value={formik.values.indirizzoDomicilio}
              onChange={formik.handleChange}
              helperText={
                formik.touched.indirizzoDomicilio &&
                formik.errors.indirizzoDomicilio
              }
              error={
                formik.touched.indirizzoDomicilio &&
                Boolean(formik.errors.indirizzoDomicilio)
              }
              fullWidth
            ></TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              type={"text"}
              label="Civico Domicilio"
              name="civicoDomicilio"
              value={formik.values.civicoDomicilio}
              onChange={formik.handleChange}
              helperText={
                formik.touched.civicoDomicilio && formik.errors.civicoDomicilio
              }
              error={
                formik.touched.civicoDomicilio &&
                Boolean(formik.errors.civicoDomicilio)
              }
              fullWidth
            ></TextField>
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              select
              className="select"
              label="Nazione Domicilio"
              name="nazioneDomicilio"
              value={formik.values.nazioneDomicilio}
              onChange={formik.handleChange}
              helperText={
                formik.touched.nazioneDomicilio &&
                formik.errors.nazioneDomicilio
              }
              error={
                formik.touched.nazioneDomicilio &&
                Boolean(formik.errors.nazioneDomicilio)
              }
              fullWidth
            >
              {NAZIONI.map((nazione) => (
                <MenuItem value={nazione.name} key={nazione.id}>
                  {" "}
                  {nazione.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {formik.values.nazioneDomicilio === "Italia" ? (
            <>
              <Grid item xs={12} md={6}>
                <TextField
                  type={"text"}
                  label="Cap"
                  name="capZipCodeDomicilio"
                  value={formik.values.capZipCodeDomicilio}
                  onChange={formik.handleChange}
                  helperText={
                    formik.touched.capZipCodeDomicilio &&
                    formik.errors.capZipCodeDomicilio
                  }
                  error={
                    formik.touched.capZipCodeDomicilio &&
                    Boolean(formik.errors.capZipCodeDomicilio)
                  }
                  fullWidth
                ></TextField>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  type={"text"}
                  label="Regione Domicilio"
                  name="regioneDomicilio"
                  value={formik.values.regioneDomicilio}
                  onChange={formik.handleChange}
                  helperText={
                    formik.touched.regioneDomicilio &&
                    formik.errors.regioneDomicilio
                  }
                  error={
                    formik.touched.regioneDomicilio &&
                    Boolean(formik.errors.regioneDomicilio)
                  }
                  fullWidth
                ></TextField>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  type={"text"}
                  label="Comune Domicilio"
                  name="comuneDomicilio"
                  value={formik.values.comuneDomicilio}
                  onChange={formik.handleChange}
                  helperText={
                    formik.touched.comuneDomicilio &&
                    formik.errors.comuneDomicilio
                  }
                  error={
                    formik.touched.comuneDomicilio &&
                    Boolean(formik.errors.comuneDomicilio)
                  }
                  fullWidth
                ></TextField>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  type={"text"}
                  label="Provincia Domicilio"
                  name="provinciaDomicilio"
                  value={formik.values.provinciaDomicilio}
                  onChange={formik.handleChange}
                  helperText={
                    formik.touched.provinciaDomicilio &&
                    formik.errors.provinciaDomicilio
                  }
                  error={
                    formik.touched.provinciaDomicilio &&
                    Boolean(formik.errors.provinciaDomicilio)
                  }
                  fullWidth
                ></TextField>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12} md={6}>
                <TextField
                  type={"text"}
                  label="Comune Estero Domicilio"
                  name="comuneEsteroDomicilio"
                  value={formik.values.comuneEsteroDomicilio}
                  onChange={formik.handleChange}
                  fullWidth
                ></TextField>
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  type={"text"}
                  label="Cap"
                  name="capZipCodeEsteroDomicilio"
                  value={formik.values.capZipCodeEsteroDomicilio}
                  onChange={formik.handleChange}
                  fullWidth
                ></TextField>
              </Grid>
            </>
          )}
        </Grid>

        {/*********************** INDIRIZZI RESIDENZA ********************/}

        <div className="modal_title_presentatore">Residenza</div>
        <Grid container padding={3} spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              type={"text"}
              label="Indirizzo Residenza"
              name="indirizzoResidenza"
              value={formik.values.indirizzoResidenza}
              onChange={formik.handleChange}
              helperText={
                formik.touched.indirizzoResidenza &&
                formik.errors.indirizzoResidenza
              }
              error={
                formik.touched.indirizzoResidenza &&
                Boolean(formik.errors.indirizzoResidenza)
              }
              fullWidth
            ></TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              type={"text"}
              label="Civico Residenza"
              name="civicoResidenza"
              value={formik.values.civicoResidenza}
              onChange={formik.handleChange}
              helperText={
                formik.touched.civicoResidenza && formik.errors.civicoResidenza
              }
              error={
                formik.touched.civicoResidenza &&
                Boolean(formik.errors.civicoResidenza)
              }
              fullWidth
            ></TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              select
              className="select"
              label="Nazione Residenza"
              name="nazioneResidenza"
              value={formik.values.nazioneResidenza}
              onChange={formik.handleChange}
              helperText={
                formik.touched.nazioneResidenza &&
                formik.errors.nazioneResidenza
              }
              error={
                formik.touched.nazioneResidenza &&
                Boolean(formik.errors.nazioneResidenza)
              }
              fullWidth
            >
              {NAZIONI.map((nazione) => (
                <MenuItem value={nazione.name} key={nazione.id}>
                  {" "}
                  {nazione.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {formik.values.nazioneResidenza === "Italia" ? (
            <>
              <Grid item xs={12} md={6}>
                <TextField
                  type={"text"}
                  label="Cap"
                  name="capZipCodeResidenza"
                  value={formik.values.capZipCodeResidenza}
                  onChange={formik.handleChange}
                  fullWidth
                ></TextField>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  type={"text"}
                  label="Regione Residenza"
                  name="regioneResidenza"
                  value={formik.values.regioneResidenza}
                  onChange={formik.handleChange}
                  fullWidth
                ></TextField>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  type={"text"}
                  label="Comune Residenza"
                  name="comuneResidenza"
                  value={formik.values.comuneResidenza}
                  onChange={formik.handleChange}
                  fullWidth
                ></TextField>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  type={"text"}
                  label="Provincia Residenza"
                  name="provinciaResidenza"
                  value={formik.values.provinciaResidenza}
                  onChange={formik.handleChange}
                  helperText={
                    formik.touched.provinciaResidenza &&
                    formik.errors.provinciaResidenza
                  }
                  error={
                    formik.touched.provinciaResidenza &&
                    Boolean(formik.errors.provinciaResidenza)
                  }
                  fullWidth
                ></TextField>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12} md={6}>
                <TextField
                  type={"text"}
                  label="Comune Estero Residenza"
                  name="comuneEsteroResidenza"
                  value={formik.values.comuneEsteroResidenza}
                  onChange={formik.handleChange}
                  fullWidth
                ></TextField>
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  type={"text"}
                  label="Cap"
                  name="capZipCodeEsteroResidenza"
                  value={formik.values.capZipCodeEsteroResidenza}
                  onChange={formik.handleChange}
                  fullWidth
                ></TextField>
              </Grid>
            </>
          )}
        </Grid>

        <div className="modal_title_presentatore">Quota di partecipazione </div>
        <Grid container padding={3} spacing={2}>
          <Grid item xs={12} md={12}>
            <TextField
              select
              className="select"
              label="Diritto Proprietà"
              name="dirittoProprieta"
              value={formik.values.dirittoProprieta}
              onChange={formik.handleChange}
              helperText={
                formik.touched.dirittoProprieta &&
                formik.errors.dirittoProprieta
              }
              error={
                formik.touched.dirittoProprieta &&
                Boolean(formik.errors.dirittoProprieta)
              }
              fullWidth
            >
              <MenuItem value="Proprieta">Proprietà</MenuItem>

              <MenuItem value="Nuda_Proprieta">Nuda proprietà</MenuItem>
              <MenuItem value="Proprieta_Superficiaria">
                Proprietà superficiaria
              </MenuItem>
              <MenuItem value="Usufrutto">Usufrutto</MenuItem>
              <MenuItem value="Usufrutto_Su_Proprieta_Superficiaria">
                Usufrutto su proprietà superficiaria
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              type="number"
              label="Quota Partecipazione"
              name="quotaPartecipazione"
              value={formik.values.quotaPartecipazione}
              onChange={formik.handleChange}
              InputProps={{ inputProps: { min: 0, max: 100, step: 1 } }}
              helperText={
                formik.touched.quotaPartecipazione &&
                formik.errors.quotaPartecipazione
              }
              error={
                formik.touched.quotaPartecipazione &&
                Boolean(formik.errors.quotaPartecipazione)
              }
              fullWidth
            ></TextField>
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              select
              className="select"
              label="Titolo"
              name="titolo"
              value={formik.values.titolo}
              onChange={formik.handleChange}
              helperText={formik.touched.titolo && formik.errors.titolo}
              error={formik.touched.titolo && Boolean(formik.errors.titolo)}
              fullWidth
            >
              <MenuItem value={"A_TITOLO_PERSONALE"}>
                A titolo Personale
              </MenuItem>
              <MenuItem value={"IN_QUALITA_DI_LEGALE_RAPPRESENTANTE"}>
                In qualità di Legale Rappresentante
              </MenuItem>
              <MenuItem value={"PER_PERSONA_DA_NOMINARE"}>
                Per Persona da nominare (solo avvocati)
              </MenuItem>
              <MenuItem value={"PROCURATORE_SPECIALE"}>
                Procuratore Speciale
              </MenuItem>
              <MenuItem value={"TUTORE_LEGALE"}>Tutore Legale</MenuItem>
              <MenuItem value={"ALTRO"}>ALTRO</MenuItem>
            </TextField>
          </Grid>
        </Grid>

        <Box display={"flex"} gap={2} justifyContent={"flex-end"}>
          <Button color="primary" variant="contained" type="submit">
            {" "}
            {offerente ? "Aggiorna Offerente" : "Salva Offerente"}
          </Button>
        </Box>
      </form>
    );
  }
);

export default DatiOfferente;
