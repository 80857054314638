import React, { memo, useState } from "react";

//@mui
import { Button, TextField, IconButton, InputAdornment } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

//hooks
import { useFormik } from "formik";
import { useAuth } from "../../context/useAuth";
import { useNavigate } from "react-router-dom";

//utils
import { authErrorHandler } from "../../utils/Errors";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "../../pages/Login/Login.css";

const LoginForm = memo(({ forgotPasswordHandler }) => {
  
  const [showPassword, setShowPassword] = useState(false);
  const navigate= useNavigate()
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const { loginHandler } = useAuth();

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string().required("Inserisci email"),
    password: Yup.string().required("Inserisci la password"),
  });

  const submitHandler = async (values) => {
    const response = await loginHandler(values.email, values.password);
    if (!response.error) {
      if(response.emailVerified){
        toast.success("Benvenuto in Astalex");
      }else{
        toast.warning("Attenzione, ricordati di verificare la e-mail");
      }
      navigate("/")
    } else {
      const { code } = response.message;
      console.log(response);
      toast.error(authErrorHandler(code));
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: submitHandler,
  });

  const forgotPassword = () => {
    forgotPasswordHandler();
  };

  return (
    <form className="login_form" onSubmit={formik.handleSubmit}>
      <div className="login_container">
        <div className="login_title">EFFETTUA ACCESSO</div>

        <TextField
          id="email"
          type="email"
          onChange={formik.handleChange}
          value={formik.values.email}
          label="Inserisci Email"
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          fullWidth
        />

        <TextField
          id="password"
          type={showPassword ? "text" : "password"}
          onChange={formik.handleChange}
          value={formik.values.password}
          label="Inserisci Password"
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Button type="submit" variant="contained" fullWidth>
          ENTRA
        </Button>
        <p onClick={forgotPassword}> Hai dimenticato la tua password?</p>
      </div>
    </form>
  );
});

export default LoginForm;
