// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal_box {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 1rem;
    outline: none;
    padding: 1rem;
  }
  
  .modal_box .modal_box_buttons {
    display: flex;
    margin: 1rem 0;
    justify-content: flex-end;
    align-items: center;
  }
  
  @media screen and (max-width: 600px) {
    .modal_box {
      position: absolute;
      left: 0;
      right: 0;
      transform: translate(0);
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/utils/CustomModal/CustomModal.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,aAAa;EACf;;EAEA;IACE,aAAa;IACb,cAAc;IACd,yBAAyB;IACzB,mBAAmB;EACrB;;EAEA;IACE;MACE,kBAAkB;MAClB,OAAO;MACP,QAAQ;MACR,uBAAuB;IACzB;EACF","sourcesContent":[".modal_box {\n    position: absolute;\n    top: 30%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    background-color: white;\n    border-radius: 1rem;\n    outline: none;\n    padding: 1rem;\n  }\n  \n  .modal_box .modal_box_buttons {\n    display: flex;\n    margin: 1rem 0;\n    justify-content: flex-end;\n    align-items: center;\n  }\n  \n  @media screen and (max-width: 600px) {\n    .modal_box {\n      position: absolute;\n      left: 0;\n      right: 0;\n      transform: translate(0);\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
