export const NAZIONI = [
  { id: 4, alpha2: "af", alpha3: "afg", name: "Afghanistan" },
  { id: 8, alpha2: "al", alpha3: "alb", name: "Albania" },
  { id: 12, alpha2: "dz", alpha3: "dza", name: "Algeria" },
  { id: 20, alpha2: "ad", alpha3: "and", name: "Andorra" },
  { id: 24, alpha2: "ao", alpha3: "ago", name: "Angola" },
  { id: 660, alpha2: "ai", alpha3: "aia", name: "Anguilla" },
  { id: 10, alpha2: "aq", alpha3: "ata", name: "Antartide" },
  { id: 28, alpha2: "ag", alpha3: "atg", name: "Antigua e Barbuda" },
  { id: 682, alpha2: "sa", alpha3: "sau", name: "Arabia Saudita" },
  { id: 32, alpha2: "ar", alpha3: "arg", name: "Argentina" },
  { id: 51, alpha2: "am", alpha3: "arm", name: "Armenia" },
  { id: 533, alpha2: "aw", alpha3: "abw", name: "Aruba" },
  { id: 36, alpha2: "au", alpha3: "aus", name: "Australia" },
  { id: 40, alpha2: "at", alpha3: "aut", name: "Austria" },
  { id: 31, alpha2: "az", alpha3: "aze", name: "Azerbaigian" },
  { id: 44, alpha2: "bs", alpha3: "bhs", name: "Bahamas" },
  { id: 48, alpha2: "bh", alpha3: "bhr", name: "Bahrein" },
  { id: 50, alpha2: "bd", alpha3: "bgd", name: "Bangladesh" },
  { id: 52, alpha2: "bb", alpha3: "brb", name: "Barbados" },
  { id: 56, alpha2: "be", alpha3: "bel", name: "Belgio" },
  { id: 84, alpha2: "bz", alpha3: "blz", name: "Belize" },
  { id: 204, alpha2: "bj", alpha3: "ben", name: "Benin" },
  { id: 60, alpha2: "bm", alpha3: "bmu", name: "Bermuda" },
  { id: 64, alpha2: "bt", alpha3: "btn", name: "Bhutan" },
  { id: 112, alpha2: "by", alpha3: "blr", name: "Bielorussia" },
  { id: 104, alpha2: "mm", alpha3: "mmr", name: "Birmania" },
  { id: 68, alpha2: "bo", alpha3: "bol", name: "Bolivia" },
  { id: 70, alpha2: "ba", alpha3: "bih", name: "Bosnia ed Erzegovina" },
  { id: 72, alpha2: "bw", alpha3: "bwa", name: "Botswana" },
  { id: 76, alpha2: "br", alpha3: "bra", name: "Brasile" },
  { id: 96, alpha2: "bn", alpha3: "brn", name: "Brunei" },
  { id: 100, alpha2: "bg", alpha3: "bgr", name: "Bulgaria" },
  { id: 854, alpha2: "bf", alpha3: "bfa", name: "Burkina Faso" },
  { id: 108, alpha2: "bi", alpha3: "bdi", name: "Burundi" },
  { id: 116, alpha2: "kh", alpha3: "khm", name: "Cambogia" },
  { id: 120, alpha2: "cm", alpha3: "cmr", name: "Camerun" },
  { id: 124, alpha2: "ca", alpha3: "can", name: "Canada" },
  { id: 132, alpha2: "cv", alpha3: "cpv", name: "Capo Verde" },
  { id: 148, alpha2: "td", alpha3: "tcd", name: "Ciad" },
  { id: 152, alpha2: "cl", alpha3: "chl", name: "Cile" },
  { id: 156, alpha2: "cn", alpha3: "chn", name: "Cina" },
  { id: 196, alpha2: "cy", alpha3: "cyp", name: "Cipro" },
  { id: 336, alpha2: "va", alpha3: "vat", name: "Città del Vaticano" },
  { id: 170, alpha2: "co", alpha3: "col", name: "Colombia" },
  { id: 174, alpha2: "km", alpha3: "com", name: "Comore" },
  { id: 408, alpha2: "kp", alpha3: "prk", name: "Corea del Nord" },
  { id: 410, alpha2: "kr", alpha3: "kor", name: "Corea del Sud" },
  { id: 384, alpha2: "ci", alpha3: "civ", name: "Costa d'Avorio" },
  { id: 188, alpha2: "cr", alpha3: "cri", name: "Costa Rica" },
  { id: 191, alpha2: "hr", alpha3: "hrv", name: "Croazia" },
  { id: 192, alpha2: "cu", alpha3: "cub", name: "Cuba" },
  { id: 531, alpha2: "cw", alpha3: "cuw", name: "Curaçao" },
  { id: 208, alpha2: "dk", alpha3: "dnk", name: "Danimarca" },
  { id: 212, alpha2: "dm", alpha3: "dma", name: "Dominica" },
  { id: 218, alpha2: "ec", alpha3: "ecu", name: "Ecuador" },
  { id: 818, alpha2: "eg", alpha3: "egy", name: "Egitto" },
  { id: 222, alpha2: "sv", alpha3: "slv", name: "El Salvador" },
  { id: 784, alpha2: "ae", alpha3: "are", name: "Emirati Arabi Uniti" },
  { id: 232, alpha2: "er", alpha3: "eri", name: "Eritrea" },
  { id: 233, alpha2: "ee", alpha3: "est", name: "Estonia" },
  { id: 231, alpha2: "et", alpha3: "eth", name: "Etiopia" },
  { id: 242, alpha2: "fj", alpha3: "fji", name: "Figi" },
  { id: 608, alpha2: "ph", alpha3: "phl", name: "Filippine" },
  { id: 246, alpha2: "fi", alpha3: "fin", name: "Finlandia" },
  { id: 250, alpha2: "fr", alpha3: "fra", name: "Francia" },
  { id: 266, alpha2: "ga", alpha3: "gab", name: "Gabon" },
  { id: 270, alpha2: "gm", alpha3: "gmb", name: "Gambia" },
  { id: 268, alpha2: "ge", alpha3: "geo", name: "Georgia" },
  {
    id: 239,
    alpha2: "gs",
    alpha3: "sgs",
    name: "Georgia del Sud e Isole Sandwich Australi",
  },
  { id: 276, alpha2: "de", alpha3: "deu", name: "Germania" },
  { id: 288, alpha2: "gh", alpha3: "gha", name: "Ghana" },
  { id: 388, alpha2: "jm", alpha3: "jam", name: "Giamaica" },
  { id: 392, alpha2: "jp", alpha3: "jpn", name: "Giappone" },
  { id: 292, alpha2: "gi", alpha3: "gib", name: "Gibilterra" },
  { id: 262, alpha2: "dj", alpha3: "dji", name: "Gibuti" },
  { id: 400, alpha2: "jo", alpha3: "jor", name: "Giordania" },
  { id: 300, alpha2: "gr", alpha3: "grc", name: "Grecia" },
  { id: 308, alpha2: "gd", alpha3: "grd", name: "Grenada" },
  { id: 304, alpha2: "gl", alpha3: "grl", name: "Groenlandia" },
  { id: 312, alpha2: "gp", alpha3: "glp", name: "Guadalupa" },
  { id: 316, alpha2: "gu", alpha3: "gum", name: "Guam" },
  { id: 320, alpha2: "gt", alpha3: "gtm", name: "Guatemala" },
  { id: 831, alpha2: "gg", alpha3: "ggy", name: "Guernsey" },
  { id: 324, alpha2: "gn", alpha3: "gin", name: "Guinea" },
  { id: 624, alpha2: "gw", alpha3: "gnb", name: "Guinea-Bissau" },
  { id: 226, alpha2: "gq", alpha3: "gnq", name: "Guinea Equatoriale" },
  { id: 328, alpha2: "gy", alpha3: "guy", name: "Guyana" },
  { id: 254, alpha2: "gf", alpha3: "guf", name: "Guyana francese" },
  { id: 332, alpha2: "ht", alpha3: "hti", name: "Haiti" },
  { id: 340, alpha2: "hn", alpha3: "hnd", name: "Honduras" },
  { id: 344, alpha2: "hk", alpha3: "hkg", name: "Hong Kong" },
  { id: 356, alpha2: "in", alpha3: "ind", name: "India" },
  { id: 360, alpha2: "id", alpha3: "idn", name: "Indonesia" },
  { id: 364, alpha2: "ir", alpha3: "irn", name: "Iran" },
  { id: 368, alpha2: "iq", alpha3: "irq", name: "Iraq" },
  { id: 372, alpha2: "ie", alpha3: "irl", name: "Irlanda" },
  { id: 352, alpha2: "is", alpha3: "isl", name: "Islanda" },
  { id: 74, alpha2: "bv", alpha3: "bvt", name: "Isola Bouvet" },
  { id: 833, alpha2: "im", alpha3: "imn", name: "Isola di Man" },
  { id: 162, alpha2: "cx", alpha3: "cxr", name: "Isola di Natale" },
  { id: 574, alpha2: "nf", alpha3: "nfk", name: "Isola Norfolk" },
  { id: 248, alpha2: "ax", alpha3: "ala", name: "Isole Åland" },
  { id: 535, alpha2: "bq", alpha3: "bes", name: "Isole BES" },
  { id: 136, alpha2: "ky", alpha3: "cym", name: "Isole Cayman" },
  { id: 166, alpha2: "cc", alpha3: "cck", name: "Isole Cocos (Keeling)" },
  { id: 184, alpha2: "ck", alpha3: "cok", name: "Isole Cook" },
  { id: 234, alpha2: "fo", alpha3: "fro", name: "Fær Øer" },
  { id: 238, alpha2: "fk", alpha3: "flk", name: "Isole Falkland" },
  { id: 334, alpha2: "hm", alpha3: "hmd", name: "Isole Heard e McDonald" },
  {
    id: 580,
    alpha2: "mp",
    alpha3: "mnp",
    name: "Isole Marianne Settentrionali",
  },
  { id: 584, alpha2: "mh", alpha3: "mhl", name: "Isole Marshall" },
  {
    id: 581,
    alpha2: "um",
    alpha3: "umi",
    name: "Isole minori esterne degli Stati Uniti",
  },
  { id: 612, alpha2: "pn", alpha3: "pcn", name: "Isole Pitcairn" },
  { id: 90, alpha2: "sb", alpha3: "slb", name: "Isole Salomone" },
  { id: 92, alpha2: "vg", alpha3: "vgb", name: "Isole Vergini britanniche" },
  { id: 850, alpha2: "vi", alpha3: "vir", name: "Isole Vergini americane" },
  { id: 376, alpha2: "il", alpha3: "isr", name: "Israele" },
  { id: 380, alpha2: "it", alpha3: "ita", name: "Italia" },
  { id: 832, alpha2: "je", alpha3: "jey", name: "Jersey" },
  { id: 398, alpha2: "kz", alpha3: "kaz", name: "Kazakistan" },
  { id: 404, alpha2: "ke", alpha3: "ken", name: "Kenya" },
  { id: 417, alpha2: "kg", alpha3: "kgz", name: "Kirghizistan" },
  { id: 296, alpha2: "ki", alpha3: "kir", name: "Kiribati" },
  { id: 414, alpha2: "kw", alpha3: "kwt", name: "Kuwait" },
  { id: 418, alpha2: "la", alpha3: "lao", name: "Laos" },
  { id: 426, alpha2: "ls", alpha3: "lso", name: "Lesotho" },
  { id: 428, alpha2: "lv", alpha3: "lva", name: "Lettonia" },
  { id: 422, alpha2: "lb", alpha3: "lbn", name: "Libano" },
  { id: 430, alpha2: "lr", alpha3: "lbr", name: "Liberia" },
  { id: 434, alpha2: "ly", alpha3: "lby", name: "Libia" },
  { id: 438, alpha2: "li", alpha3: "lie", name: "Liechtenstein" },
  { id: 440, alpha2: "lt", alpha3: "ltu", name: "Lituania" },
  { id: 442, alpha2: "lu", alpha3: "lux", name: "Lussemburgo" },
  { id: 446, alpha2: "mo", alpha3: "mac", name: "Macao" },
  { id: 807, alpha2: "mk", alpha3: "mkd", name: "Macedonia del Nord" },
  { id: 450, alpha2: "mg", alpha3: "mdg", name: "Madagascar" },
  { id: 454, alpha2: "mw", alpha3: "mwi", name: "Malawi" },
  { id: 458, alpha2: "my", alpha3: "mys", name: "Malaysia" },
  { id: 462, alpha2: "mv", alpha3: "mdv", name: "Maldive" },
  { id: 466, alpha2: "ml", alpha3: "mli", name: "Mali" },
  { id: 470, alpha2: "mt", alpha3: "mlt", name: "Malta" },
  { id: 504, alpha2: "ma", alpha3: "mar", name: "Marocco" },
  { id: 474, alpha2: "mq", alpha3: "mtq", name: "Martinica" },
  { id: 478, alpha2: "mr", alpha3: "mrt", name: "Mauritania" },
  { id: 480, alpha2: "mu", alpha3: "mus", name: "Mauritius" },
  { id: 175, alpha2: "yt", alpha3: "myt", name: "Mayotte" },
  { id: 484, alpha2: "mx", alpha3: "mex", name: "Messico" },
  { id: 583, alpha2: "fm", alpha3: "fsm", name: "Micronesia" },
  { id: 498, alpha2: "md", alpha3: "mda", name: "Moldavia" },
  { id: 496, alpha2: "mn", alpha3: "mng", name: "Mongolia" },
  { id: 499, alpha2: "me", alpha3: "mne", name: "Montenegro" },
  { id: 500, alpha2: "ms", alpha3: "msr", name: "Montserrat" },
  { id: 508, alpha2: "mz", alpha3: "moz", name: "Mozambico" },
  { id: 516, alpha2: "na", alpha3: "nam", name: "Namibia" },
  { id: 520, alpha2: "nr", alpha3: "nru", name: "Nauru" },
  { id: 524, alpha2: "np", alpha3: "npl", name: "Nepal" },
  { id: 558, alpha2: "ni", alpha3: "nic", name: "Nicaragua" },
  { id: 562, alpha2: "ne", alpha3: "ner", name: "Niger" },
  { id: 566, alpha2: "ng", alpha3: "nga", name: "Nigeria" },
  { id: 570, alpha2: "nu", alpha3: "niu", name: "Niue" },
  { id: 578, alpha2: "no", alpha3: "nor", name: "Norvegia" },
  { id: 540, alpha2: "nc", alpha3: "ncl", name: "Nuova Caledonia" },
  { id: 554, alpha2: "nz", alpha3: "nzl", name: "Nuova Zelanda" },
  { id: 512, alpha2: "om", alpha3: "omn", name: "Oman" },
  { id: 528, alpha2: "nl", alpha3: "nld", name: "Paesi Bassi" },
  { id: 586, alpha2: "pk", alpha3: "pak", name: "Pakistan" },
  { id: 585, alpha2: "pw", alpha3: "plw", name: "Palau" },
  { id: 275, alpha2: "ps", alpha3: "pse", name: "Palestina" },
  { id: 591, alpha2: "pa", alpha3: "pan", name: "Panama" },
  { id: 598, alpha2: "pg", alpha3: "png", name: "Papua Nuova Guinea" },
  { id: 600, alpha2: "py", alpha3: "pry", name: "Paraguay" },
  { id: 604, alpha2: "pe", alpha3: "per", name: "Perù" },
  { id: 258, alpha2: "pf", alpha3: "pyf", name: "Polinesia francese" },
  { id: 616, alpha2: "pl", alpha3: "pol", name: "Polonia" },
  { id: 630, alpha2: "pr", alpha3: "pri", name: "Porto Rico" },
  { id: 620, alpha2: "pt", alpha3: "prt", name: "Portogallo" },
  { id: 492, alpha2: "mc", alpha3: "mco", name: "Monaco" },
  { id: 634, alpha2: "qa", alpha3: "qat", name: "Qatar" },
  { id: 826, alpha2: "gb", alpha3: "gbr", name: "Regno Unito" },
  { id: 180, alpha2: "cd", alpha3: "cod", name: "RD del Congo" },
  { id: 203, alpha2: "cz", alpha3: "cze", name: "Rep. Ceca" },
  { id: 140, alpha2: "cf", alpha3: "caf", name: "Rep. Centrafricana" },
  { id: 178, alpha2: "cg", alpha3: "cog", name: "Rep. del Congo" },
  { id: 214, alpha2: "do", alpha3: "dom", name: "Rep. Dominicana" },
  { id: 638, alpha2: "re", alpha3: "reu", name: "Riunione" },
  { id: 642, alpha2: "ro", alpha3: "rou", name: "Romania" },
  { id: 646, alpha2: "rw", alpha3: "rwa", name: "Ruanda" },
  { id: 643, alpha2: "ru", alpha3: "rus", name: "Russia" },
  { id: 732, alpha2: "eh", alpha3: "esh", name: "Sahara Occidentale" },
  { id: 659, alpha2: "kn", alpha3: "kna", name: "Saint Kitts e Nevis" },
  { id: 662, alpha2: "lc", alpha3: "lca", name: "Saint Lucia" },
  {
    id: 654,
    alpha2: "sh",
    alpha3: "shn",
    name: "Sant'Elena, Ascensione e Tristan da Cunha",
  },
  { id: 670, alpha2: "vc", alpha3: "vct", name: "Saint Vincent e Grenadine" },
  { id: 652, alpha2: "bl", alpha3: "blm", name: "Saint-Barthélemy" },
  { id: 663, alpha2: "mf", alpha3: "maf", name: "Saint-Martin" },
  { id: 666, alpha2: "pm", alpha3: "spm", name: "Saint-Pierre e Miquelon" },
  { id: 882, alpha2: "ws", alpha3: "wsm", name: "Samoa" },
  { id: 16, alpha2: "as", alpha3: "asm", name: "Samoa Americane" },
  { id: 674, alpha2: "sm", alpha3: "smr", name: "San Marino" },
  { id: 678, alpha2: "st", alpha3: "stp", name: "São Tomé e Príncipe" },
  { id: 686, alpha2: "sn", alpha3: "sen", name: "Senegal" },
  { id: 688, alpha2: "rs", alpha3: "srb", name: "Serbia" },
  { id: 690, alpha2: "sc", alpha3: "syc", name: "Seychelles" },
  { id: 694, alpha2: "sl", alpha3: "sle", name: "Sierra Leone" },
  { id: 702, alpha2: "sg", alpha3: "sgp", name: "Singapore" },
  { id: 534, alpha2: "sx", alpha3: "sxm", name: "Sint Maarten" },
  { id: 760, alpha2: "sy", alpha3: "syr", name: "Siria" },
  { id: 703, alpha2: "sk", alpha3: "svk", name: "Slovacchia" },
  { id: 705, alpha2: "si", alpha3: "svn", name: "Slovenia" },
  { id: 706, alpha2: "so", alpha3: "som", name: "Somalia" },
  { id: 724, alpha2: "es", alpha3: "esp", name: "Spagna" },
  { id: 144, alpha2: "lk", alpha3: "lka", name: "Sri Lanka" },
  { id: 840, alpha2: "us", alpha3: "usa", name: "Stati Uniti" },
  { id: 710, alpha2: "za", alpha3: "zaf", name: "Sudafrica" },
  { id: 729, alpha2: "sd", alpha3: "sdn", name: "Sudan" },
  { id: 728, alpha2: "ss", alpha3: "ssd", name: "Sudan del Sud" },
  { id: 740, alpha2: "sr", alpha3: "sur", name: "Suriname" },
  { id: 744, alpha2: "sj", alpha3: "sjm", name: "Svalbard e Jan Mayen" },
  { id: 752, alpha2: "se", alpha3: "swe", name: "Svezia" },
  { id: 756, alpha2: "ch", alpha3: "che", name: "Svizzera" },
  { id: 748, alpha2: "sz", alpha3: "swz", name: "eSwatini" },
  { id: 158, alpha2: "tw", alpha3: "twn", name: "Taiwan" },
  { id: 762, alpha2: "tj", alpha3: "tjk", name: "Tagikistan" },
  { id: 834, alpha2: "tz", alpha3: "tza", name: "Tanzania" },
  {
    id: 260,
    alpha2: "tf",
    alpha3: "atf",
    name: "Terre australi e antartiche francesi",
  },
  {
    id: 86,
    alpha2: "io",
    alpha3: "iot",
    name: "Territorio britannico dell'Oceano Indiano",
  },
  { id: 764, alpha2: "th", alpha3: "tha", name: "Thailandia" },
  { id: 626, alpha2: "tl", alpha3: "tls", name: "Timor Est" },
  { id: 768, alpha2: "tg", alpha3: "tgo", name: "Togo" },
  { id: 772, alpha2: "tk", alpha3: "tkl", name: "Tokelau" },
  { id: 776, alpha2: "to", alpha3: "ton", name: "Tonga" },
  { id: 780, alpha2: "tt", alpha3: "tto", name: "Trinidad e Tobago" },
  { id: 788, alpha2: "tn", alpha3: "tun", name: "Tunisia" },
  { id: 792, alpha2: "tr", alpha3: "tur", name: "Turchia" },
  { id: 795, alpha2: "tm", alpha3: "tkm", name: "Turkmenistan" },
  { id: 796, alpha2: "tc", alpha3: "tca", name: "Turks e Caicos" },
  { id: 798, alpha2: "tv", alpha3: "tuv", name: "Tuvalu" },
  { id: 804, alpha2: "ua", alpha3: "ukr", name: "Ucraina" },
  { id: 800, alpha2: "ug", alpha3: "uga", name: "Uganda" },
  { id: 348, alpha2: "hu", alpha3: "hun", name: "Ungheria" },
  { id: 858, alpha2: "uy", alpha3: "ury", name: "Uruguay" },
  { id: 860, alpha2: "uz", alpha3: "uzb", name: "Uzbekistan" },
  { id: 548, alpha2: "vu", alpha3: "vut", name: "Vanuatu" },
  { id: 862, alpha2: "ve", alpha3: "ven", name: "Venezuela" },
  { id: 704, alpha2: "vn", alpha3: "vnm", name: "Vietnam" },
  { id: 876, alpha2: "wf", alpha3: "wlf", name: "Wallis e Futuna" },
  { id: 887, alpha2: "ye", alpha3: "yem", name: "Yemen" },
  { id: 894, alpha2: "zm", alpha3: "zmb", name: "Zambia" },
  { id: 716, alpha2: "zw", alpha3: "zwe", name: "Zimbabwe" },
];

export const REGIONI = [
  {
    id: "13",
    nome: "Abruzzo",
    latitudine: 42.354008,
    longitudine: 13.391992,
  },
  {
    id: "17",
    nome: "Basilicata",
    latitudine: 40.633333,
    longitudine: 15.8,
  },
  {
    id: "18",
    nome: "Calabria",
    latitudine: 38.91,
    longitudine: 16.5875,
  },
  {
    id: "15",
    nome: "Campania",
    latitudine: 40.833333,
    longitudine: 14.25,
  },
  {
    id: "8",
    nome: "Emilia Romagna",
    latitudine: 44.493889,
    longitudine: 11.342778,
  },
  {
    id: "6",
    nome: "Friuli Venezia Giulia",
    latitudine: 45.636111,
    longitudine: 13.804167,
  },
  {
    id: "12",
    nome: "Lazio",
    latitudine: 41.893056,
    longitudine: 12.482778,
  },
  {
    id: "7",
    nome: "Liguria",
    latitudine: 44.411156,
    longitudine: 8.932661,
  },
  {
    id: "3",
    nome: "Lombardia",
    latitudine: 45.464161,
    longitudine: 9.190336,
  },
  {
    id: "11",
    nome: "Marche",
    latitudine: 43.616667,
    longitudine: 13.516667,
  },
  {
    id: "14",
    nome: "Molise",
    latitudine: 41.561,
    longitudine: 14.6684,
  },
  {
    id: "1",
    nome: "Piemonte",
    latitudine: 45.066667,
    longitudine: 7.7,
  },
  {
    id: "16",
    nome: "Puglia",
    latitudine: 41.125278,
    longitudine: 16.866667,
  },
  {
    id: "20",
    nome: "Sardegna",
    latitudine: 39.216667,
    longitudine: 9.116667,
  },
  {
    id: "19",
    nome: "Sicilia",
    latitudine: 38.115556,
    longitudine: 13.361389,
  },
  {
    id: "9",
    nome: "Toscana",
    latitudine: 43.771389,
    longitudine: 11.254167,
  },
  {
    id: "4",
    nome: "Trentino Alto Adige",
    latitudine: 46.066667,
    longitudine: 11.116667,
  },
  {
    id: "10",
    nome: "Umbria",
    latitudine: 43.1121,
    longitudine: 12.3888,
  },
  {
    id: "2",
    nome: "Valle d'Aosta",
    latitudine: 45.737222,
    longitudine: 7.320556,
  },
  {
    id: "5",
    nome: "Veneto",
    latitudine: 45.439722,
    longitudine: 12.331944,
  },
];
