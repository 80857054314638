/*
Il componente OfferteTelematiche è un pannello interattivo per la gestione delle offerte telematiche in un'asta. 
Include una tabella che elenca le offerte ricevute, con funzioni per visualizzare dettagli aggiuntivi, 
come dati anagrafici dei partecipanti e contenuti delle buste. Il componente permette inoltre di gestire 
la validità delle offerte e la verifica del versamento della cauzione. Include modali per confermare o 
rifiutare il versamento della cauzione e la validità delle offerte, con la possibilità di aggiornare lo 
stato delle offerte direttamente dalla tabella.
*/

import React, { memo, useState, useCallback } from "react";

//@mui
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";

//@mui
import AddBoxIcon from "@mui/icons-material/AddBox";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";

//hooks
import { useAsta } from "../../../context/useAsta";
import { useParams } from "react-router-dom";
//icons
import { AiOutlineMail } from "react-icons/ai";

//components
import ModalDatiAnagrafici from "./ModalDatiAnagrafici";
import ModalDatiBusta from "./ModalDatiBusta";
import CustomModal from "../../../utils/CustomModal/CustomModal";

//utils
import "./ReferentePanel.css";
import { partecipantiHead } from "../../../utils/SearchData";
import { motion } from "framer-motion";
import { convertDateInIta } from "../../../utils/Utils";
import {
  updateVersaCauzione,
  firebaseValidaOfferta,
  updateLogInserzione,
} from "../../../services/firebase";
import { toast } from "react-toastify";

const OfferteTelematiche = memo(() => {
    const [isOpen, setOpen] = useState(false);

    const { buste, setBuste } = useAsta();

    const offerteTelematiche =
      buste && buste.filter((busta) => busta.isTelematica);

    const params = useParams();

    //************** MODAL DATI ANAGRAFICI *************//
    const [modalDatiAnagrafici, setModalDatiAnagrafici] = useState({
      state: false,
      busta: null,
    });

    //************** MODAL DATI BUSTA ********************//
    const [modalDatiBusta, setModalDatiBusta] = useState({
      state: false,
      busta: null,
    });
    //************** MODAL VERSA CAUZIONE ***************//
    const [modalVersaCauzione, setModalVersaCauzione] = useState({
      state: false,
      docRef: "",
      isVersa: null,
    });
    //************** MODAL VALIDA OFFERTA ***************//
    const [modalValidaOfferta, setModalValidaOfferta] = useState({
      state: false,
      docRef: "",
      isValida: null,
    });

    const updateVersamentoCauzione = async () => {
      try {
        await updateVersaCauzione(
          modalVersaCauzione.docRef,
          modalVersaCauzione.isVersa
        );

        const newBuste = buste.map((busta) =>
          busta.idRef === modalVersaCauzione.docRef
            ? { ...busta, cauzioneVersata: modalVersaCauzione.isVersa }
            : busta
        );

        setBuste(newBuste);
        const bustaAlizzata = buste.find(
          (busta) => busta.idRef === modalVersaCauzione.docRef
        );

        if (modalVersaCauzione.isVersa === true) {
          const stringa =
            "L'offerente " +
            bustaAlizzata.credenziali.username +
            " ha versato la cauzione. --- " +
            convertDateInIta(new Date()) +
            " ---";
          await updateLogInserzione(stringa, params.id);

          toast.success("La cauzione è stata versata con successo");
        } else {
          const stringa =
            "L'offerente " +
            bustaAlizzata.credenziali.username +
            " non ha versato la cauzione. --- " +
            convertDateInIta(new Date()) +
            " ---";
          await updateLogInserzione(stringa, params.id);

          toast.success("La cauzione non è stata versata");
        }

        setModalVersaCauzione({
          state: false,
          docRef: "",
          isVersa: null,
        });
      } catch (error) {
        console.log(error);
        toast.error("Si è verificato un errore");
      }
    };

    const updateValidaOfferta = async () => {
      try {
        await firebaseValidaOfferta(
          modalValidaOfferta.docRef,
          modalValidaOfferta.isValida
        );

        const newBuste = buste.map((busta) =>
          busta.idRef === modalValidaOfferta.docRef
            ? { ...busta, offertaValida: modalValidaOfferta.isValida }
            : busta
        );

        setBuste(newBuste);
        const bustaAnalizzata = buste.find(
          (busta) => busta.idRef === modalValidaOfferta.docRef
        );

        if (modalValidaOfferta.isValida === true) {
          const stringa =
            "L'offerente " +
            bustaAnalizzata.credenziali.username +
            ` è stato ammesso, la sua offerta è di : € ${bustaAnalizzata.offertaRequest.offIntegrale.offerta.importoOfferta}  ---` +
            convertDateInIta(new Date()) +
            " ---";
          await updateLogInserzione(stringa, params.id);
          toast.success("L'offerta è valida!");
        } else {
          const stringa =
            "L'offerente " +
            bustaAnalizzata.credenziali.username +
            ` non è stato ammesso  ---` +
            convertDateInIta(new Date()) +
            " ---";
          await updateLogInserzione(stringa, params.id);
          toast.warning("L'offerta NON è valida!");
        }

        setModalValidaOfferta({
          state: false,
          docRef: "",
          isValida: null,
        });
      } catch (error) {
        console.log(error);
        toast.error("Si è verificato un errore");
      }
    };

    const openModalDatiBusta = useCallback((busta) => {
      setModalDatiBusta({
        state: true,
        busta: busta,
      });
    }, []);

    const openModalDatiAnagrafici = useCallback((busta) => {
      setModalDatiAnagrafici({
        state: true,
        busta: busta,
      });
    }, []);

    const closeModalDatiBusta = useCallback(() => {
      setModalDatiBusta({
        state: false,
        busta: null,
      });
    }, []);

    const closeModalDatiAnagrafici = useCallback(() => {
      setModalDatiAnagrafici({
        state: false,
        busta: null,
      });
    }, []);

    const closeModalVersaCauzione = useCallback(() => {
      setModalVersaCauzione({
        state: false,
        docRef: "",
        isVersa: null,
      });
    }, []);

    const closeModalValidaOfferta = useCallback(() => {
      setModalValidaOfferta({
        state: false,
        docRef: "",
        isValida: null,
      });
    }, []);

    return (
      <React.Fragment>
        <div className="referente_dropdown_container">
          {!isOpen ? (
            <AddBoxIcon
              color="primary"
              style={{ cursor: "pointer" }}
              onClick={() => setOpen(true)}
            />
          ) : (
            <IndeterminateCheckBoxIcon
              color="primary"
              style={{ cursor: "pointer" }}
              onClick={() => setOpen(false)}
            />
          )}
          <div style={{ fontWeight: 500 }}>Offerte Telematiche Ricevute</div>
        </div>

        {isOpen && (
          <motion.div
            initial={{
              opacity: 0,
            }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            exit={{ opacity: 0 }}
          >
            {" "}
            <TableContainer className="referente_table_container active">
              <Table sx={{ minWidth: 650 }} className="referente_table">
                <TableHead>
                  <TableRow>
                    {partecipantiHead.map((cell, index) => {
                      return <TableCell key={index}>{cell.title}</TableCell>;
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {offerteTelematiche.map((busta, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <div
                          onClick={() => {
                            openModalDatiAnagrafici(busta);
                          }}
                          className="text_title_presentatore"
                        >
                          {busta.offertaRequest.offIntegrale.presentatore
                            .datiAnagrafici.nome +
                            " " +
                            busta.offertaRequest.offIntegrale.presentatore
                              .datiAnagrafici.cognome +
                            ` (${busta.credenziali.username})`}
                        </div>
                      </TableCell>
                      <TableCell>
                        <AiOutlineMail
                          fontSize={25}
                          onClick={() => {
                            openModalDatiBusta(busta);
                          }}
                          style={{ cursor: "pointer" }}
                        />
                      </TableCell>
                      <TableCell>
                        <div className="buste_container">
                          {busta.offertaRequest.offIntegrale
                            .tipoDepositoCauzione === "bonifico" ? (
                            <div className="busta_info_cauzione">
                              <div>
                                {
                                  busta.offertaRequest.offIntegrale.offerta
                                    .importoCauzione
                                }{" "}
                                €
                              </div>
                              <div>
                                CRO:
                                {busta.offertaRequest.offIntegrale.offerta.cro}
                              </div>
                              <div>
                                {convertDateInIta(
                                  busta.offertaRequest.offIntegrale.offerta
                                    .dataCro
                                )}
                              </div>
                            </div>
                          ) : busta.offertaRequest.offIntegrale
                              .tipoDepositoCauzione === "carta di credito" ? (
                            <div className="busta_info_cauzione">
                              <div>
                                {
                                  busta.offertaRequest.offIntegrale.offerta
                                    .importoCauzione
                                }{" "}
                                €
                              </div>
                              <div>
                                {
                                  busta.offertaRequest.offIntegrale.offerta
                                    .tipoCarta
                                }{" "}
                                €
                              </div>
                            </div>
                          ) : (
                            <div className="busta_info_cauzione">
                              <div>
                                {
                                  busta.offertaRequest.offIntegrale.offerta
                                    .importoCauzione
                                }{" "}
                                €
                              </div>
                              <div>
                                {
                                  busta.offertaRequest.offIntegrale.offerta
                                    .tipoDepositoCauzione
                                }{" "}
                                
                              </div>
                            </div>
                          )}
                        </div>
                      </TableCell>

                      {/********** BOTTONI PER VERIFICA CAUZIONE ***********/}
                      {busta.cauzioneVersata === null && (
                        <TableCell>
                          <div className="busta_info_button">
                            <Button
                              variant="contained"
                              color="success"
                              onClick={() =>
                                setModalVersaCauzione({
                                  state: true,
                                  docRef: busta.idRef,
                                  isVersa: true,
                                })
                              }
                            >
                              Versata
                            </Button>
                            <Button
                              variant="contained"
                              color="error"
                              onClick={() =>
                                setModalVersaCauzione({
                                  state: true,
                                  docRef: busta.idRef,
                                  isVersa: false,
                                })
                              }
                              style={{ marginLeft: "0.4rem" }}
                            >
                              Non Versata
                            </Button>
                          </div>
                        </TableCell>
                      )}

                      {/********** BOTTONI PER VERIFICA OFFERTA ***********/}

                      {busta.cauzioneVersata === true &&
                        busta.offertaValida === null && (
                          <TableCell>
                            <div className="busta_info_button">
                              <Button
                                variant="contained"
                                color="success"
                                onClick={() => {
                                  setModalValidaOfferta({
                                    state: true,
                                    docRef: busta.idRef,
                                    isValida: true,
                                  });
                                }}
                              >
                                Valida Offerta
                              </Button>
                              <Button
                                variant="contained"
                                color="error"
                                style={{ marginLeft: "0.4rem" }}
                                onClick={() => {
                                  setModalValidaOfferta({
                                    state: true,
                                    docRef: busta.idRef,
                                    isValida: false,
                                  });
                                }}
                              >
                                Rifiuta Offerta
                              </Button>
                            </div>
                          </TableCell>
                        )}

                      {/********** MESSAGGIO OFFERTA VALIDA ***********/}

                      {busta.cauzioneVersata === true &&
                        busta.offertaValida === true && (
                          <TableCell>
                            <div
                              style={{
                                textAlign: "center",
                                color: "green",
                                fontWeight: "600",
                              }}
                            >
                              {" "}
                              L'offerta è valida!
                            </div>
                          </TableCell>
                        )}

                      {/********** MESSAGGIO OFFERTA NON VALIDA ***********/}

                      {busta.cauzioneVersata === true &&
                        busta.offertaValida === false && (
                          <TableCell>
                            <div
                              style={{
                                textAlign: "center",
                                color: "red",
                                fontWeight: "600",
                              }}
                            >
                              {" "}
                              L'offerta non è valida!
                            </div>
                          </TableCell>
                        )}

                      {/********** MESSAGGIO CAUZIONE NON VERSATA ***********/}

                      {busta.cauzioneVersata === false &&
                        busta.offertaValida === null && (
                          <TableCell>
                            <div
                              style={{
                                textAlign: "center",
                                color: "red",
                                fontWeight: "600",
                              }}
                            >
                              {" "}
                              La cauzione non è stata versata{" "}
                            </div>
                          </TableCell>
                        )}

                      <TableCell>
                        <div>
                          {
                            busta.offertaRequest.offIntegrale.offerta
                              .importoOfferta
                          }{" "}
                          €
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </motion.div>
        )}

        {modalDatiAnagrafici.state && (
          <ModalDatiAnagrafici
            open={modalDatiAnagrafici.state}
            busta={modalDatiAnagrafici.busta}
            onClose={closeModalDatiAnagrafici}
          />
        )}

        {modalDatiBusta.state && (
          <ModalDatiBusta
            open={modalDatiBusta.state}
            busta={modalDatiBusta.busta}
            onClose={closeModalDatiBusta}
          />
        )}

        {modalVersaCauzione.state && (
          <CustomModal
            title={
              modalVersaCauzione.isVersa
                ? "Sei sicuro di confermare il versamento della cauzione"
                : "Sei sicuro di non confermare il versamento della cauzione?"
            }
            content="Attenzione, una volta confermato non potrai più annullare l'operazione."
            openModal={modalVersaCauzione.state}
            handleClose={closeModalVersaCauzione}
            submitModal={updateVersamentoCauzione}
            color={modalVersaCauzione.isVersa ? "green" : "red"}
          />
        )}

        {modalValidaOfferta.state && (
          <CustomModal
            title={
              modalValidaOfferta.isValida
                ? "Sei sicuro di voler confermare l'offerta?"
                : "Sei sicuro di rifiutare l'offerta?"
            }
            content="Attenzione, una volta confermato non potrai più annullare l'operazione."
            openModal={modalValidaOfferta.state}
            handleClose={closeModalValidaOfferta}
            submitModal={updateValidaOfferta}
            color={modalValidaOfferta.isValida ? "green" : "red"}
          />
        )}
      </React.Fragment>
    );
});

export default OfferteTelematiche;
