import React from "react";

//components
import OfferteTelematiche from "./OfferteTelematiche";
import PannelloControllo from "./PannelloControllo/PannelloControllo";
import GaraPanelReferente from "./GaraPanelReferente";
import OfferteCartacee from "./OfferteCartacee/OfferteCartacee";
import Chat from "./Chat";
//hooks
import { useAsta } from "../../../context/useAsta";
import { useParams } from "react-router-dom";

//@mui
import { Button } from "@mui/material";

//fb
import {
  changeStateAulaVirtuale,
  updateLogInserzione,
} from "../../../services/firebase";

//utils
import "../../../pages/Asta/Asta.css";
import { toast } from "react-toastify";
import { convertDateInIta, STATI_ASTA } from "../../../utils/Utils";

const ReferentePanel = () => {
  const { asta, aulaVirtuale } = useAsta();
  const params = useParams();

  const openAulaVirtuale = async () => {
    try {
      await changeStateAulaVirtuale(true, params.id);
      const stringa =
        "L'aula virtuale è stata aperta  --- " +
        convertDateInIta(new Date()) +
        " ---";
      await updateLogInserzione(stringa, params.id);
    } catch (error) {
      console.log(error);
      toast.error("Si è verificato un errore");
    }
  };

  if (
    aulaVirtuale &&
    aulaVirtuale.type === "referente" &&
    aulaVirtuale.messageId === params.id
  ) {
    return (
      <div className="referente_panel">
     

        {!asta.aulaVirtuale ? (
          <div className="referente_button_container">
            <Button variant="contained" onClick={openAulaVirtuale}>
              {" "}
              APRI AULA VIRTUALE
            </Button>
          </div>
        ) : (
          <div className="referente_container">
            {STATI_ASTA.IN_CORSO === asta.statoAsta && <GaraPanelReferente />}
            <div className="gara_box_chat">
{/*             {asta.datiVendita.modalitaVendita !== "ASINCRONA TELEMATICA" && 
              <Chat />} */}
              <Chat />

          </div>
            <div className="referente_title"> AZIONI REFERENTE </div>
            <OfferteTelematiche />
            {asta.datiVendita.modalitaVendita ===
              "SINCRONA MISTA" && <OfferteCartacee />}
            <PannelloControllo />
          </div>
        )}
      </div>
    );
  }

  return null;
};

export default ReferentePanel;
