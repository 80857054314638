import React, { memo } from "react";
import Carousel from "react-multi-carousel";
import CardInserzione from "./CardInserzione";
import "../../pages/Homepage/Homepage.css"

const MultiCarousel = memo(({ inserzioni }) => {
  const responsive = {
    desktop: {
      breakpoint: {  max:Infinity , min: 1024 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 800 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 800, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  return (
    <Carousel
      swipeable={false}
      draggable={false}
      showArrows={true}
      showDots={true}
      responsive={responsive}
      ssr={true} // means to render carousel on server-side.
      customTransition="transform 600ms ease-in-out"
      removeArrowOnDeviceType={["tablet", "mobile"]}
      dotListClass="custom-dot-list-style"
      containerClass="carousel_container"
      itemClass="carousel_item"

    >
      {inserzioni.map((inserzione, index) => (
        <CardInserzione key={index} inserzione={inserzione} />
      ))}
    </Carousel>
  );
});

export default MultiCarousel;
