// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header_container {
  width: 100vw;
  height: 66px;
  max-width: 1240px;
  margin: auto;
  background-color: var(--main-color);
}

.header_login_button {
  padding: 0.5rem;
}

.header_avatar {
  padding: 0rem 1rem;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
}

.box_menu {
  position: absolute;
  top: 94%;
  z-index: 20;
  left: -80%;
  border-radius: 0.5rem;
  background-color: #03a9f4;
}

.box_menu li {
  margin: 1rem 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  font-weight: 600;
  font-size: 1.2rem;
  list-style-type: none;
}

.text_menu {
  margin-left: 0.5rem;
}

.header_flexbox {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
`, "",{"version":3,"sources":["webpack://./src/layout/Header/Header.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,YAAY;EACZ,iBAAiB;EACjB,YAAY;EACZ,mCAAmC;AACrC;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,WAAW;EACX,UAAU;EACV,qBAAqB;EACrB,yBAAyB;AAC3B;;AAEA;EACE,iBAAiB;EACjB,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,YAAY;EACZ,gBAAgB;EAChB,iBAAiB;EACjB,qBAAqB;AACvB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC","sourcesContent":[".header_container {\n  width: 100vw;\n  height: 66px;\n  max-width: 1240px;\n  margin: auto;\n  background-color: var(--main-color);\n}\n\n.header_login_button {\n  padding: 0.5rem;\n}\n\n.header_avatar {\n  padding: 0rem 1rem;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  position: relative;\n}\n\n.box_menu {\n  position: absolute;\n  top: 94%;\n  z-index: 20;\n  left: -80%;\n  border-radius: 0.5rem;\n  background-color: #03a9f4;\n}\n\n.box_menu li {\n  margin: 1rem 1rem;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  color: white;\n  font-weight: 600;\n  font-size: 1.2rem;\n  list-style-type: none;\n}\n\n.text_menu {\n  margin-left: 0.5rem;\n}\n\n.header_flexbox {\n  height: 100%;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
