/*
Il componente InfoAsta in React è progettato per visualizzare informazioni dettagliate sullo stato e sui dati di una specifica asta. 
Utilizza il hook useAsta per accedere ai dati dell'asta corrente. 
A seconda dello stato dell'asta (AVVISO, ISCRIZIONI_IN_CORSO, IN_ATTESA_DI_AVVIO, IN_CORSO, TERMINATA_NO_INVIO, TERMINATA_OK_INVIO), 
il componente mostra diverse informazioni, come termine per la presentazione delle offerte, prezzo base, rialzo minimo, 
data inizio vendita, e, se l'asta è conclusa, dettagli sull'esito e sul vincitore. 

*/

/*

Stato "AVVISO": L'asta è in fase preliminare, in attesa di avviare le iscrizioni. Le informazioni disponibili includono la data di termine per la presentazione delle offerte e il prezzo base. Questo stato precede l'apertura ufficiale delle iscrizioni.

Stato "ISCRIZIONI_IN_CORSO": Le iscrizioni all'asta sono aperte. In questo stato vengono visualizzate le stesse informazioni dell'AVVISO, con l'aggiunta del prezzo minimo e del rialzo minimo se l'asta è senza incanto.

Stato "IN_ATTESA_DI_AVVIO": L'asta è in attesa di iniziare ufficialmente. Vengono mostrate informazioni simili allo stato "ISCRIZIONI_IN_CORSO", con l'aggiunta della data di inizio dell'asta.

Stato "IN_CORSO": L'asta è attivamente in corso. Le informazioni mostrate includono la data di fine vendita, il prezzo base e il rialzo minimo.

Stati "TERMINATA_NO_INVIO" e "TERMINATA_OK_INVIO": L'asta è terminata, con dettagli sull'esito, come il vincitore e l'importo di aggiudicazione, oltre al prezzo base e al rialzo minimo.

*/



//@todo gestire gli stati dell'asta non come stringhe ma come costanti


import React from "react";

//context
import { useAsta } from "../../context/useAsta";

//utils
import { STATI_ASTA } from "../../utils/Utils";
import "../../pages/Asta/Asta.css";
import accounting from "accounting";
import { convertDateInIta } from "../../utils/Utils";

const InfoAsta = () => {
  const { asta } = useAsta();


  if (asta && 
      asta.statoAsta && 
      asta.datiVendita) 
  {

      if (asta?.statoAsta === "AVVISO") {
        return (
          <React.Fragment>
            <div className="info_asta_container">
              <div className="info_asta_status">
                IN ATTESA DI AVVIARE LE ISCRIZIONI
              </div>
              <div className="info_asta_informazioni">
                <div>Termine Presentazione Offerte:</div>
                {convertDateInIta(
                  asta.datiVendita.terminePresentazioneOfferte
                )}
              </div>

              <div className="info_asta_section">
                <div>Prezzo Base € :</div>
                <div style={{ marginLeft: "15px" }}>
                  {asta.datiVendita.prezzoValoreBase}
                </div>
              </div>
              <hr style={{ width: "100%" }} />
              <div className="info_asta_section">
                <div>Inizio Vendita :</div>
                <div style={{ marginLeft: "15px" }}>
                  {convertDateInIta(
                    asta.datiVendita.dataOraVendita
                  )}
                </div>
              </div>
              <hr style={{ width: "100%" }} />
            </div>
          </React.Fragment>
        );
      }

      if (asta.statoAsta === STATI_ASTA.ISCRIZIONI_IN_CORSO) {
        return (
          <React.Fragment>
            <div className="info_asta_container">
              <div className="info_asta_status">GARA NON AVVIATA</div>
              <div className="info_asta_informazioni">
                <div>Termine Presentazione Offerte:</div>
                {convertDateInIta(
                  asta.datiVendita.terminePresentazioneOfferte
                )}
              </div>

              <div className="info_asta_section">
                <div>Prezzo Base € :</div>
                <div style={{ marginLeft: "15px" }}>
                  {asta.datiVendita.prezzoValoreBase}
                </div>
              </div>
              {asta.datiVendita.tipologiaVendita !==
              "CON INCANTO" ? (
                <div className="info_asta_section">
                  <div>Prezzo Minimo € :</div>
                  <div style={{ marginLeft: "15px" }}>
                    {asta.datiVendita.offertaMinima}
                  </div>
                </div>
              ) : (
                ""
              )}
              <hr style={{ width: "100%" }} />
              <div className="info_asta_section">
                <div>Rialzo minimo € :</div>
                <div style={{ marginLeft: "15px" }}>
                  {asta.datiVendita.rialzoMinimo}
                </div>
              </div>
              <hr style={{ width: "100%" }} />
              <div className="info_asta_section">
                <div>Inizio Vendita :</div>
                <div style={{ marginLeft: "15px" }}>
                  {convertDateInIta(
                    asta.datiVendita.dataOraVendita
                  )}
                </div>
              </div>
            </div>
          </React.Fragment>
        );
      }

      if (asta.statoAsta === STATI_ASTA.IN_ATTESA_DI_AVVIO) {
        return (
          <React.Fragment>
            <div className="info_asta_container">
              <div className="info_asta_status">GARA IN ATTESA DI AVVIO </div>
              <div className="info_asta_informazioni">
                <div>Data vendita:</div>
                {convertDateInIta(
                  asta.datiVendita.dataOraVendita
                )}
              </div>

              <div className="info_asta_section">
                <div>Prezzo Base € :</div>
                <div style={{ marginLeft: "15px" }}>
                  {asta.datiVendita.prezzoValoreBase}
                </div>
              </div>
              {asta.datiVendita.tipologiaVendita !==
              "CON INCANTO" ? (
                <div className="info_asta_section">
                  <div>Prezzo Minimo € :</div>
                  <div style={{ marginLeft: "15px" }}>
                    {asta.datiVendita.offertaMinima}
                  </div>
                </div>
              ) : (
                ""
              )}
              <hr style={{ width: "100%" }} />
              <div className="info_asta_section">
                <div>Rialzo minimo € :</div>
                <div style={{ marginLeft: "15px" }}>
                  {asta.datiVendita.rialzoMinimo}
                </div>
              </div>
              <hr style={{ width: "100%" }} />
              <div className="info_asta_section">
                <div>Inizio Vendita :</div>
                <div style={{ marginLeft: "15px" }}>
                  {convertDateInIta(
                    asta.datiVendita.dataOraVendita
                  )}
                </div>
              </div>
              {asta.dataFineVendita && (
                <div className="info_asta_section">
                  <div>Data Partenza Gara:</div>
                  <div style={{ marginLeft: "15px", fontWeight: 500 }}>
                    {asta.datiVendita.modalitaVendita ===
                    "ASINCRONA TELEMATICA"
                      ? convertDateInIta(asta.dataInizioVendita)
                      : convertDateInIta(asta.dataFineVendita)}
                  </div>
                </div>
              )}
            </div>
          </React.Fragment>
        );
      }

      if (asta.statoAsta === STATI_ASTA.IN_CORSO) {
        return (
          <React.Fragment>
            <div className="info_asta_container">
              <div className="info_asta_status">GARA IN CORSO </div>
              <div className="info_asta_informazioni">
                <div>Data fine vendita:</div>
                {convertDateInIta(
                  asta.datiVendita.dataFineVendita
                )}
              </div>

              <div className="info_asta_section">
                <div>Prezzo Base € :</div>
                <div style={{ marginLeft: "15px" }}>
                  {asta.datiVendita.prezzoValoreBase}
                </div>
              </div>
              <hr style={{ width: "100%" }} />
              <div className="info_asta_section">
                <div>Rialzo minimo € :</div>
                <div style={{ marginLeft: "15px" }}>
                  {asta.datiVendita.rialzoMinimo}
                </div>
              </div>
              <hr style={{ width: "100%" }} />
              <div className="info_asta_section">
                <div>Rialzo minimo € :</div>
                <div style={{ marginLeft: "15px" }}>
                  {asta.datiVendita.rialzoMinimo}
                </div>
              </div>
              <hr style={{ width: "100%" }} />

              <div className="info_asta_section">
                <div>Data Partenza Gara:</div>
                <div style={{ marginLeft: "15px" }}>
                  {asta.datiVendita.modalitaVendita ===
                  "ASINCRONA TELEMATICA"
                    ? convertDateInIta(asta.dataInizioVendita)
                    : convertDateInIta(asta.dataFineVendita)}
                </div>
              </div>
            </div>
          </React.Fragment>
        );
      }

      if (
        (asta.statoAsta === STATI_ASTA.TERMINATA_NO_INVIO ||
        asta.statoAsta === STATI_ASTA.TERMINATA_OK_INVIO) 
      ) {
        return (
          <React.Fragment>
            <div className="info_asta_container">
              <div className="info_asta_status">GARA {asta.esitoAsta} </div>

              {asta.esitoAsta === "AGGIUDICATA" && (
                <>
                  <div className="info_asta_section">
                    <div>Vincitore:</div>
                    <div style={{ marginLeft: "15px" }}>{asta.vincitore}</div>
                  </div>
                  <hr style={{ width: "100%" }} />
                  <div className="info_asta_section">
                    <div>Importo Aggiudicazione €:</div>
                    <div style={{ marginLeft: "15px" }}>
                      {accounting.formatMoney(
                        asta.vincitoreOfferta,
                        "",
                        2,
                        ".",
                        ","
                      )}
                    </div>
                  </div>
                  <hr style={{ width: "100%" }} />
                </>
              )}
              <div className="info_asta_section">
                <div>Prezzo Base € :</div>
                <div style={{ marginLeft: "15px" }}>
                  {asta.datiVendita.prezzoValoreBase}
                </div>
              </div>
              <hr style={{ width: "100%" }} />
              <div className="info_asta_section">
                <div>Rialzo minimo € :</div>
                <div style={{ marginLeft: "15px" }}>
                  {asta.datiVendita.rialzoMinimo}
                </div>
              </div>
            </div>
          </React.Fragment>
        );
      }
  }
  return null;
};

export default InfoAsta;
