import React from "react";

//hooks
import { useFormik } from "formik";
import { useAuth } from "../../context/useAuth";
import { useNavigate } from "react-router-dom";
//@mui
import { TextField, Button } from "@mui/material";

//utils
import * as Yup from "yup";
import "../../pages/SignUp/SignUp.css";
import { authErrorHandler } from "../../utils/Errors";
import { toast } from "react-toastify";

const SignUpForm = () => {

    const {registerUserHandler} = useAuth()
    const navigate = useNavigate()

  const initialValue = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string().required("Inserisci una email"),
    password: Yup.string()
      .required("Inserisci Password")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&])(?=.{8,})/,
        "Deve contenere 8 lettere,una maiuscola, una minuscola, un numero  e un carattere speciale"
      ),
  });

  const submitHandler = async(value) => {

    
    const response = await registerUserHandler(value.email,value.password)
    if (!response.error) {
        toast.success("Conferma la e-mail, per verificare l'utenza.");
        navigate("/")
      } else {
        const { code } = response.message;
        console.log(response);
        toast.error(authErrorHandler(code));
      }

};

  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: validationSchema,
    onSubmit: submitHandler,
  });



  

  return (
    <form className="signup_form" onSubmit={formik.handleSubmit}>
      <div className="signup_container">
        <div className="signup_title">REGISTRATI IN ASTALEX</div>

        <div className="signup_subtitle">
          Per registrarsi in Astalex è necessario inserire una e-mail ed una
          password.
          <br /> Una volta avviato il processo di registrazione bisognerà
          confermare la e-mail.
        </div>

        <TextField
          id="email"
          type="email"
          margin="normal"
          onChange={formik.handleChange}
          value={formik.values.email}
          label="Inserisci Email"
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          fullWidth
        />

        <TextField
          id="password"
          type="text"
          margin="normal"
          onChange={formik.handleChange}
          value={formik.values.password}
          label="Inserisci Password"
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          fullWidth
        />
        <Button
          sx={{ marginTop: "1rem" }}
          type="submit"
          variant="contained"
          fullWidth
        >
          Registrati
        </Button>
      </div>
    </form>
  );
};

export default SignUpForm;
