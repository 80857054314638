import React from "react";
import { Route, Routes } from "react-router-dom";

//components
import Header from "./layout/Header/Header";
import Footer from "./layout/Footer/Footer";

//pages
import Homepage from "./pages/Homepage/Homepage";
import Login from "./pages/Login/Login";
import SignUp from "./pages/SignUp/SignUp";
import Asta from "./pages/Asta/Asta";

const Routing = () => {
  return (
    <React.Fragment>
      <nav>
        <Header />
      </nav>
      <main>
        {" "}
        <Routes>
          <Route path="/">
            <Route index element={<Homepage />} />
            <Route path="login" element={<Login />} />
            <Route path="registrati" element={<SignUp />} />
            <Route path="asta/:id" element={<Asta />} />
          </Route>
        </Routes>
      </main>

      <footer>
        <Footer />
      </footer>
    </React.Fragment>
  );
};

export default Routing;
