//React
import { useState, useEffect } from "react";

//Components
import Routing from "./Routing";
import Maintenance from "./pages/Maintenance/Maintenance";

//ctx
import { useAuth } from "./context/useAuth";

//utils
import { HalfMalf } from "react-spinner-animated";
import "./App.css";
import {  getStatusApp } from "./services/firebase";

function App() {
  const { loading } = useAuth();
  const [maintenance, setMaintenance] = useState(false);



  /****************** STATUS_APP  ********************/

  useEffect(() => {
    getStatusApp().then((response) => setMaintenance(response.STATUS_APP));
  }, []);


  if (loading && !maintenance) {
    return (
      <div className="spinner_style">
        <HalfMalf center={false} width={"150px"} height={"150px"} />
      </div>
    );
  }

  
  if (!maintenance) {
    return (
      <>
        <Routing />
      </>
    );
  } 
  


  return (
    <>
    <Maintenance/>
      </>
  )
}

export default App;
