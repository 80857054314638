/*
Il componente Chat è una funzionalità di messaggistica integrata nell'ambito del pannello del referente di un'asta. 
Permette agli utenti (sia referenti che partecipanti) di inviare messaggi testuali all'interno dell'asta. 
Utilizza gli hook useAsta e useChat per gestire lo stato e recuperare i dati necessari. 
L'utente può inserire testi fino a un massimo di 200 caratteri. I messaggi vengono visualizzati 
nella chat con la data e l'orario di invio, e se l'utente è il referente o un partecipante. 
In caso di errore (ad esempio, testo troppo lungo), viene mostrato un messaggio di errore. 
*/

import React, { memo, useState } from "react";

import { Button } from "@mui/material";

//css
import "./ReferentePanel.css";

//hooks
import { useAsta } from "../../../context/useAsta";
import { useChat } from "../../../context/useChat";
import { useParams } from "react-router-dom";
import { addChatItem } from "../../../services/firebase";

const Chat = memo(() => {
  const [text, setText] = useState("");
  const [error, setError] = useState("");
  const { aulaVirtuale } = useAsta();
  const { chatItems } = useChat();
  const params = useParams();
  const { id } = params;
  const { type, nomeUtente } = aulaVirtuale;

  const submitMessage = async () => {
    try {
      if (text.length >= 200) {
        setError("Il testo è troppo lungo!");
        return;
      }

      const data = new Date();
      const dataString =
        "◉ Il " +
        data.toLocaleDateString() +
        " alle " +
        data.toLocaleTimeString();

      if (type === "referente") {
        const allStringToSend =
          dataString + " Il <b>Banditore</b> ha scritto: " + text;
        await addChatItem(id, allStringToSend);
      } else {
        const allStringToSend =
          dataString +
          ` Il partecipante <b>${nomeUtente}</b> ha scritto: ` +
          text;
        await addChatItem(id, allStringToSend);
      }

      setText("");
      setError("");
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  // console.log("CHAT ITEMS = " + chatItems)

  return (
    <div className="chat_box">
      <div className="title">CHAT</div>
      <input onChange={(e) => setText(e.target.value)} value={text}></input>
      {error !== "" && <div> Errore: {error} </div>}

      <Button
        variant="contained"
        type="submit"
        onClick={submitMessage}
        color="primary"
      >
        {" "}
        Invia
      </Button>

      <div className="chat_container">
        {chatItems && 
          chatItems?.map((item, index) => (
            <div>
              <div
                dangerouslySetInnerHTML={{ __html: item }}
                key={index}
                style={{ marginTop: "0.3rem" }}
              />
            </div>
          ))}
      </div>
    </div>
  );
});

export default Chat;
