/*
Il componente CronologiaGara in React è progettato per visualizzare la cronologia degli eventi di un'asta specifica. 
Utilizza l'hook useParams per ottenere l'ID dell'asta dall'URL e recupera i dati dalla collezione "LogInserzioni" su Firebase. 
Presenta un'icona per espandere o comprimere la visualizzazione della cronologia. 
Quando l'utente clicca sull'icona, la cronologia degli eventi registrati per quella specifica asta viene mostrata o nascosta. 
Questa funzionalità fornisce agli utenti una visione dettagliata dell'andamento dell'asta, tra cui offerte, 
modifiche di stato e altri eventi significativi.
*/

import React, { useState, memo, useEffect } from "react";
import { useParams } from "react-router-dom";

//firebase
import { db } from "../../../services/firebase";

//@mui
import AddBoxIcon from "@mui/icons-material/AddBox";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";

const CronologiaGara = memo(() => {
  const [data, setData] = useState(null);
  const [isOpen, setOpen] = useState(false);
  const params = useParams();

  useEffect(() => {
    db.collection("LogInserzioni")
      .doc(params.id)
      .onSnapshot((docRef) => {
        setData(docRef.data());
      });

    return () => {
      setData([]);
    };
  }, [setData, params.id]);

  return (
    <React.Fragment>
      <div className="partecipante_dropdown_container">
        {!isOpen ? (
          <AddBoxIcon
            color="primary"
            style={{ cursor: "pointer" }}
            onClick={() => setOpen(true)}
          />
        ) : (
          <IndeterminateCheckBoxIcon
            color="primary"
            style={{ cursor: "pointer" }}
            onClick={() => setOpen(false)}
          />
        )}
        <div style={{ fontWeight: 500 }}> Cronologia Gara</div>
      </div>

      {isOpen && (
        <div className="cronologia_gara_box">
          {data &&
            data?.logs?.reverse().map((log, index) => (
              <div key={index} style={{ marginTop: "0.3rem" }}>
                {log}
              </div>
            ))}
        </div>
      )}
    </React.Fragment>
  );
});

export default CronologiaGara;
