/*
Il componente ModalDatiAnagrafici è un modal in React che visualizza i dati anagrafici del presentatore e 
degli offerenti di una busta d'asta. 
Quando aperto, mostra un'interfaccia utente con sezioni espandibili (Accordion) per ciascun soggetto coinvolto nell'offerta. 
All'interno di queste sezioni, sono elencati dettagli come nome, cognome, data di nascita, contatti, 
informazioni sulla residenza e altro. 
È uno strumento utile per fornire una panoramica dettagliata degli individui coinvolti in un'offerta specifica all'interno 
dell'ambiente dell'asta.
*/


import React, { memo } from "react";

//@mui
import {
  Modal,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { Box } from "@mui/system";

//utils
import "../../../pages/Asta/Asta.css";

const ModalDatiAnagrafici = memo(({ open, onClose, busta }) => {
  const { presentatore } = busta.offertaRequest.offIntegrale;
  const { offerenti } = busta.offertaRequest.offIntegrale;


  
  return (
    <Modal open={open} onClose={onClose}>
      <Box className="modal_dati_anagrafici">
        <div className="modal_assisti_title">Dati Anagrafici</div>

        <div>
          Di seguito sono riportati i dati anagrafici relativi al presentatore
          ed eventuali offerenti
        </div>

        {/**PRESENTATORE*/}
        <Accordion className="modal_dati_accordition">
          <AccordionSummary expandIcon={<ExpandMore />}>
            <div style={{ fontWeight: 600, color: "white" }}>PRESENTATORE</div>
          </AccordionSummary>
          <AccordionDetails
            style={{ backgroundColor: "white", borderRadius: "0.5rem" }}
            className="modal_dati_accordition_details"
          >
            <Grid container padding={2} spacing={1}>
              <Grid item xs={6}>
                Nome:
              </Grid>
              <Grid item xs={6} fontWeight={600}>
                {presentatore.datiAnagrafici.nome}
              </Grid>
              <Grid item xs={6}>
                Cognome:
              </Grid>
              <Grid item xs={6} fontWeight={600}>
                {presentatore.datiAnagrafici.cognome}
              </Grid>
              {presentatore.datiAnagrafici.nazioneNascita === "Italia" ? (
                <>
                  <Grid item xs={6}>
                    Comune Nascita:
                  </Grid>
                  <Grid item xs={6} fontWeight={600}>
                    {presentatore.datiAnagrafici.comuneNascita}
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={6}>
                    Comune Nascita Estero:
                  </Grid>
                  <Grid item xs={6} fontWeight={600}>
                    {presentatore.datiAnagrafici.comuneNascitaEstero}
                  </Grid>
                </>
              )}

              <Grid item xs={6}>
                Data Nascita:
              </Grid>
              <Grid item xs={6} fontWeight={600}>
                {`${presentatore.datiAnagrafici.giornoNascita}/${presentatore.datiAnagrafici.meseNascita}/${presentatore.datiAnagrafici.annoNascita}`}
              </Grid>
              <Grid item xs={6}>
                Codice Fiscale:
              </Grid>
              <Grid item xs={6} fontWeight={600}>
                {`${presentatore.datiAnagrafici.codiceFiscale}`}
              </Grid>
              <Grid item xs={6}>
                Email:
              </Grid>
              <Grid item xs={6} fontWeight={600}>
                {`${presentatore.contatti.email}`}
              </Grid>
              <Grid item xs={6}>
                Pec:
              </Grid>
              <Grid item xs={6} fontWeight={600}>
                {`${presentatore.contatti.pec}`}
              </Grid>
              <Grid item xs={6}>
                Cellulare:
              </Grid>
              <Grid item xs={6} fontWeight={600}>
                {`${presentatore.contatti.cellulare}`}
              </Grid>
              <Grid item xs={6}>
                Telefono:
              </Grid>
              <Grid item xs={6} fontWeight={600}>
                {`${presentatore.contatti.telefono}`}
              </Grid>

              {presentatore.residenza.comuneEsteroResidenza !== null ? (
                <>
                  <Grid item xs={6}>
                    Comune Residenza Estero:
                  </Grid>
                  <Grid item xs={6} fontWeight={600}>
                    {`${presentatore.residenza.comuneEsteroResidenza}`}
                  </Grid>
                  <Grid item xs={6}>
                    Cap Zip Code Estero:
                  </Grid>
                  <Grid item xs={6} fontWeight={600}>
                    {`${presentatore.residenza.capZipCodeEsteroResidenza}`}
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={6}>
                    Indirizzo Residenza:
                  </Grid>
                  <Grid item xs={6} fontWeight={600}>
                    {`${presentatore.residenza.indirizzoResidenza}`}
                  </Grid>
                  <Grid item xs={6}>
                    Comune Residenza:
                  </Grid>
                  <Grid item xs={6} fontWeight={600}>
                    {`${presentatore.residenza.comuneResidenza}`}
                  </Grid>
                  <Grid item xs={6}>
                    Provincia Residenza:
                  </Grid>
                  <Grid item xs={6} fontWeight={600}>
                    {`${presentatore.residenza.provinciaResidenza}`}
                  </Grid>
                </>
              )}

              {presentatore.domicilio.comuneEsteroDomicilio !== "" ? (
                <>
                  <Grid item xs={6}>
                    Comune Domicilio Estero:
                  </Grid>
                  <Grid item xs={6} fontWeight={600}>
                    {`${presentatore.domicilio.comuneEsteroDomicilio}`}
                  </Grid>
                  <Grid item xs={6}>
                    Cap Zip Code Estero Domicilio:
                  </Grid>
                  <Grid item xs={6} fontWeight={600}>
                    {`${presentatore.domicilio.capZipCodeEsteroDomicilio}`}
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={6}>
                    Comune Domicilio:
                  </Grid>
                  <Grid item xs={6} fontWeight={600}>
                    {`${presentatore.domicilio.comuneDomicilio}`}
                  </Grid>
                  <Grid item xs={6}>
                    Indirizzo Domicilio:
                  </Grid>
                  <Grid item xs={6} fontWeight={600}>
                    {`${presentatore.domicilio.indirizzoDomicilio}`}
                  </Grid>
                  <Grid item xs={6}>
                    Cap Domicilio:
                  </Grid>
                  <Grid item xs={6} fontWeight={600}>
                    {`${presentatore.domicilio.capZipCodeDomicilio}`}
                  </Grid>
                  <Grid item xs={6}>
                    Provincia Domicilio:
                  </Grid>
                  <Grid item xs={6} fontWeight={600}>
                    {`${presentatore.domicilio.provinciaDomicilio}`}
                  </Grid>
                </>
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>

        {offerenti.map((offerente, index) => (
          <Accordion className="modal_dati_accordition" key={index}>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <div style={{ fontWeight: 600, color: "white" }}>
                {`OFFERENTE ${index+1}`}{" "}
              </div>
            </AccordionSummary>
            <AccordionDetails
              style={{ backgroundColor: "white", borderRadius: "0.5rem" }}
              className="modal_dati_accordition_details"
            >
              <Grid container padding={2} spacing={1}>
                <Grid item xs={6}>
                  Nome:
                </Grid>
                <Grid item xs={6} fontWeight={600}>
                  {offerente.datiAnagrafici.nome}
                </Grid>
                <Grid item xs={6}>
                  Cognome:
                </Grid>
                <Grid item xs={6} fontWeight={600}>
                  {offerente.datiAnagrafici.cognome}
                </Grid>
                {offerente.datiAnagrafici.nazioneNascita === "Italia" ? (
                  <>
                    <Grid item xs={6}>
                      Comune Nascita:
                    </Grid>
                    <Grid item xs={6} fontWeight={600}>
                      {offerente.datiAnagrafici.comuneNascita}
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={6}>
                      Comune Nascita Estero:
                    </Grid>
                    <Grid item xs={6} fontWeight={600}>
                      {offerente.datiAnagrafici.comuneNascitaEstero}
                    </Grid>
                  </>
                )}

                <Grid item xs={6}>
                  Data Nascita:
                </Grid>
                <Grid item xs={6} fontWeight={600}>
                  {`${offerente.datiAnagrafici.giornoNascita}/${offerente.datiAnagrafici.meseNascita}/${offerente.datiAnagrafici.annoNascita}`}
                </Grid>
                <Grid item xs={6}>
                  Codice Fiscale:
                </Grid>
                <Grid item xs={6} fontWeight={600}>
                  {`${offerente.datiAnagrafici.codiceFiscale}`}
                </Grid>
                <Grid item xs={6}>
                  Email:
                </Grid>
                <Grid item xs={6} fontWeight={600}>
                  {`${offerente.contatti.email}`}
                </Grid>
                <Grid item xs={6}>
                  Cellulare:
                </Grid>
                <Grid item xs={6} fontWeight={600}>
                  {`${offerente.contatti.cellulare}`}
                </Grid>
                <Grid item xs={6}>
                  Telefono:
                </Grid>
                <Grid item xs={6} fontWeight={600}>
                  {`${offerente.contatti.telefono}`}
                </Grid>

                {offerente.residenza.comuneEsteroResidenza !== null ? (
                  <>
                    <Grid item xs={6}>
                      Comune Residenza Estero:
                    </Grid>
                    <Grid item xs={6} fontWeight={600}>
                      {`${offerente.residenza.comuneEsteroResidenza}`}
                    </Grid>
                    <Grid item xs={6}>
                      Cap Zip Code Estero:
                    </Grid>
                    <Grid item xs={6} fontWeight={600}>
                      {`${offerente.residenza.capZipCodeEsteroResidenza}`}
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={6}>
                      Indirizzo Residenza:
                    </Grid>
                    <Grid item xs={6} fontWeight={600}>
                      {`${offerente.residenza.indirizzoResidenza}`}
                    </Grid>
                    <Grid item xs={6}>
                      Comune Residenza:
                    </Grid>
                    <Grid item xs={6} fontWeight={600}>
                      {`${offerente.residenza.comuneResidenza}`}
                    </Grid>
                    <Grid item xs={6}>
                      Provincia Residenza:
                    </Grid>
                    <Grid item xs={6} fontWeight={600}>
                      {`${offerente.residenza.provinciaResidenza}`}
                    </Grid>
                  </>
                )}

                <Grid item xs={6}>
                  Tipo Offerente:
                </Grid>
                <Grid item xs={6} fontWeight={600}>
                  {`${offerente.tipoOfferente}`}
                </Grid>
                <Grid item xs={6}>
                  Titolo Partecipazione:
                </Grid>
                <Grid item xs={6} fontWeight={600}>
                  {`${offerente.titolo}`}
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Modal>
  );
});

export default ModalDatiAnagrafici;
