import React from "react";
import "./CustomModal.css";
import { Modal, Box, Typography, Button } from "@mui/material";
import { memo } from "react";

const CustomModal = memo(({ title, content, openModal, handleClose, color, submitModal }) => {


    return (
      <Modal open={openModal} onClose={handleClose}>
        <Box className="modal_box">
          <Typography variant="h6" color="primary" component="h2">
            {title}
          </Typography>
          <Typography sx={{ mt: 2 }}>{content}</Typography>
          <div className="modal_box_buttons">
            <Button
              style={{ backgroundColor: "#9e9e9e" }}
              variant="contained"
              onClick={handleClose}
            >
              Annulla
            </Button>
            <Button
              variant="contained"
              sx={{
                bgcolor: `${color}`,
                marginLeft: "1rem",
                ":hover": {
                  bgcolor: `${color}`,
                  color: "white",
                },
              }}
              onClick={submitModal}
            >
              Conferma
            </Button>
          </div>
        </Box>
      </Modal>
    );
  }
);

export default CustomModal;
