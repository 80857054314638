// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.signup_form{
    width: 100%;
    min-height: calc(100vh - 66px);
    display: flex;
    justify-content: center;
    align-items: center;
}


.signup_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 350px;
    padding: 2rem;
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

  .signup_subtitle{
    margin:1rem 0;
  }
  
  .signup_title {
    background-color: var(--success);
    padding: 1rem 0;
    border-radius: 0.5rem;
    width: 100%;
    color: white;
    text-transform: uppercase;
    font-weight: 600;
    text-align: center;
  }
  
  @media screen and (max-width: 600px) {
    .signup_container {
      margin: 1rem;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/SignUp/SignUp.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,8BAA8B;IAC9B,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;;AAGA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;IACtB,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,qBAAqB;IACrB,qDAAqD;EACvD;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,gCAAgC;IAChC,eAAe;IACf,qBAAqB;IACrB,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,gBAAgB;IAChB,kBAAkB;EACpB;;EAEA;IACE;MACE,YAAY;IACd;EACF","sourcesContent":[".signup_form{\n    width: 100%;\n    min-height: calc(100vh - 66px);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n\n.signup_container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    width: 350px;\n    padding: 2rem;\n    background-color: white;\n    border-radius: 0.5rem;\n    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;\n  }\n\n  .signup_subtitle{\n    margin:1rem 0;\n  }\n  \n  .signup_title {\n    background-color: var(--success);\n    padding: 1rem 0;\n    border-radius: 0.5rem;\n    width: 100%;\n    color: white;\n    text-transform: uppercase;\n    font-weight: 600;\n    text-align: center;\n  }\n  \n  @media screen and (max-width: 600px) {\n    .signup_container {\n      margin: 1rem;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
