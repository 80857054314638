// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --main-color: #1e88e5;
  --background-color: #f5f5f5;
  --background-gray:#e0e0e0;
  --success:#4caf50;
  --error:#ef5350;
  --warning:#ff9800;
  
}

*{
  box-sizing: border-box;
  margin:0;
  padding:0;
}

:root{
  height: 100%;
}

a{
  text-decoration: none;

}

body {
  
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
  width: 100vw;
  min-height: calc(100vh - 66px);
  max-width: 1240px;
  margin: auto;
  background-color: var(--background-color);
}


::-webkit-scrollbar{
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,2BAA2B;EAC3B,yBAAyB;EACzB,iBAAiB;EACjB,eAAe;EACf,iBAAiB;;AAEnB;;AAEA;EACE,sBAAsB;EACtB,QAAQ;EACR,SAAS;AACX;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,qBAAqB;;AAEvB;;AAEA;;EAEE,iCAAiC;EACjC,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;AAEA;EACE,YAAY;EACZ,8BAA8B;EAC9B,iBAAiB;EACjB,YAAY;EACZ,yCAAyC;AAC3C;;;AAGA;EACE,aAAa;AACf","sourcesContent":[":root {\n  --main-color: #1e88e5;\n  --background-color: #f5f5f5;\n  --background-gray:#e0e0e0;\n  --success:#4caf50;\n  --error:#ef5350;\n  --warning:#ff9800;\n  \n}\n\n*{\n  box-sizing: border-box;\n  margin:0;\n  padding:0;\n}\n\n:root{\n  height: 100%;\n}\n\na{\n  text-decoration: none;\n\n}\n\nbody {\n  \n  font-family: 'Roboto', sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\n    monospace;\n}\n\n.container {\n  width: 100vw;\n  min-height: calc(100vh - 66px);\n  max-width: 1240px;\n  margin: auto;\n  background-color: var(--background-color);\n}\n\n\n::-webkit-scrollbar{\n  display: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
