import React from "react";
import { memo } from "react";

//@mui
import { Modal, Box, Button, Checkbox, FormControlLabel } from "@mui/material";

//hooks
import { useFormik } from "formik";
import { useBreakPoints } from "../../../../utils/useBreakPoints";
import { useParams } from "react-router-dom";
import { useAsta } from "../../../../context/useAsta";

//utils
import "./PannelloControllo.css";
import { terminaAstaDeserta } from "../../../../services/firebase";

import * as Yup from "yup";
import { toast } from "react-toastify";

const AstaDeserta = memo(({ isOpen, handleClose }) => {

  const params = useParams();
  const { md } = useBreakPoints();
  const {setAulaVirtuale} = useAsta()

  const initialValues = {
    confermaOfferte: false,
  };

  const validationSchema = Yup.object().shape({
    confermaOfferte: Yup.boolean()
      .required("Conferma tale campo")
      .oneOf([true], "Conferma tale campo"),
  });

  const submitHandler = async (values) => {
    if (values.confermaOfferte) {
      try {
        await terminaAstaDeserta(params.id);
        handleClose()
        setAulaVirtuale(false)
        toast.success("L'asta ha cambiato stato in : DESERTA ");
      
      } catch (error) {
        console.log(error);
        toast.error("Si è verificato un errore");
      }
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: submitHandler,
  });

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box className="modal_termina_esame">
        <div className="modal_termina_esame_title">TERMINA ESAME OFFERTE</div>
        <Box marginBottom={2}>
          D.M 32/2015 - Art. 22 co.3: i dati contenuti nelle offerte cartacee,
          nonchè i rilanci e le osservazioni dei partecipanti in sala, devono
          essere inseriti nel portale del gestore della gara telematica.
          <br /> Nel caso in cui non siano pervenuto offerte telematiche e non
          venga inserita nessuna offerta cartacea, la gara risulterà
          automaticamente deserta.
        </Box>
        <form onSubmit={formik.handleSubmit}>
          <FormControlLabel
            control={
              <Checkbox
                checked={formik.values.confermaOfferte}
                onChange={formik.handleChange}
                name={"confermaOfferte"}
              />
            }
            label="Confermo di aver esaminato anche le eventuali offerte cartacee pervenute"
          />

          {formik.errors.confermaOfferte && formik.touched.confermaOfferte ? (
            <div style={{ color: "red" }}>{formik.errors.confermaOfferte}</div>
          ) : (
            ""
          )}

          <Box
            display={"flex"}
            alignItems="center"
            justifyContent={"flex-end"}
            marginTop={2}
            flexDirection={!md ? "Column" : "row"}
          >
            <Button
              variant="contained"
              type="submit"
              sx={{
                marginRight: !md ? "0" : "1rem",
                marginBottom: !md ? "1rem" : "0",
              }}
            >
              Conferma i dati e termina
            </Button>
            <Button color="neutral" onClick={handleClose} variant="contained">
              Annulla
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
});

export default AstaDeserta;
