import React from "react";
import App from "./App";
import ReactDOM from "react-dom";

//utils
import { BrowserRouter } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material";
import { AuthContextProvider } from "./context/useAuth";
import { ToastContainer } from "react-toastify";
import { AstaContextProvider } from "./context/useAsta";
import { AsteContextProvider } from "./context/useAste";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { HelmetProvider } from "react-helmet-async";

//css
import "react-multi-carousel/lib/styles.css";
import "react-spinner-animated/dist/index.css";
import "moment/locale/it";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";

const theme = createTheme({
  palette: {
    neutral: {
      main: "#64748B",
      contrastText: "#fff",
    },
  },
});






ReactDOM.render(
  <HelmetProvider>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <AuthContextProvider>
        <AsteContextProvider>
          <AstaContextProvider>
            <ToastContainer />
            <ThemeProvider theme={theme}>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </ThemeProvider>
          </AstaContextProvider>
        </AsteContextProvider>
      </AuthContextProvider>
    </LocalizationProvider>
  </HelmetProvider>,
  document.getElementById("root")
);
