import axios from "axios";

async function sendNotifications (idInserzione, rilancio) {
  console.log("sendNotifications called")
  try {
    const response = await axios.post(
      process.env.REACT_APP_DATA_SERVICE_URL + '/sendNotifications?password=' + process.env.REACT_APP_DATA_SERVICE_PASSWORD, 
      {
          idInserzione,
          rilancio
      });
    
    console.log('Risposta dal server:', response.data);
    alert('Gli altri partecipanti sono stati informati!');
  } catch (error) {
    console.error('Errore nell\'invio degli SMS:', error);
    alert('Errore nell\'invio delle notifiche agli altri partecipanti');
  }
};

/* 
async function sendSms (numbers, message) {
    console.log("sendSms called")
    try {
      const response = await axios.post(
        process.env.REACT_APP_DATA_SERVICE_URL + '/sendSmsToPeople?password=' + process.env.REACT_APP_DATA_SERVICE_PASSWORD, 
        {
            numbers,
            body: message
        });
      
      console.log('Risposta dal server:', response.data);
      alert('SMS inviati con successo!' + response.data);
    } catch (error) {
      console.error('Errore nell\'invio degli SMS:', error);
      alert('Errore nell\'invio degli SMS. Controlla la console per dettagli.');
    }
};


async function sendEmail (recipients, subject, body) {
    console.log("sendEmail called")

    try {
        const response = await axios.post(process.env.REACT_APP_DATA_SERVICE_URL + '/sendEmailToPeople?password=' + process.env.REACT_APP_DATA_SERVICE_PASSWORD, {
            recipients,
        subject,
        body
      });
      console.log('Risposta dal server:', response.data);
      alert('Email inviate con successo!' + response.data);
    } catch (error) {
      console.error('Errore nell\'invio delle email:', error);
      alert('Errore nell\'invio delle email. Controlla la console per dettagli.');
    }
  };
 */

  export { sendNotifications };
