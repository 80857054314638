
export const authErrorHandler = (code) => {
  switch (code) {
    case "auth/wrong-password":
      return "Password Errata!";
    case "auth/user-not-found":
      return "Utente non trovato";
    case "auth/invalid-email":
      return "Il formato della e-mail non è valida";
      case "auth/email-already-in-use":
        return "L'email è già presente. "
    default:
      return "Si è verificato un errore";
  }
};



