import axios from "axios";

const baseUrl = "https://comuni-ita.herokuapp.com/api";

export const getProvincie = (regione) =>
  new Promise((resolve, reject) =>
    axios
      .get(`${baseUrl}/province/${regione}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error))
  );

export const getComuni = (provincia) =>
  new Promise((resolve, reject) =>
    axios
      .get(`${baseUrl}/comuni/provincia/${provincia}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error))
  );
