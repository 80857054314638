import React from "react";
import { memo } from "react";

//@mui
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Box,
  Button,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

//utils
import "./OfferteCartacee.css";
import "../../../../pages/Asta/Asta.css";
import { toast } from "react-toastify";

const DatiOfferenti = memo(
  ({ offerenti, createNewOfferente, salvaDatiOfferta }) => {
    const inviaOffertaHandler = () => {
      var quotaTotalePartecipazione = offerenti.reduce(function (acc, obj) {
        return acc + Number(obj.quotaPartecipazione);
      }, 0);

      if (quotaTotalePartecipazione !== 100)
        return toast.warning(
          "ATTENZIONE! La quota totale deve essere uguale a 100"
        );
      salvaDatiOfferta();
    };

    return (
      <React.Fragment>
        <div className="modal_title_presentatore">Offerenti</div>
        <div style={{ marginTop: "10px" }}>
          Di seguito sono riportati i dati anagrafici relativi al presentatore
          ed eventuali offerenti
        </div>

        {offerenti.length !== 0 ? (
          offerenti.map((offerente, index) => (
            <Accordion className="modal_dati_accordition" key={index}>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <div style={{ fontWeight: 600, color: "white" }}>
                  {`OFFERENTE  ${index + 1}`}
                </div>
              </AccordionSummary>
              <AccordionDetails
                style={{ backgroundColor: "white", borderRadius: "0.5rem" }}
                className="modal_dati_accordition_details"
              >
                <Grid container padding={2} spacing={1}>
                  <Grid item xs={6}>
                    Nome:
                  </Grid>
                  <Grid item xs={6} fontWeight={600}>
                    {offerente.datiAnagrafici.nome}
                  </Grid>
                  <Grid item xs={6}>
                    Cognome:
                  </Grid>
                  <Grid item xs={6} fontWeight={600}>
                    {offerente.datiAnagrafici.cognome}
                  </Grid>

                  <Grid item xs={6}>
                    Data Nascita:
                  </Grid>
                  <Grid item xs={6} fontWeight={600}>
                    {`${offerente.datiAnagrafici.giornoNascita}/${offerente.datiAnagrafici.meseNascita}/${offerente.datiAnagrafici.annoNascita}`}
                  </Grid>
                  <Grid item xs={6}>
                    Codice Fiscale:
                  </Grid>
                  <Grid item xs={6} fontWeight={600}>
                    {`${offerente.datiAnagrafici.codiceFiscale}`}
                  </Grid>
                  <Grid item xs={6}>
                    Email:
                  </Grid>
                  <Grid item xs={6} fontWeight={600}>
                    {`${offerente.contatti.email}`}
                  </Grid>
                  <Grid item xs={6}>
                    Cellulare:
                  </Grid>
                  <Grid item xs={6} fontWeight={600}>
                    {`${offerente.contatti.cellulare}`}
                  </Grid>
                  <Grid item xs={6}>
                    Telefono:
                  </Grid>
                  <Grid item xs={6} fontWeight={600}>
                    {`${offerente.contatti.telefono}`}
                  </Grid>
                  <Grid item xs={6}>
                    Tipo Offerente:
                  </Grid>
                  <Grid item xs={6} fontWeight={600}>
                    {`${offerente.tipoOfferente}`}
                  </Grid>
                  <Grid item xs={6}>
                    Diritto Proprietà:
                  </Grid>
                  <Grid item xs={6} fontWeight={600}>
                    {`${offerente.dirittoProprieta}`}
                  </Grid>
                  <Grid item xs={6}>
                    Quota Partecipazione:
                  </Grid>
                  <Grid item xs={6} fontWeight={600}>
                    {`${offerente.quotaPartecipazione}`}
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          ))
        ) : (
          <div className="box_nessun_offerente">Nessun Offerente Presente</div>
        )}
        <Box display="flex" justifyContent={"center"} alignItems="center">
          <Button
            onClick={createNewOfferente}
            variant="contained"
            sx={{ marginTop: "1rem" }}
          >
            {" "}
            Aggiungi Offerente
          </Button>
        </Box>

        {offerenti.length !== 0 && (
          <Box display={"flex"} justifyContent="flex-end">
            <Button
              onClick={inviaOffertaHandler}
              variant="contained"
              color="success"
            >
              Invia Offerta
            </Button>
          </Box>
        )}
      </React.Fragment>
    );
  }
);

export default DatiOfferenti;
