import { createContext, useContext, useEffect, useReducer } from "react";
import { LOAD_ASTE } from "./actions";
import { db } from "../services/firebase";
import { reducerAste } from "./reducer";


const initialStateAste = {
  aste: [{
    siti: [],
    referente: {},
    dataFineVendita: null,
    datiProcedura: {},
    tipologiaInserzione: "",
    lotto: {},
    dataRicezione: {},
    datiVendita: {},
    giorniPubblicita: null,
    messageId: "",
    dataPubblicazione: "",
    statoAsta: "",
    idInserzioneEspVendita: "",
    eventi: null
  }],
  genere: "",
  categoria: "",

  //Inserzione
  tipologiaInserzione: "",

  //ProceduraGiudiziaria
  tribunale: "",

  //Dati Vendita
  tipologiaVendita: "",
  modalitaVendita: "",
};
 
const AsteContext = createContext();

export const AsteContextProvider = ({ children }) => {
  const [asteState, asteDispatch] = useReducer(reducerAste, initialStateAste);

  useEffect(() => {
    let aste = [];
    db.collection("Inserzioni")
      .get()
      .then((querySnap) => {
        querySnap.docs.forEach((doc) => {
            aste.push(doc.data());
        });
        asteDispatch({ type: LOAD_ASTE, payload: aste });
      });
  }, []);

  return (
    <AsteContext.Provider value={{ asteState, asteDispatch }}>
      {children}
    </AsteContext.Provider>
  );
};

export const useAste = () => useContext(AsteContext);
