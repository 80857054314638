import React from "react";
import defaultPhoto from "../../assets/default_asta.png";

const PhotoGallery = () => {
  return (
    <React.Fragment>
      <img
        src={defaultPhoto}
        width="100%"
        height={"100%"}
        alt="Immagine di default"
        style={{ objectFit: "contain" }}
      />
    </React.Fragment>
  );
};

export default PhotoGallery;
