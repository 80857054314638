import React from "react";

//hooks
import { useFormik } from "formik";

//components
import { MenuItem, Grid, FormControl, Button, TextField } from "@mui/material";
import Search from "@mui/icons-material/Search";
import { DatePicker } from "@mui/x-date-pickers";

//utils
import "../../pages/Homepage/Homepage.css";
import * as Yup from "yup";
import {
  categoriaLottoData,
  tribunaliData,
  modalitaVenditaData,
  tipologiaVenditaData,
} from "../../utils/SearchData";
import { useAste } from "../../context/useAste";
import { SEARCH_FORM } from "../../context/actions";

const FormRicercaInserzione = () => {
  const { asteDispatch } = useAste();

  const initialValues = {
    //LOTTO
    categoria: "",
    //INSERZIONE
    tipologiaInserzione: "",

    //DATIPROCEDURA-PROCEDURAGIUDIZIARIA
    tribunale: "",

    //DATI-VENDITA
    tipologiaVendita: "",
    modalitaVendita: "",
    dataInizioVendita: null,
    dataFineVendita: null,
  };

  const validationSchema = Yup.object().shape(
    {
      categoria: Yup.string(),
      tipologiaInserzione: Yup.string(),
      tribunale: Yup.string(),
      tipologiaVendita: Yup.string(),
      modalitaVendita: Yup.string(),
      dataInizioVendita: Yup.date()
        .nullable()
        .when("dataFineVendita", {
          is: (dataFineVendita) => dataFineVendita !== null,
          then: Yup.date().required("Inserisci Data Inizio Vendita").nullable(),
          otherwise: Yup.date().nullable(),
        }),
      dataFineVendita: Yup.date()
        .nullable()
        .when("dataInizioVendita", {
          is: (dataInizioVendita) => dataInizioVendita !== null,
          then: Yup.date().required("Inserisci Data Fine Vendita").nullable(),
          otherwise: Yup.date().nullable(),
        }),
    },
    [["dataInizioVendita", "dataFineVendita"]]
  );

  const submitHandler = (value) => {
    asteDispatch({ type: SEARCH_FORM, payload: { ...value } });
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: submitHandler,
  });

  const resetFilterHandler = () => {
    formik.resetForm();
  };

  return (
    <React.Fragment>
      <div className="container_filter">
        <div className="container_filter_form">
          <form onSubmit={formik.handleSubmit}>
            <Grid container padding={2} spacing={2}>
              <Grid item md={4} xs={12}>
                <FormControl fullWidth>
                  <TextField
                    select
                    className="select"
                    label="Categoria Lotto"
                    name="categoria"
                    value={formik.values.categoria}
                    onChange={formik.handleChange}
                  >
                    <MenuItem value={""}>NESSUNA CATEGORIA</MenuItem>
                    {categoriaLottoData.sort().map((item, index) => (
                      <MenuItem value={item} key={index}>
                        {item}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item md={4} xs={12}>
                <FormControl fullWidth>
                  <TextField
                    select
                    className="select"
                    label="Tipologia Inserzione"
                    name="tipologiaInserzione"
                    value={formik.values.tipologiaInserzione}
                    onChange={formik.handleChange}
                  >
                    <MenuItem value={""}>NESSUNA TIPOLOGIA</MenuItem>

                    <MenuItem value={"altra vendita"}>
                      {"Altra Vendita"}
                    </MenuItem>
                    <MenuItem value={"giudiziaria"}>{"Giudiziaria"}</MenuItem>
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item md={4} xs={12}>
                <FormControl fullWidth>
                  <TextField
                    select
                    className="select"
                    label="Tribunale"
                    name="tribunale"
                    value={formik.values.tribunale}
                    onChange={formik.handleChange}
                  >
                    <MenuItem value={""}>NESSUNA TRIBUNALE</MenuItem>

                    {tribunaliData.sort().map((item, index) => (
                      <MenuItem value={item} key={index}>
                        {item}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>

              <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                  <TextField
                    select
                    className="select"
                    label="Tipologia Vendita"
                    name="tipologiaVendita"
                    value={formik.values.tipologiaVendita}
                    onChange={formik.handleChange}
                  >
                    <MenuItem value={""}>NESSUNA TIPOLOGIA</MenuItem>

                    {tipologiaVenditaData.sort().map((item, index) => (
                      <MenuItem value={item} key={index}>
                        {item}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>

              <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                  <TextField
                    select
                    className="select"
                    label="Modalità Vendita"
                    name="modalitaVendita"
                    value={formik.values.modalitaVendita}
                    onChange={formik.handleChange}
                  >
                    <MenuItem value={""}>NESSUNA MODALITA</MenuItem>

                    {modalitaVenditaData.map((item, index) => (
                      <MenuItem value={item} key={index}>
                        {item}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>

              <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                  <DatePicker
                    label="Data Inizio Vendita"
                    inputFormat="dd/MM/yyyy"
                    name="dataInizioVendita"
                    toolbarPlaceholder="Inserisci data Inizio"
                    id="dataInizioVendita"
                    value={formik.values.dataInizioVendita}
                    onChange={(val) =>
                      formik.setFieldValue("dataInizioVendita", val)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={
                          formik.touched.dataInizioVendita &&
                          Boolean(formik.errors.dataInizioVendita)
                        }
                        helperText={
                          formik.touched.dataInizioVendita &&
                          formik.errors.dataInizioVendita
                        }
                      />
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                  <DatePicker
                    label="Data Fine Vendita"
                    inputFormat={"dd/MM/yyyy"}
                    name="dataFineVendita"
                    toolbarPlaceholder="Inserisci data fine"
                    id="dataFineVendita"
                    value={formik.values.dataFineVendita}
                    onChange={(val) =>
                      formik.setFieldValue("dataFineVendita", val)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={
                          formik.touched.dataFineVendita &&
                          Boolean(formik.errors.dataFineVendita)
                        }
                        helperText={
                          formik.touched.dataFineVendita &&
                          formik.errors.dataFineVendita
                        }
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <div className="button_container">
              <Button
                type="submit"
                color="neutral"
                variant="contained"
                onClick={resetFilterHandler}
                style={{ margin: "1rem" }}
              >
                Resetta Filtri
              </Button>
              <Button
                type="submit"
                variant="contained"
                endIcon={<Search />}
                style={{ margin: "1rem" }}
              >
                Ricerca
              </Button>
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FormRicercaInserzione;
