import axios from 'axios'

const base_url = process.env.REACT_APP_DATA_SERVICE_URL
const password = process.env.REACT_APP_DATA_SERVICE_PASSWORD


export const getStatus = async (astaId) => {
    try {
        const response = await axios({
          method: 'get', // Metodo della richiesta
          url: base_url +'/getStatus', 
          headers: {
            'Content-Type': 'application/json'
          },
          params: {
              password: password,
              astaId: astaId
          },
        //   httpsAgent: new https.Agent({
        //       rejectUnauthorized: false // Ignora la verifica del certificato SSL
        //   })
        });
        return response.data
      } catch (error) {
        console.error('Errore nella richiesta GET:', error.message);
      }

}

export const getTimer = async (astaId) => {
    try {
        const response = await axios({
          method: 'get', // Metodo della richiesta
          url: base_url +'/getTimer', 
          headers: {
            'Content-Type': 'application/json'
          },
          params: {
              password: password,
              astaId: astaId
          },
        //   httpsAgent: new https.Agent({
        //       rejectUnauthorized: false // Ignora la verifica del certificato SSL
        //   })
        });
        return response.data
      } catch (error) {
        console.error('getTimer - Errore nella richiesta GET:', error.message);
      }

}



export const pauseAsta = async (astaId) => {
    try {
        const response = await axios({
          method: 'post', // Metodo della richiesta
          url: base_url + '/pauseAsta', // URL dell'API da testare
          headers: {
            'Content-Type': 'application/json'
          },
          data: {
            password: password,
            astaId: astaId,
          },
        //   httpsAgent: new https.Agent({
        //       rejectUnauthorized: false // Ignora la verifica del certificato SSL
        //   })
        });
        return  response.data;
      } catch (error) {
        console.error('pauseAsta - Errore nella richiesta POST:', error.message);
      }
}



export const riavviaAsta = async (astaId) => {
    try {
        const response = await axios({
          method: 'post', // Metodo della richiesta
          url: base_url + '/riavviaAsta', // URL dell'API da testare
          headers: {
            'Content-Type': 'application/json'
          },
          data: {
            password: password,
            astaId: astaId,
          },
        //   httpsAgent: new https.Agent({
        //       rejectUnauthorized: false // Ignora la verifica del certificato SSL
        //   })
        });
        return  response.data;
      } catch (error) {
        console.error('riavviaAsta - Errore nella richiesta POST:', error.message);
      }
}



export const catchUltimaOfferta = async (astaId, timestamp) => {
    try {
        const response = await axios({
          method: 'post', // Metodo della richiesta
          url: base_url + '/catchUltimaOfferta', // URL dell'API da testare
          headers: {
            'Content-Type': 'application/json'
          },
          data: {
            password: password,
            astaId: astaId,
            timestamp: timestamp
          },
        //   httpsAgent: new https.Agent({
        //       rejectUnauthorized: false // Ignora la verifica del certificato SSL
        //   })
        });
        return  response.data;
      } catch (error) {
        console.error('catchUltimaOfferta - Errore nella richiesta POST:', error.message);
      }
}



export const aggiornaQuantoDiTempo = async (astaId, quanto) => {
    try {
        const response = await axios({
          method: 'post', // Metodo della richiesta
          url: base_url + '/aggiornaQuantoDiTempo', // URL dell'API da testare
          headers: {
            'Content-Type': 'application/json'
          },
          data: {
            password: password,
            astaId: astaId,
            quanto: quanto
          },
        //   httpsAgent: new https.Agent({
        //       rejectUnauthorized: false // Ignora la verifica del certificato SSL
        //   })
        });
        return  response.data;
      } catch (error) {
        console.error('aggiornaQuantoDiTempo - Errore nella richiesta POST:', error.message);
      }
}

