import React, { useState, useEffect } from "react";
import { useAsta } from "../../context/useAsta"; // Supponiamo che l'ID dell'asta venga preso da questo contesto
import { getAllegatiAsta } from "../../services/firebase"; // Importa il metodo per ottenere gli allegati
import {
  Button,
  Grid,
  Typography,
  Paper,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

const AllegatiAsta = () => {
  const { asta } = useAsta(); // Prendi l'ID dell'asta dal contesto
  const [allegati, setAllegati] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAllegati = async () => {
      if (!asta?.idInserzioneEspVendita) return;

      try {
        console.log("ID asta: " + asta.idInserzioneEspVendita);
        const allegatiData = await getAllegatiAsta(asta.idInserzioneEspVendita);
        console.log("Allegati recuperati: ", allegatiData);
        setAllegati(allegatiData);
        setLoading(false);
      } catch (error) {
        console.error("Errore durante il recupero degli allegati:", error);
        setError(error);
        setLoading(false);
      }
    };

    fetchAllegati();
  }, [asta]);

  if (loading) {
    return (
      <Grid container justifyContent="center" alignItems="center" style={{ minHeight: "50vh" }}>
        <CircularProgress />
        <Typography variant="h6" style={{ marginTop: 20 }}>
          Caricamento allegati...
        </Typography>
      </Grid>
    );
  }

  if (error) {
    return (
      <Grid container justifyContent="center" alignItems="center" style={{ minHeight: "50vh" }}>
        <Typography variant="h6" color="error">
          Errore durante il caricamento degli allegati.
        </Typography>
      </Grid>
    );
  }

  return (
    <div style={{ padding: "20px" }}>
      <Typography variant="h4" gutterBottom>
        Allegati dell'asta
      </Typography>
      {allegati.length > 0 ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Titolo</TableCell>
                <TableCell align="right">Azione</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allegati.map((allegato) => (
                <TableRow key={allegato.id}>
                  <TableCell>{allegato.titolo}</TableCell>
                  <TableCell align="right">
                    <Button
                      variant="contained"
                      color="primary"
                      href={allegato.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Scarica
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant="body1">Nessun allegato disponibile per questa asta.</Typography>
      )}
    </div>
  );
};

export default AllegatiAsta;
