import React, { memo } from "react";
import {
  Modal,
  Box,
  AccordionSummary,
  AccordionDetails,
  Accordion,
  Grid,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import FileDownload from "js-file-download";
import X2JS from "x2js";

const ModalDatiBusta = memo(({ open, onClose, busta }) => {
  const { offertaRequest } = busta;

  const docAnonimoUrl = offertaRequest.docAnonimo
  const pecOffertaUrl = offertaRequest.pecOfferta
  const bolloDigitaleUrl = offertaRequest.bolloDigitale

  const downloadFile = async (url, fileName) => {
    if (!url) {
      console.error("URL is undefined or empty.");
      return;
    }

    try {
      const response = await fetch(url);
      const blob = await response.blob();
      FileDownload(blob, fileName);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const downloadXML = async () => {
    const x2js = new X2JS();
    const new_xml = x2js.js2xml(offertaRequest.offIntegrale);
    const xmltext = `<xml>${new_xml}</xml>`;
    const fileName = "OffertaIntegrale.xml";
    const bb = new Blob([xmltext], { type: "text/plain" });
    FileDownload(bb, fileName);
  };

  const downloadDocumentoAnonimo = async () => {
    const fileName = "documentoAnonimo.pdf";
    const url = docAnonimoUrl; // Supponendo che tu abbia già l'URL
    await downloadFile(url, fileName);
  };

  const downloadPecOfferta = async () => {
    const fileName = "pecOfferta.eml";
    const url = pecOffertaUrl; // Supponendo che tu abbia già l'URL
    await downloadFile(url, fileName);
  };

  const downloadBolloDigitale = async () => {
    const x2js = new X2JS();
    const new_xml = x2js.js2xml(offertaRequest.bolloDigitale);
    const xmltext = `<xml>${new_xml}</xml>`;
    const fileName = "BolloDigitale.xml";
    const bb = new Blob([xmltext], { type: "text/plain" });
    FileDownload(bb, fileName);
  };

  const downloadAllegato = async (allegato) => {
    const fileName = `${allegato.nome}.${allegato.estensione}`;
    const url = allegato.url; // Supponendo che l'URL dell'allegato sia già disponibile nell'oggetto allegato
    await downloadFile(url, fileName);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box className="modal_dati_anagrafici">
        <div className="modal_assisti_title">Contenuto Busta</div>

        {/**FILE*/}
        <Accordion className="modal_dati_accordition">
          <AccordionSummary expandIcon={<ExpandMore />}>
            <div style={{ fontWeight: 600, color: "white" }}>FILE</div>
          </AccordionSummary>
          <AccordionDetails
            style={{ backgroundColor: "white", borderRadius: "0.5rem" }}
            className="modal_dati_accordition_details"
          >
            <Grid container padding={2} spacing={1}>
              <Grid item xs={12}>
                <div onClick={downloadXML} className="text_title_dati">
                  Offerta Integrale (XML)
                </div>
              </Grid>
              <Grid item xs={12}>
                <div
                  className="text_title_dati"
                  onClick={downloadDocumentoAnonimo}
                >
                  Documento Anonimo
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="text_title_dati" onClick={downloadPecOfferta}>
                  Pec Offerta
                </div>
              </Grid>
              {offertaRequest.bolloDigitale && (
                <Grid item xs={12}>
                  <div
                    className="text_title_dati"
                    onClick={downloadBolloDigitale}
                  >
                    Bollo Digitale
                  </div>
                </Grid>
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Accordion className="modal_dati_accordition">
          <AccordionSummary expandIcon={<ExpandMore />}>
            <div style={{ fontWeight: 600, color: "white" }}>ALLEGATI</div>
          </AccordionSummary>

          {offertaRequest.allegati.map((allegato) => (
            <AccordionDetails
              key={allegato.idAllegato}
              style={{ backgroundColor: "white", borderRadius: "0.5rem" }}
              className="modal_dati_accordition_details"
            >
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  Id allegato:
                </Grid>
                <Grid item xs={6} fontWeight={600}>
                  {allegato.idAllegato}
                </Grid>
                <Grid item xs={6}>
                  Allegato per:
                </Grid>
                <Grid item xs={6} fontWeight={600}>
                  {allegato.allegatoPer}
                </Grid>
                <Grid item xs={6}>
                  Categoria:
                </Grid>
                <Grid item xs={6} fontWeight={600}>
                  {allegato.categoria}
                </Grid>
                <Grid item xs={6}>
                  Contenuto:
                </Grid>
                <Grid item xs={6}>
                  <div className="text_title_dati" onClick={() => { downloadAllegato(allegato) }}>
                    {`${allegato.nome}.${allegato.estensione}`}
                  </div>
                </Grid>
              </Grid>
            </AccordionDetails>
          ))}
        </Accordion>
      </Box>
    </Modal>
  );
});

export default ModalDatiBusta;
