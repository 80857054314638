import React from "react";
import { useState, useCallback } from "react";
import { memo } from "react";

//hooks
import { useAsta } from "../../../../context/useAsta";

//@mui
import {
  Modal,
  Box,
  FormControl,
  MenuItem,
  TextField,
  Tab,
  Button,
} from "@mui/material";
import { TabPanel, TabContext, TabList } from "@mui/lab";

//components
import CustomModal from "../../../../utils/CustomModal/CustomModal";

//utils
import "./PannelloControllo.css";
import accounting from "accounting";
import {
  terminaAstaAltroOfferente,
  terminaAstaMigliorOfferente,
} from "../../../../services/firebase";
import { toast } from "react-toastify";

const ModalAggiudicaInserzione = memo(({ isOpen, handleClose }) => {
  const { buste, asta } = useAsta();
  const [selectedTab, setSelectedTab] = useState("0");
  const [aggiudicatario, setAggiudicatario] = useState("");

  const [modalMigliorOfferente, setModalMigliorOfferente] = useState(false);
  const [modalAltroOfferente, setModalAltroOfferente] = useState(false);

  //Se scegli di indicare l'offerente con l'offerta migliore, individuo la busta dell'offerente , utilizzando il campo
  //miglior offerente , mentre l'importo è memorizzato nel campo migliore offerta!
  const bustaMigliorOfferente = buste.filter(
    (busta) => busta.credenziali.username === asta.miglioreOfferente
  );

  const busteMiglioriOfferte = buste.map((busta) => {
    let maxRilancio = 0;
    asta.rilanci.forEach((rilancio) => {
      if (
        rilancio.offerente === busta.credenziali.username &&
        Number(maxRilancio) < Number(rilancio.rilancio)
      ) {
        maxRilancio = rilancio.rilancio;
      }
    });

    if (maxRilancio === 0) {
      return {
        offertaMigliore:
          busta.offertaRequest.offIntegrale.offerta.importoOfferta,
        busta: busta,
      };
    } else {
      return {
        offertaMigliore: maxRilancio,
        busta: busta,
      };
    }
  });

  const handleChange = (e, newValue) => {
    setSelectedTab(newValue);
  };

  const bustaMigliorOffertaHandler = async () => {
    try {
      await terminaAstaMigliorOfferente(
        asta.idInserzioneEspVendita,
        asta.miglioreOfferente,
        asta.miglioreOfferta
      );

      toast.success("Complimenti l'asta è stata aggiudicata !");
      handleClose();
    } catch (error) {
      console.log(error);
      toast.error("Si è verificato un errore ");
    }
  };

  const bustaAltroOfferenteHandler = async () => {
    const bustaScelta = busteMiglioriOfferte[aggiudicatario];

    try {
      await terminaAstaAltroOfferente(
        asta.idInserzioneEspVendita,
        bustaScelta.busta.credenziali.username,
        bustaScelta.miglioreOfferta
      );
      toast.success("Complimenti l'asta è stata aggiudicata !");
      handleClose();
    } catch (error) {
      console.log(error);
      toast.error("Attenzione si è verificato un errore");
    }
  };

  let bestOffer =
    bustaMigliorOfferente &&
    ` ${
      bustaMigliorOfferente[0].offertaRequest?.offIntegrale?.presentatore
        ?.datiAnagrafici?.nome
    } ${
      bustaMigliorOfferente[0].offertaRequest?.offIntegrale?.presentatore
        ?.datiAnagrafici?.cognome
    } (${
      bustaMigliorOfferente[0].credenziali.username
    }) - ${accounting.formatMoney(asta?.miglioreOfferta, "€")} `;

  const stringOffertaBusta = (documento) =>
    ` ${
      documento.busta.offertaRequest?.offIntegrale?.presentatore?.datiAnagrafici
        ?.nome
    }  ${
      documento.busta.offertaRequest?.offIntegrale?.presentatore?.datiAnagrafici
        ?.cognome
    }  (${documento.busta.credenziali.username}) - ${accounting.formatMoney(
      documento?.offertaMigliore,
      "€"
    )}`;

  /************ MODAL ********/
  const closeModalMigliorOfferente = useCallback(() => {
    setModalMigliorOfferente(false);
  }, []);

  const closeModalAltroOfferente = useCallback(() => {
    setModalAltroOfferente(false);
  }, []);

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box className="modal_aggiudica_inserzione">
        <div className="modal_aggiudica_inserzione_title ">
          AGGIUDICA INSERZIONE
        </div>
        <div>
          Clicca su "Aggiudica al miglior offerente" per aggiudicare la vendita
          al miglior offerente. Clicca su "Aggiudica ad altro offerente" nel
          caso in cui si vuole scegliere un aggiudicatario differente dal
          miglior offerente.
        </div>

        <TabContext value={selectedTab}>
          <Box
            sx={{ borderBottom: 1, borderColor: "divider", marginTop: "2rem" }}
          >
            <TabList onChange={handleChange}>
              <Tab label="Aggiudica al miglior offerente" value="0" />
              <Tab label="Aggiudica ad altro offerente" value="1" />
            </TabList>
          </Box>
          <TabPanel value="0">
            <div style={{ marginBottom: "1rem" }}>
              Seleziona l'aggiudicatario di questa inserzione:
            </div>
            <FormControl fullWidth>
              <TextField
                select
                className="select"
                label="Aggiudicatario"
                name="aggiudicatario"
                value={aggiudicatario}
                onChange={(e) => setAggiudicatario(e.target.value)}
              >
                <MenuItem value={bestOffer}>{bestOffer}</MenuItem>
              </TextField>
            </FormControl>

            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              marginTop={3}
            >
              <Button
                variant="contained"
                onClick={() => setModalMigliorOfferente(true)}
              >
                {" "}
                Conferma Aggiudicatario
              </Button>
            </Box>
          </TabPanel>

          <TabPanel value="1">
            <div style={{ marginBottom: "1rem" }}>
              Seleziona l'aggiudicatario di questa inserzione:
            </div>
            <FormControl fullWidth>
              <TextField
                select
                className="select"
                label="Aggiudicatario"
                name="aggiudicatario"
                value={aggiudicatario}
                onChange={(e) => setAggiudicatario(e.target.value)}
              >
                {busteMiglioriOfferte.map((busta, index) => (
                  <MenuItem key={index} value={index}>
                    {stringOffertaBusta(busta)}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>

            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              marginTop={3}
            >
              <Button
                variant="contained"
                onClick={() => setModalMigliorOfferente(true)}
              >
                {" "}
                Conferma Aggiudicatario
              </Button>
            </Box>
          </TabPanel>
        </TabContext>

        {modalMigliorOfferente && (
          <CustomModal
            title={"Sei sicuro di voler aggiudicare l'asta a " + bestOffer}
            content="Attenzione, una volta confermato non potrai più annullare l'operazione."
            openModal={modalMigliorOfferente}
            handleClose={closeModalMigliorOfferente}
            submitModal={bustaMigliorOffertaHandler}
            color={"green"}
          />
        )}

        {modalAltroOfferente && (
          <CustomModal
            title={
              "Sei sicuro di voler aggiudicare l'asta a " +
              stringOffertaBusta(busteMiglioriOfferte[aggiudicatario])
            }
            content="Attenzione, una volta confermato non potrai più annullare l'operazione."
            openModal={modalAltroOfferente}
            handleClose={closeModalAltroOfferente}
            submitModal={bustaAltroOfferenteHandler}
            color={"green"}
          />
        )}
      </Box>
    </Modal>
  );
});

export default ModalAggiudicaInserzione;
