import React from "react";
import SignUpForm from "../../components/SignUp/SignUpForm";
import "./SignUp.css";
import { Helmet } from "react-helmet-async";

const SignUp = () => {
  return (
    <div className="container">
      <Helmet>
        <title> ASTALEX | Registrazione</title>
        <meta
          name="description"
          content="Registrati in Astalex.it per partecipare ad un'asta."
        />
        <link rel="canonical" href="/registrati" />
      </Helmet>
      <SignUpForm />
    </div>
  );
};

export default SignUp;
