import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { db } from "../services/firebase";

export const useChat = () => {
    const [chatItems, setChatItems] = useState([]);
    const { id } = useParams();

    useEffect(() => {
      // Crea un listener che osserva le modifiche al documento specificato in tempo reale
      const unsubscribe = db.collection("ChatInserzioni")
        .doc(id)
        .onSnapshot((doc) => {
          // Controlla se il documento esiste e ha il campo 'chatItems'
          if (doc.exists) {
            const data = doc.data();
            if (data && data.chatItems) {
              setChatItems(data.chatItems);
            } else {
              // Se non c'è un campo 'chatItems', imposta un array vuoto
              setChatItems([]);
            }
          } else {
            // Se il documento non esiste, imposta un array vuoto
            console.log("Il documento non esiste nella collezione!");
            setChatItems([]);
          }
        });

      // Questa è la funzione di cleanup che viene eseguita quando il componente viene smontato
      return () => {
        // Disconnette il listener per evitare perdite di memoria
        unsubscribe();
      };
    }, [id]); // L'array di dipendenze con solo 'id' assicura che il listener sia allegato una sola volta per ogni 'id'

    return {
      chatItems,
    };
};