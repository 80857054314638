import React, { useCallback } from "react";
import { useState } from "react";

//@mui
import { Box, Button } from "@mui/material";
import AddBoxIcon from "@mui/icons-material/AddBox";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";

//components
import ModalAggiudicaInserzione from "./ModalAggiudicaInserzione";
import ModalTerminaEsameOfferte from "./ModalTerminaEsameOfferte";
import ModalUnicaOfferta from "./ModalUnicaOfferta";
import AstaDeserta from "./AstaDeserta";
import CustomModal from "../../../../utils/CustomModal/CustomModal";

//hooks
import { useAsta } from "../../../../context/useAsta";

//firebase
import { sospendiAsta } from "../../../../services/firebase";

//utils
import "./PannelloControllo.css";
import { toast } from "react-toastify";
import { motion } from "framer-motion";
import { getPdf, STATI_ASTA } from "../../../../utils/Utils";
import { convertBase64ToBlob } from "../../../../utils/Utils";
import FileDownload from "js-file-download";

const PannelloControllo = () => {
  const { asta, setAulaVirtuale, buste } = useAsta();
  const [isOpen, setOpen] = useState(false);

  //Modal
  const [modalSospendiVendita, setModalSospendiVendita] = useState(false);
  const [modalTerminaEsameOfferta, setModalTerminaEsameOfferte] =
    useState(false);
  const [modalUnicaOfferta, setModalUnicaOfferta] = useState(false);
  const [modalAggiudicaInserzione, setModalAggiudicaInserzione] =
    useState(false);

  /*   const [modalAggiungiOffertaCartacea,setAggiungiOffertaCartacea]=useState(false)
   */

  const checkBusteAsta = () => {
    if (buste.length === 1) {
      setModalUnicaOfferta(true);
    } else {
      setModalTerminaEsameOfferte(true);
    }
  };

  const closeModalTerminaOfferte = useCallback(() => {
    setModalTerminaEsameOfferte(false);
  }, []);

  const closeModalUnicaOfferta = useCallback(() => {
    setModalUnicaOfferta(false);
  }, []);

  const closeModalSospendiVendita = useCallback(() => {
    setModalSospendiVendita(false);
  }, []);

  const closeModalAggiudicaInserzione = useCallback(() => {
    setModalAggiudicaInserzione(false);
  }, []);

  const sospendiVenditaHandler = async () => {
    try {
      await sospendiAsta(asta.idInserzioneEspVendita);
      setAulaVirtuale(false);
      toast.success("La gara è stata sospesa !");
    } catch (error) {
      toast.error("Si è verificato un errore");
      console.log(error);
    }
  };

  const scaricaPdfGara = async () => {
    try {
      const pdfResponse = await getPdf(
        String(asta.idInserzioneEspVendita)
      );
     const blob = convertBase64ToBlob(pdfResponse.data.report);
      const fileName = `Report ${asta.idInserzioneEspVendita}.pdf`;
      FileDownload(blob, fileName);
    } catch (error) {
      toast.error("Si è verificato un errore");
      console.log(error);
    }
  };

  return (
    <React.Fragment>
      <div className="pannello_dropdown_container">
        {!isOpen ? (
          <AddBoxIcon
            color="primary"
            style={{ cursor: "pointer" }}
            onClick={() => setOpen(true)}
          />
        ) : (
          <IndeterminateCheckBoxIcon
            color="primary"
            style={{ cursor: "pointer" }}
            onClick={() => setOpen(false)}
          />
        )}
        <div style={{ fontWeight: 500 }}>Pannello di Controllo </div>
      </div>

      {isOpen && (
        <motion.div
          initial={{
            opacity: 0,
          }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          exit={{ opacity: 0 }}
          className="pannello_controllo_button_container"
        >
          {!asta.dataFineVendita && (
            <Button
              color="primary"
              variant="contained"
              onClick={checkBusteAsta}
            >
              {" "}
              ESAME OFFERTE TERMINATO{" "}
            </Button>
          )}

          {asta.statoAsta === STATI_ASTA.IN_CORSO && (
            <React.Fragment>
              <Box display={"flex"} justifyContent="center" width="100%">
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => setModalAggiudicaInserzione(true)}
                >
                  {" "}
                  AGGIUDICA
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  sx={{ marginLeft: "0.5rem" }}
                  onClick={() => setModalSospendiVendita(true)}
                >
                  {" "}
                  SOSPENDI VENDITA{" "}
                </Button>
              </Box>
            </React.Fragment>
          )}

          {asta.statoAsta === STATI_ASTA.TERMINATA_OK_INVIO &&
            asta.esitoAsta === "AGGIUDICATA" && (
              <Button variant="contained" onClick={scaricaPdfGara}>
                {" "}
                Scarica Pdf Gara
              </Button>
            )}
        </motion.div>
      )}

      {modalTerminaEsameOfferta &&
        (buste.length === 0 ? (
          <AstaDeserta
            handleClose={closeModalTerminaOfferte}
            isOpen={modalTerminaEsameOfferta}
          />
        ) : (
          <ModalTerminaEsameOfferte
            handleClose={closeModalTerminaOfferte}
            isOpen={modalTerminaEsameOfferta}
          />
        ))}

      {modalUnicaOfferta && (
        <ModalUnicaOfferta
          handleClose={closeModalUnicaOfferta}
          isOpen={modalUnicaOfferta}
        />
      )}

      {modalSospendiVendita && (
        <CustomModal
          title={"Sei sicuro di voler sospendere la vendita?"}
          content="Attenzione, una volta confermato non potrai più annullare l'operazione."
          openModal={modalSospendiVendita}
          handleClose={closeModalSospendiVendita}
          submitModal={sospendiVenditaHandler}
          color={"green"}
        />
      )}

      {modalAggiudicaInserzione && (
        <ModalAggiudicaInserzione
          isOpen={modalAggiudicaInserzione}
          handleClose={closeModalAggiudicaInserzione}
        />
      )}
    </React.Fragment>
  );
};

export default PannelloControllo;
