/*
Il componente EventiAsta in React è progettato per visualizzare una lista di eventi o esiti associati a un'asta specifica. 
Utilizzando il hook useAsta, accede ai dati dell'asta corrente e, se disponibili, elenca gli eventi legati all'asta. 
Per ogni evento, mostra dettagli come la tipologia dell'evento e eventuali note ad esso collegate.
*/


import React from "react";

//hooks
import { useAsta } from "../../context/useAsta";

import { Grid } from "@mui/material";
//utils
import "../../pages/Asta/Asta.css";

const EventiAsta = () => {
  const { asta } = useAsta();

  if (asta?.inserzioneEspVendita?.eventi) {
    return (
      <div className="eventi_container">
        <div className="eventi_title"> EVENTI/ESITO</div>
        {asta?.inserzioneEspVendita?.eventi.map((evento,index) => (
          <Grid container spacing={1} key={index}>
            <Grid item md={6}>
              Tipologia:
            </Grid>
            <Grid item md={6}>
              {evento.tipologia}
            </Grid>
            <Grid item md={6}>
              Note:
            </Grid>
            <Grid item md={6}>
              {evento.nota}
            </Grid>
          </Grid>
        ))}
      </div>
    );
  }

  return null;
};

export default EventiAsta;
