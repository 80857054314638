import React from "react";
import { memo } from "react";
import { useEffect, useState } from "react";

//@mui
import { Box } from "@mui/system";
import { Grid, TextField, Button, MenuItem } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

//hooks
import { useFormik } from "formik";

//utils
import * as Yup from "yup";
import "./OfferteCartacee.css";
import { NAZIONI, REGIONI } from "../../../../utils/DATA";
import { getComuni, getProvincie } from "../../../../services/formServices";
import moment from "moment";

const DatiPresentatore = memo(
  ({ datiPresentatore, setDatiPresentatore, nextPage, prevPage }) => {
    const [provincie, setProvincie] = useState([]);
    const [comuni, setComuni] = useState([]);

    const initialValues = {
      
      /************************ DATI ANAGRAFICI  ****************************************/
      nome: datiPresentatore?.presentatore?.datiAnagrafici?.nome || "",
      cognome: datiPresentatore?.presentatore?.datiAnagrafici?.cognome || "",
      codiceFiscale:
        datiPresentatore?.presentatore?.datiAnagrafici?.codiceFiscale || "",
      dataNascita: datiPresentatore?.presentatore
        ? moment(
            new Date(
              `${datiPresentatore?.presentatore?.datiAnagrafici?.meseNascita}/${datiPresentatore?.presentatore?.datiAnagrafici?.giornoNascita}/${datiPresentatore?.presentatore?.datiAnagrafici?.annoNascita}`
            )
          ).format("MM/DD/YYYY")
        : "",
      comuneNascita:
        datiPresentatore?.presentatore?.datiAnagrafici?.comuneNascita || "",
      comuneNascitaEstero:
        datiPresentatore?.presentatore?.datiAnagrafici?.comuneNascitaEstero ||
        "",
      provinciaNascita:
        datiPresentatore?.presentatore?.datiAnagrafici?.provinciaNascita || "",
      regioneNascita:
        datiPresentatore?.presentatore?.datiAnagrafici?.regioneNascita || "",
      nazioneNascita:
        datiPresentatore?.presentatore?.datiAnagrafici?.nazioneNascita || "",
      cittadinanza1:
        datiPresentatore?.presentatore?.datiAnagrafici?.cittadinanza1 || "",
      cittadinanza2:
        datiPresentatore?.presentatore?.datiAnagrafici?.cittadinanza2 || "",

      /************************ CONTATTI  ****************************************/
      email: datiPresentatore?.presentatore?.contatti?.email || "",
      pec: datiPresentatore?.presentatore?.contatti?.pec || "",
      cellulare: datiPresentatore?.presentatore?.contatti?.cellulare || "",
      telefono: datiPresentatore?.presentatore?.contatti?.telefono || "",

      /************************ INDIRIZZO RESIDENZA ****************************************/

      indirizzoResidenza:
        datiPresentatore?.presentatore?.residenza?.indirizzoResidenza || "",
      nazioneResidenza:
        datiPresentatore?.presentatore?.residenza?.nazioneResidenza || "",

      //ITALIA
      civicoResidenza:
        datiPresentatore?.presentatore?.residenza?.civicoResidenza || "",
      comuneResidenza:
        datiPresentatore?.presentatore?.residenza?.comuneResidenza || "",
      provinciaResidenza:
        datiPresentatore?.presentatore?.residenza?.provinciaResidenza || "",
      regioneResidenza:
        datiPresentatore?.presentatore?.residenza?.regioneResidenza || "",
      capZipCodeResidenza:
        datiPresentatore?.presentatore?.residenza?.capZipCodeResidenza || "",

      //ESTERO
      comuneEsteroResidenza:
        datiPresentatore?.presentatore?.residenza?.comuneEsteroResidenza || "",
      capZipCodeEsteroResidenza:
        datiPresentatore?.presentatore?.residenza?.capZipCodeEsteroResidenza ||
        "",

      /************************ INDIRIZZO DOMICILIO ****************************************/

      indirizzoDomicilio:
        datiPresentatore?.presentatore?.domicilio?.indirizzoDomicilio || "",
      nazioneDomicilio:
        datiPresentatore?.presentatore?.domicilio?.nazioneDomicilio || "",

      //ITALIA
      civicoDomicilio:
        datiPresentatore?.presentatore?.domicilio?.civicoDomicilio || "",
      comuneDomicilio:
        datiPresentatore?.presentatore?.domicilio?.comuneDomicilio || "",
      provinciaDomicilio:
        datiPresentatore?.presentatore?.domicilio?.provinciaDomicilio || "",
      regioneDomicilio:
        datiPresentatore?.presentatore?.domicilio?.regioneDomicilio || "",
      capZipCodeDomicilio:
        datiPresentatore?.presentatore?.domicilio?.capZipCodeDomicilio || "",

      //ESTERO
      comuneEsteroDomicilio:
        datiPresentatore?.presentatore?.domicilio?.comuneEsteroDomicilio || "",
      capZipCodeEsteroDomicilio:
        datiPresentatore?.presentatore?.domicilio?.capZipCodeEsteroDomicilio ||
        "",
    };

    const validationSchema = Yup.object({
      /************************ DATI ANAGRAFICI  ****************************************/

      nazioneNascita: Yup.string().required("Inserisci nazione nascita"),
      cittadinanza1: Yup.string().required("Inserisci cittadinanza"),
      cittadinanza2: Yup.string(),
      nome: Yup.string().required("Inserisci nome"),
      cognome: Yup.string().required("Inserisci cognome"),
      dataNascita: Yup.string().required("Inserisci data di nascita"),
      codiceFiscale: Yup.string().required("Inserisci codice fiscale"),
      comuneNascitaEstero: Yup.string().when("nazioneNascita", {
        is: (nazioneNascita) => nazioneNascita !== "Italia",
        then: Yup.string().required("Inserisci comune estero"),
      }),
      regioneNascita: Yup.string().when("nazioneNascita", {
        is: (nazioneNascita) => nazioneNascita === "Italia",
        then: Yup.string().required("Inserisci regione "),
      }),
      provinciaNascita: Yup.string().when("nazioneNascita", {
        is: (nazioneNascita) => nazioneNascita === "Italia",
        then: Yup.string().required("Inserisci regione "),
      }),
      comuneNascita: Yup.string().when("nazioneNascita", {
        is: (nazioneNascita) => nazioneNascita === "Italia",
        then: Yup.string().required("Inserisci regione "),
      }),

      /************************ CONTATTI  ****************************************/

      email: Yup.string(),
      pec: Yup.string().required("Inserisci e-mail"),
      cellulare: Yup.string().required("Inserisci cellulare"),
      telefono: Yup.string(),

      /************************ INDIRIZZO RESIDENZA ****************************************/

      indirizzoResidenza: Yup.string().required(
        "Inserisci indirizzo residenza"
      ),
      nazioneResidenza: Yup.string().required("Inserisci nazione residenza"),

      //RESIDENZA--ITALIA
      civicoResidenza: Yup.string(),
      comuneResidenza: Yup.string(),
      provinciaResidenza: Yup.string(),
      regioneResidenza: Yup.string(),
      capZipCodeResidenza: Yup.string(),

      //RESIDENZA--ESTERO
      comuneEsteroResidenza: Yup.string(),
      capZipCodeEsteroResidenza: Yup.string(),

      /************************ INDIRIZZO DOMICILIO ****************************************/
      indirizzoDomicilio: Yup.string().required(
        "Inserisci indirizzo domicilio"
      ),
      nazioneDomicilio: Yup.string().required("Inserisci nazione domicilio"),

      //RESIDENZA--ITALIA
      civicoDomicilio: Yup.string(),
      comuneDomicilio: Yup.string(),
      provinciaDomicilio: Yup.string(),
      regioneDomicilio: Yup.string(),
      capZipCodeDomicilio: Yup.string(),

      //RESIDENZA--ESTERO
      comuneEsteroDomicilio: Yup.string(),
      capZipCodeEsteroDomicilio: Yup.string(),
    });

    const submitHandler = (value) => {
      const checkDateNascita = moment(value.dataNascita).format("DD/MM/YYYY");

      const arrayData = checkDateNascita.split("/");
      var meseNascita = Number(arrayData[1]);
      var giornoNascita = Number(arrayData[0]);
      var annoNascita = Number(arrayData[2]);

      const presentatoreInfo = {
        presentatore: {
          datiAnagrafici: {
            nome: value.nome,
            cognome: value.cognome,
            codiceFiscale: value.codiceFiscale,
            meseNascita: Number(meseNascita),
            giornoNascita: Number(giornoNascita),
            annoNascita: Number(annoNascita),

            comuneNascita: value.comuneNascita,
            comuneNascitaEstero: value.comuneNascitaEstero,
            provinciaNascita: value.provinciaNascita,
            regioneNascita: value.regioneNascita,
            nazioneNascita: value.nazioneNascita,
            cittadinanza1: value.cittadinanza1,
            cittadinanza2: value.cittadinanza2,
            partitaIva: "",
            denominazione: "",
          },
          contatti: {
            email: value.email,
            pec: value.pec,
            cellulare: value.cellulare,
            telefono: value.telefono,
          },
          residenza: {
            indirizzoResidenza: value.indirizzoResidenza,
            nazioneResidenza: value.nazioneResidenza,
            civicoResidenza:value.civicoResidenza,
            comuneResidenza:
              value.nazioneResidenza === "Italia" ? value.comuneResidenza : "",
            provinciaResidenza:
              value.nazioneResidenza === "Italia"
                ? value.provinciaResidenza
                : "",
            regioneResidenza:
              value.nazioneResidenza === "Italia" ? value.regioneResidenza : "",
            capZipCodeResidenza:
              value.nazioneResidenza === "Italia"
                ? value.capZipCodeResidenza
                : "",
            comuneEsteroResidenza:
              value.nazioneResidenza !== "Italia"
                ? value.comuneEsteroResidenza
                : "",
            capZipCodeEsteroResidenza:
              value.nazioneResidenza !== "Italia"
                ? value.capZipCodeEsteroResidenza
                : "",
          },
          domicilio: {
            indirizzoDomicilio: value.indirizzoDomicilio,
            nazioneDomicilio: value.nazioneDomicilio,
            civicoDomicilio:value.civicoDomicilio,
            comuneDomicilio:
              value.nazioneDomicilio === "Italia" ? value.comuneDomicilio : "",
            provinciaDomicilio:
              value.nazioneDomicilio === "Italia"
                ? value.provinciaDomicilio
                : "",
            regioneDomicilio:
              value.nazioneDomicilio === "Italia" ? value.regioneDomicilio : "",
            capZipCodeDomicilio:
              value.nazioneDomicilio === "Italia"
                ? value.capZipCodeDomicilio
                : "",
            comuneEsteroDomicilio:
              value.nazioneDomicilio !== "Italia"
                ? value.comuneEsteroDomicilio
                : "",
            capZipCodeEsteroDomicilio:
              value.nazioneDomicilio !== "Italia"
                ? value.capZipCodeEsteroDomicilio
                : "",
          },
        },
      };

      setDatiPresentatore(presentatoreInfo);
      nextPage();
    };

    const formik = useFormik({
      initialValues: initialValues,
      validationSchema: validationSchema,
      onSubmit: submitHandler,
    });

    const { setFieldValue } = formik;

    useEffect(() => {
      (async () => {
        if (formik.values.regioneNascita !== "") {
          setFieldValue("provinciaNascita", "");
          setFieldValue("comuneNascita", "");

          try {
            const data = await getProvincie(formik.values.regioneNascita);
            setProvincie(data);
          } catch (error) {
            console.log(error);
          }
        }
      })();
    }, [formik.values.regioneNascita, setFieldValue]);

    useEffect(() => {
      (async () => {
        if (formik.values.provinciaNascita !== "") {
          setFieldValue("comuneNascita", "");
          try {
            const data = await getComuni(formik.values.provinciaNascita);
            setComuni(data);
          } catch (error) {
            console.log(error);
          }
        }
      })();
    }, [formik.values.provinciaNascita, setFieldValue]);

    return (
      <form onSubmit={formik.handleSubmit}>
        <div className="modal_title_presentatore">
          Dati anagrafici presentatore
        </div>
        <Grid container padding={3} spacing={3}>
          <Grid item xs={12} md={4}>
            <TextField
              id="nome"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.nome}
              label="Nome"
              error={formik.touched.nome && Boolean(formik.errors.nome)}
              helperText={formik.touched.nome && formik.errors.nome}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              id="cognome"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.cognome}
              label="Cognome"
              error={formik.touched.cognome && Boolean(formik.errors.cognome)}
              helperText={formik.touched.cognome && formik.errors.cognome}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              id="codiceFiscale"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.codiceFiscale}
              label="Codice Fiscale"
              error={
                formik.touched.codiceFiscale &&
                Boolean(formik.errors.codiceFiscale)
              }
              helperText={
                formik.touched.codiceFiscale && formik.errors.codiceFiscale
              }
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              select
              className="select"
              label="Nazione di nascita"
              name="nazioneNascita"
              value={formik.values.nazioneNascita}
              onChange={formik.handleChange}
              helperText={
                formik.touched.nazioneNascita && formik.errors.nazioneNascita
              }
              error={
                formik.touched.nazioneNascita &&
                Boolean(formik.errors.nazioneNascita)
              }
              fullWidth
            >
              {NAZIONI.map((nazione) => (
                <MenuItem value={nazione.name} key={nazione.id}>
                  {" "}
                  {nazione.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              select
              className="select"
              label="Paese di cittadinanza 1"
              name="cittadinanza1"
              value={formik.values.cittadinanza1}
              onChange={formik.handleChange}
              helperText={
                formik.touched.cittadinanza1 && formik.errors.cittadinanza1
              }
              error={
                formik.touched.cittadinanza1 &&
                Boolean(formik.errors.cittadinanza1)
              }
              fullWidth
            >
              {NAZIONI.map((nazione) => (
                <MenuItem value={nazione.name} key={nazione.id}>
                  {" "}
                  {nazione.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              select
              className="select"
              label="Paese di cittadinanza 2"
              name="cittadinanza2"
              value={formik.values.cittadinanza2}
              onChange={formik.handleChange}
              helperText={
                formik.touched.cittadinanza2 && formik.errors.cittadinanza2
              }
              error={
                formik.touched.cittadinanza2 &&
                Boolean(formik.errors.cittadinanza2)
              }
              fullWidth
            >
              {NAZIONI.map((nazione) => (
                <MenuItem value={nazione.name} key={nazione.id}>
                  {" "}
                  {nazione.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={12}>
            <DatePicker
              label="Data di Nascita"
              inputFormat="dd/MM/yyyy"
              name="dataNascita"
              toolbarPlaceholder="Data Nascita"
              id="dataNascita"
              value={formik.values.dataNascita}
              onChange={(val) => formik.setFieldValue("dataNascita", val)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={
                    formik.touched.dataNascita &&
                    Boolean(formik.errors.dataNascita)
                  }
                  fullWidth
                  helperText={
                    formik.touched.dataNascita && formik.errors.dataNascita
                  }
                />
              )}
            />
          </Grid>
          {formik.values.nazioneNascita === "Italia" && (
            <>
              <Grid item xs={12} md={4}>
                <TextField
                  select
                  className="select"
                  label="Regione Nascita"
                  name="regioneNascita"
                  value={formik.values.regioneNascita}
                  onChange={formik.handleChange}
                  helperText={
                    formik.touched.regioneNascita &&
                    formik.errors.regioneNascita
                  }
                  error={
                    formik.touched.regioneNascita &&
                    Boolean(formik.errors.regioneNascita)
                  }
                  fullWidth
                >
                  {REGIONI.map((regione) => (
                    <MenuItem value={regione.nome} key={regione.id}>
                      {" "}
                      {regione.nome}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  select
                  className="select"
                  label="Provincia Nascita"
                  name="provinciaNascita"
                  value={formik.values.provinciaNascita}
                  onChange={formik.handleChange}
                  helperText={
                    formik.touched.provinciaNascita &&
                    formik.errors.provinciaNascita
                  }
                  error={
                    formik.touched.provinciaNascita &&
                    Boolean(formik.errors.provinciaNascita)
                  }
                  fullWidth
                >
                  {provincie &&
                    provincie.map((provincia) => (
                      <MenuItem value={provincia.nome} key={provincia.codice}>
                        {" "}
                        {provincia.nome}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  select
                  className="select"
                  label="Comune Nascita"
                  name="comuneNascita"
                  value={formik.values.comuneNascita}
                  onChange={formik.handleChange}
                  helperText={
                    formik.touched.comuneNascita && formik.errors.comuneNascita
                  }
                  error={
                    formik.touched.comuneNascita &&
                    Boolean(formik.errors.comuneNascita)
                  }
                  fullWidth
                >
                  {comuni &&
                    comuni.map((comune) => (
                      <MenuItem value={comune.nome} key={comune.codice}>
                        {" "}
                        {comune.nome}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
            </>
          )}
          {formik.values.nazioneNascita !== "Italia" && (
            <>
              <Grid item xs={12} md={12}>
                <TextField
                  type={"text"}
                  label="Comune Nascita Estero"
                  name="comuneNascitaEstero"
                  value={formik.values.comuneNascitaEstero}
                  onChange={formik.handleChange}
                  helperText={
                    formik.touched.comuneNascitaEstero &&
                    formik.errors.comuneNascitaEstero
                  }
                  error={
                    formik.touched.comuneNascitaEstero &&
                    Boolean(formik.errors.comuneNascitaEstero)
                  }
                  fullWidth
                ></TextField>
              </Grid>
            </>
          )}
        </Grid>

        {/*********************** CONTATTI ********************/}

        <div className="modal_title_presentatore">Contatti</div>
        <Grid container padding={3} spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              type={"email"}
              label="Pec"
              name="pec"
              value={formik.values.pec}
              onChange={formik.handleChange}
              helperText={formik.touched.pec && formik.errors.pec}
              error={formik.touched.pec && Boolean(formik.errors.pec)}
              fullWidth
            ></TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              type={"email"}
              label="Email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              helperText={formik.touched.email && formik.errors.email}
              error={formik.touched.email && Boolean(formik.errors.email)}
              fullWidth
            ></TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              type={"text"}
              label="Cellulare"
              name="cellulare"
              value={formik.values.cellulare}
              onChange={formik.handleChange}
              helperText={formik.touched.cellulare && formik.errors.cellulare}
              error={
                formik.touched.cellulare && Boolean(formik.errors.cellulare)
              }
              fullWidth
            ></TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              type={"text"}
              label="Telefono"
              name="telefono"
              value={formik.values.telefono}
              onChange={formik.handleChange}
              helperText={formik.touched.telefono && formik.errors.telefono}
              error={formik.touched.telefono && Boolean(formik.errors.telefono)}
              fullWidth
            ></TextField>
          </Grid>
        </Grid>

        {/*********************** INDIRIZZI DOMICILIO ********************/}

        <div className="modal_title_presentatore">Indirizzi</div>
        <Grid container padding={3} spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              type={"text"}
              label="Indirizzo Domicilio"
              name="indirizzoDomicilio"
              value={formik.values.indirizzoDomicilio}
              onChange={formik.handleChange}
              helperText={
                formik.touched.indirizzoDomicilio &&
                formik.errors.indirizzoDomicilio
              }
              error={
                formik.touched.indirizzoDomicilio &&
                Boolean(formik.errors.indirizzoDomicilio)
              }
              fullWidth
            ></TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              type={"text"}
              label="Civico Domicilio"
              name="civicoDomicilio"
              value={formik.values.civicoDomicilio}
              onChange={formik.handleChange}
              helperText={
                formik.touched.civicoDomicilio && formik.errors.civicoDomicilio
              }
              error={
                formik.touched.civicoDomicilio &&
                Boolean(formik.errors.civicoDomicilio)
              }
              fullWidth
            ></TextField>
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              select
              className="select"
              label="Nazione Domicilio"
              name="nazioneDomicilio"
              value={formik.values.nazioneDomicilio}
              onChange={formik.handleChange}
              helperText={
                formik.touched.nazioneDomicilio &&
                formik.errors.nazioneDomicilio
              }
              error={
                formik.touched.nazioneDomicilio &&
                Boolean(formik.errors.nazioneDomicilio)
              }
              fullWidth
            >
              {NAZIONI.map((nazione) => (
                <MenuItem value={nazione.name} key={nazione.id}>
                  {" "}
                  {nazione.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {formik.values.nazioneDomicilio === "Italia" ? (
            <>
              <Grid item xs={12} md={6}>
                <TextField
                  type={"text"}
                  label="Cap"
                  name="capZipCodeDomicilio"
                  value={formik.values.capZipCodeDomicilio}
                  onChange={formik.handleChange}
                  helperText={
                    formik.touched.capZipCodeDomicilio &&
                    formik.errors.capZipCodeDomicilio
                  }
                  error={
                    formik.touched.capZipCodeDomicilio &&
                    Boolean(formik.errors.capZipCodeDomicilio)
                  }
                  fullWidth
                ></TextField>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  type={"text"}
                  label="Regione Domicilio"
                  name="regioneDomicilio"
                  value={formik.values.regioneDomicilio}
                  onChange={formik.handleChange}
                  helperText={
                    formik.touched.regioneDomicilio &&
                    formik.errors.regioneDomicilio
                  }
                  error={
                    formik.touched.regioneDomicilio &&
                    Boolean(formik.errors.regioneDomicilio)
                  }
                  fullWidth
                ></TextField>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  type={"text"}
                  label="Comune Domicilio"
                  name="comuneDomicilio"
                  value={formik.values.comuneDomicilio}
                  onChange={formik.handleChange}
                  helperText={
                    formik.touched.comuneDomicilio &&
                    formik.errors.comuneDomicilio
                  }
                  error={
                    formik.touched.comuneDomicilio &&
                    Boolean(formik.errors.comuneDomicilio)
                  }
                  fullWidth
                ></TextField>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  type={"text"}
                  label="Provincia Domicilio"
                  name="provinciaDomicilio"
                  value={formik.values.provinciaDomicilio}
                  onChange={formik.handleChange}
                  helperText={
                    formik.touched.provinciaDomicilio &&
                    formik.errors.provinciaDomicilio
                  }
                  error={
                    formik.touched.provinciaDomicilio &&
                    Boolean(formik.errors.provinciaDomicilio)
                  }
                  fullWidth
                ></TextField>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12} md={6}>
                <TextField
                  type={"text"}
                  label="Comune Estero Domicilio"
                  name="comuneEsteroDomicilio"
                  value={formik.values.comuneEsteroDomicilio}
                  onChange={formik.handleChange}
                  fullWidth
                ></TextField>
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  type={"text"}
                  label="Cap"
                  name="capZipCodeEsteroDomicilio"
                  value={formik.values.capZipCodeEsteroDomicilio}
                  onChange={formik.handleChange}
                  fullWidth
                ></TextField>
              </Grid>
            </>
          )}
        </Grid>

        {/*********************** INDIRIZZI RESIDENZA ********************/}

        <div className="modal_title_presentatore">Residenza</div>
        <Grid container padding={3} spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              type={"text"}
              label="Indirizzo Residenza"
              name="indirizzoResidenza"
              value={formik.values.indirizzoResidenza}
              onChange={formik.handleChange}
              helperText={
                formik.touched.indirizzoResidenza &&
                formik.errors.indirizzoResidenza
              }
              error={
                formik.touched.indirizzoResidenza &&
                Boolean(formik.errors.indirizzoResidenza)
              }
              fullWidth
            ></TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              type={"text"}
              label="Civico Residenza"
              name="civicoResidenza"
              value={formik.values.civicoResidenza}
              onChange={formik.handleChange}
              helperText={
                formik.touched.civicoResidenza && formik.errors.civicoResidenza
              }
              error={
                formik.touched.civicoResidenza &&
                Boolean(formik.errors.civicoResidenza)
              }
              fullWidth
            ></TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              select
              className="select"
              label="Nazione Residenza"
              name="nazioneResidenza"
              value={formik.values.nazioneResidenza}
              onChange={formik.handleChange}
              helperText={
                formik.touched.nazioneResidenza &&
                formik.errors.nazioneResidenza
              }
              error={
                formik.touched.nazioneResidenza &&
                Boolean(formik.errors.nazioneResidenza)
              }
              fullWidth
            >
              {NAZIONI.map((nazione) => (
                <MenuItem value={nazione.name} key={nazione.id}>
                  {" "}
                  {nazione.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {formik.values.nazioneResidenza === "Italia" ? (
            <>
              <Grid item xs={12} md={6}>
                <TextField
                  type={"text"}
                  label="Cap"
                  name="capZipCodeResidenza"
                  value={formik.values.capZipCodeResidenza}
                  onChange={formik.handleChange}
                  fullWidth
                ></TextField>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  type={"text"}
                  label="Regione Residenza"
                  name="regioneResidenza"
                  value={formik.values.regioneResidenza}
                  onChange={formik.handleChange}
                  fullWidth
                ></TextField>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  type={"text"}
                  label="Comune Residenza"
                  name="comuneResidenza"
                  value={formik.values.comuneResidenza}
                  onChange={formik.handleChange}
                  fullWidth
                ></TextField>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  type={"text"}
                  label="Provincia Residenza"
                  name="provinciaResidenza"
                  value={formik.values.provinciaResidenza}
                  onChange={formik.handleChange}
                  helperText={
                    formik.touched.provinciaResidenza &&
                    formik.errors.provinciaResidenza
                  }
                  error={
                    formik.touched.provinciaResidenza &&
                    Boolean(formik.errors.provinciaResidenza)
                  }
                  fullWidth
                ></TextField>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12} md={6}>
                <TextField
                  type={"text"}
                  label="Comune Estero Residenza"
                  name="comuneEsteroResidenza"
                  value={formik.values.comuneEsteroResidenza}
                  onChange={formik.handleChange}
                  fullWidth
                ></TextField>
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  type={"text"}
                  label="Cap"
                  name="capZipCodeEsteroResidenza"
                  value={formik.values.capZipCodeEsteroResidenza}
                  onChange={formik.handleChange}
                  fullWidth
                ></TextField>
              </Grid>
            </>
          )}
        </Grid>

        <Box display={"flex"} gap={2} justifyContent={"flex-end"}>
          <Button color="neutral" variant="contained" onClick={prevPage}>
            {" "}
            Indietro{" "}
          </Button>
          <Button color="primary" type="submit" variant="contained">
            {" "}
            Prosegui{" "}
          </Button>
        </Box>
      </form>
    );
  }
);

export default DatiPresentatore;
