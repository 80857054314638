import React, { useState, useCallback } from "react";
import { memo } from "react";

//@mui
import AddBoxIcon from "@mui/icons-material/AddBox";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import {
  Button,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
} from "@mui/material";

//icons
import { AiOutlineMail } from "react-icons/ai";


//components
import ModalOfferteCartacee from "./ModalOfferteCartacee";
import ModalDatiAnagrafici from "../ModalDatiAnagrafici";

//hooks
import { useAsta } from "../../../../context/useAsta";

//utils
import { motion } from "framer-motion";
import { partecipantiHead } from "../../../../utils/SearchData";
import { convertDateInIta } from "../../../../utils/Utils";


const OfferteCartacee = memo(() => {

  const { buste, setBuste } = useAsta();

  
  const [isOpen, setOpen] = useState(false);
  const [modalOfferteCartacee, setModalOfferteCartacee] = useState(false);
  const offerteCartacee = buste.filter((busta) => !busta.isTelematica);

  //************** MODAL DATI ANAGRAFICI *************//
  const [modalDatiAnagrafici, setModalDatiAnagrafici] = useState({
    state: false,
    busta: null,
  });

  const closeModalDatiAnagrafici = useCallback(() => {
    setModalDatiAnagrafici({
      state: false,
      busta: null,
    });
  }, []);

  const openModalDatiAnagrafici = useCallback((busta) => {
    setModalDatiAnagrafici({
      state: true,
      busta: busta,
    });
  }, []);

  return (
    <React.Fragment>
      <div className="referente_dropdown_container">
        {!isOpen ? (
          <AddBoxIcon
            color="primary"
            style={{ cursor: "pointer" }}
            onClick={() => setOpen(true)}
          />
        ) : (
          <IndeterminateCheckBoxIcon
            color="primary"
            style={{ cursor: "pointer" }}
            onClick={() => setOpen(false)}
          />
        )}
        <div style={{ fontWeight: 500 }}>Offerte Cartacee Ricevute</div>
      </div>

      {isOpen && (
        <motion.div
          initial={{
            opacity: 0,
          }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          exit={{ opacity: 0 }}
        >
          <div className="flex_center_item">
            <Button
              variant="contained"
              onClick={() => setModalOfferteCartacee(true)}
            >
              {" "}
              Aggiungi Offerta Cartacea{" "}
            </Button>
          </div>

          <TableContainer>
            <Table sx={{ minWidth: 650 }} className="referente_table">
              <TableHead>
                <TableRow>
                  {partecipantiHead.map((cell, index) => {
                    return <TableCell key={index}>{cell.title}</TableCell>;
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {offerteCartacee.map((busta, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <div
                        onClick={() => {
                          openModalDatiAnagrafici(busta);
                        }}
                        className="text_title_presentatore"
                      >
                        {busta.offertaRequest.offIntegrale.presentatore
                          .datiAnagrafici.nome +
                          " " +
                          busta.offertaRequest.offIntegrale.presentatore
                            .datiAnagrafici.cognome +
                          ` (${busta.credenziali.username})`}
                      </div>
                    </TableCell>
                    <TableCell>
                      <AiOutlineMail
                        fontSize={25}
                      />
                    </TableCell>
                    <TableCell>
                      <div className="buste_container">
                        {busta.offertaRequest.offIntegrale
                          .tipoDepositoCauzione === "bonifico" ? (
                          <div className="busta_info_cauzione">
                            <div>
                              {
                                busta.offertaRequest.offIntegrale.offerta
                                  .importoCauzione
                              }{" "}
                              €
                            </div>
                            <div>
                              CRO:
                              {busta.offertaRequest.offIntegrale.offerta.cro}
                            </div>
                            <div>
                              {convertDateInIta(
                                busta.offertaRequest.offIntegrale.offerta
                                  .dataCro
                              )}
                            </div>
                          </div>
                        ) : busta.offertaRequest.offIntegrale
                            .tipoDepositoCauzione === "carta di credito" ? (
                          <div className="busta_info_cauzione">
                            <div>
                              {
                                busta.offertaRequest.offIntegrale.offerta
                                  .importoCauzione
                              }{" "}
                              €
                            </div>
                            <div>
                              {
                                busta.offertaRequest.offIntegrale.offerta
                                  .tipoCarta
                              }{" "}
                              €
                            </div>
                          </div>
                        ) : (
                          <div className="busta_info_cauzione">
                            <div>
                              {
                                busta.offertaRequest.offIntegrale.offerta
                                  .importoCauzione
                              }{" "}
                              €
                            </div>
                            <div>
                              {
                                busta.offertaRequest.offIntegrale.offerta
                                  .tipoDepositoCauzione
                              }{" "}
                              
                            </div>
                          </div>
                        )}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div style={{color:"green",fontWeight:600}}>
                                VALIDA
                      </div>
                    </TableCell>

                    <TableCell>
                      <div>
                        {
                          busta.offertaRequest.offIntegrale.offerta
                            .importoOfferta
                        }{" "}
                        €
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </motion.div>
      )}

      {modalDatiAnagrafici.state && (
        <ModalDatiAnagrafici
          open={modalDatiAnagrafici.state}
          busta={modalDatiAnagrafici.busta}
          onClose={closeModalDatiAnagrafici}
        />
      )}
 
      {modalOfferteCartacee && (
        <ModalOfferteCartacee
          open={modalOfferteCartacee}
          onClose={() => setModalOfferteCartacee(false)}
        />
      )}
    </React.Fragment>
  );
});

export default OfferteCartacee;
