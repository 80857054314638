/*
Il componente ModalOffriSincronaMista è una finestra modale utilizzata per inserire offerte in un contesto di asta sincrona mista. 
Permette all'utente (referente o banditore) di inserire un'offerta per un partecipante specifico, mostrando dettagli come il rilancio 
minimo e l'offerta minima accettata dal sistema. Include un campo di input per inserire il valore dell'offerta e pulsanti per confermare 
o annullare l'azione. La logica di validazione e invio dell'offerta verifica che l'importo sia adeguato e aggiorna le informazioni 
dell'asta, come la cronologia delle offerte.
*/


import React from "react";
import { memo, useState } from "react";

//@mui
import { Modal, Box, Typography, TextField, Button } from "@mui/material";

//hooks
import { useAsta } from "../../../context/useAsta";

//utils
import accounting from "accounting";
import NumberFormat from "react-number-format";
import moment from "moment";
import { getDateAstalex } from "../../../utils/Utils";
import { toast } from "react-toastify";
import { convertDateInIta } from "../../../utils/Utils";
import {
  updateLogOfferteReferenti,
  updateLogOffertePartecipante,
  updateRilanciAstaSincrona,
} from "../../../services/firebase";

const ModalOffriSincronaMista = memo(({ open, handleClose, busta }) => {
  const { asta, onTimeLeft } = useAsta();
  const [rilancio, setRilancio] = useState(
    Number(asta.rilancioMinimo) + Number(asta.miglioreOfferta)
  );

  const { presentatore } = busta.offertaRequest.offIntegrale;

  const rilancioHandlerSincrona = async () => {
    if (
      asta.datiVendita.modalitaVendita ===
        "SINCRONA MISTA" ||
      asta.datiVendita.modalitaVendita ===
        "SINCRONA TELEMATICA"
    ) {
      //Check se il controllo è scaduto
      if (onTimeLeft === 0)
        return toast.error("Attenzione il tempo è scaduto!");
      if (Number(asta.rilancioMinimo) + Number(asta.miglioreOfferta) > rilancio)
        return toast.error("Attenzione l'offerta è  troppo bassa");

      //Timer in ms
      const timerMs = asta.timer * 60000;
      const timeToAdd = timerMs - onTimeLeft;

      //Tempo Nuovo da aggiungere al Db.
      const newDate = moment(asta.dataFineVenditaRaw)
        .add(timeToAdd)
        .format("YYYY-MM-DDTHH:mm:ss");

      const response = await getDateAstalex();

      const newRilanci = [
        ...asta.rilanci,
        {
          rilancio: String(rilancio),
          offerente: busta.credenziali.username,
          dataOfferta: response.data.date,
        },
      ];

      await updateRilanciAstaSincrona(
        asta.idInserzioneEspVendita,
        rilancio,
        newRilanci,
        newDate,
        busta.credenziali.username
      );

      const stringaOffertaPartecipante = `L'offerente ${
        busta.credenziali.username
      } ha offerto ${accounting.formatMoney(
        rilancio,
        "€"
      )}  in data  ${convertDateInIta(response.data.date)}`;

      await updateLogOffertePartecipante(
        asta.idInserzioneEspVendita,
        stringaOffertaPartecipante
      );

      const stringaOffertaReferente = `L'offerente ${
        presentatore.datiAnagrafici.nome
      } ${presentatore.datiAnagrafici.cognome}(${
        busta.credenziali.username
      })  ha offerto ${accounting.formatMoney(
        rilancio,
        "€"
      )}  in data  ${convertDateInIta(response.data.date)}`;

      await updateLogOfferteReferenti(
        asta.idInserzioneEspVendita,
        stringaOffertaReferente
      );

      toast.success("Complimenti hai rilanciato per questa asta!");
    }
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box className="modal_offri_sincrona">
        <div className="modal_offri_sincrona_title ">{` ${presentatore.datiAnagrafici.nome} ${presentatore.datiAnagrafici.cognome} -  INSERIMENTO OFFERTA`}</div>

        <div>
          L'offerta minima accettata dal sistema è di:{" "}
          <Typography fontWeight={"bold"} display="inline">
            {" "}
            {accounting.formatMoney(
              Number(asta.rilancioMinimo) + Number(asta.miglioreOfferta),
              "€"
            )}
          </Typography>
        </div>

        <div>
          Rilancio Minimo:
          <Typography fontWeight={"bold"} display="inline">
            {" "}
            {accounting.formatMoney(Number(asta.rilancioMinimo), "€")}
          </Typography>
        </div>

        <Box marginTop={3} display={"flex"} alignItems="center">
          <NumberFormat
            value={rilancio}
            name={"rilancio"}
            customInput={TextField}
            type="text"
            prefix={"€"}
            thousandSeparator={true}
            label="Rilancio"
            onValueChange={({ value: v }) => setRilancio(v)}
          />
        </Box>
        <Box display={"flex"} alignItems="center" justifyContent={" flex-end"}>
          <Button variant="contained" onClick={rilancioHandlerSincrona}>
            Conferma Offerta
          </Button>
          <Button
            variant="contained"
            color="neutral"
            sx={{ marginLeft: "1rem" }}
            onClick={handleClose}
          >
            {" "}
            Annulla
          </Button>
        </Box>
      </Box>
    </Modal>
  );
});

export default ModalOffriSincronaMista;
