import React, { memo } from "react";

//@mui
import { TextField, Button } from "@mui/material";

//hooks
import { useFormik } from "formik";
import { useAuth } from "../../context/useAuth";

//utils
import "../../pages/Login/Login.css";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { authErrorHandler } from "../../utils/Errors";

const RecoverForm = memo(({ forgotPasswordHandler }) => {
  const { recoverPassHandler } = useAuth();

  const initialValues = {
    email: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string().required("Inserisci la tua email"),
  });

  const submitHandler = async (value) => {
    const response = await recoverPassHandler(value.email);
    if (!response.error) {
      toast.success("Email inviata con successo");
    } else {
      const { code } = response.message;
      console.log(response);
      toast.error(authErrorHandler(code));
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: submitHandler,
  });

  const forgotPassword = () => {
    forgotPasswordHandler();
  };

  return (
    <form className="login_form" onSubmit={formik.handleSubmit}>
      <div className="login_container">
        <div className="login_warning">Recupera Password</div>

        <TextField
          id="email"
          type="email"
          onChange={formik.handleChange}
          value={formik.values.email}
          label="Inserisci Email"
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          fullWidth
        />

        <Button type="submit" variant="contained" fullWidth>
          Conferma
        </Button>
        <p onClick={forgotPassword}>Entra nel portale</p>
      </div>
    </form>
  );
});

export default RecoverForm;
