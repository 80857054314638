import { LOAD_ASTE, CHANGE_GENERE,SEARCH_FORM } from "./actions";

export const reducerAste = (state, action) => {
  switch (action.type) {
    case LOAD_ASTE:
      return { ...state, aste: action.payload };
    case CHANGE_GENERE:
      return { ...state, genere: action.payload };
    case SEARCH_FORM:
      return {...state, ...action.payload}
    default:
      return state;
  }
};
