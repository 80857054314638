/*
Il componente IscrizioneVendita è progettato per permettere agli utenti di iscriversi a un'asta specifica. 
Quando l'asta è definita (disponibile nell'hook useAsta), il componente mostra un bottone che, quando cliccato, 
reindirizza l'utente al sito del Portale delle Vendite Pubbliche. 
Qui, l'utente può registrare la propria offerta per l'asta. 
L'URL di reindirizzamento incorpora l'ID dell'inserzione dell'asta, e il testo sotto il bottone spiega all'utente che cliccando 
sarà reindirizzato al sito per completare la procedura di iscrizione.
*/



import { Button } from "@mui/material";
import { useAsta } from "../../context/useAsta";
import React from "react";

const IscrizioneVendita = () => {
  const { asta } = useAsta();

  if (asta) {
    return (
      <div className="inscrizione_section">
        <div className="inscrizione_title">Iscrizione Vendita</div>
        <a
          target={"_blank"}
          //@todo inserire l'url in forma parametrica
          href={process.env.REACT_APP_PVP_BASE_ADDRESS + `${asta.idInserzioneEspVendita}?lang=it`}
          /*           href={`https://pvp.giustizia.it/pvp-offerta/i/${asta.inserzioneEspVendita.idInserzioneEspVendita}?lang=it`}
           */ rel=" noreferrer"
        >
          <Button size="medium" variant="contained" style={{ margin: "1rem" }}>
            ISCRIVITI ALLA VENDITA
          </Button>
        </a>

        <p>
          Cliccando su "ISCRIVITI ALLA VENDITA" sarai reindirizzato sul sito del
          Portale delle Vendite Pubbliche dove potrai registrare la tua offerta.
        </p>
      </div>
    );
  }

  return null;
};

export default IscrizioneVendita;
