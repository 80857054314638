import React from "react";

//components
import MultiCarousel from "../../components/Homepage/MultiCarousel";
import FilterRicercaGenere from "../../components/Homepage/FilterRicercaGenere";
import FormRicercaInserzione from "../../components/Homepage/FormRicercaInserzione";

//hooks
import { useAste } from "../../context/useAste";

//utils
import moment from "moment";
import { Helmet } from "react-helmet-async";

// function stampaArray(array) {
//     array.forEach(( item ) => {
//       console.log(' ---> ITEM ' + JSON.stringify(item))
//     })

// }


const Homepage = () => {
  
  
  const { asteState } = useAste();

  // console.log('UOLLOC' + JSON.stringify(asteState))

  // console.log(typeof(asteState.aste))
  
  // stampaArray(asteState.aste)

  

  const keysLotto = ["genere", "categoria"];
  const keysInserzione = ["tipologiaInserzione"];
  const keysProcedura = ["tribunale"];
  const keysDatiVendita = ["tipologiaVendita", "modalitaVendita"];

  let filterAsteLotto = asteState.aste.filter(
    (asta) =>
      keysLotto.every((key) =>
        asteState[key] === ""
          ? true
          : asta.lotto[key].includes(asteState[key])
      ) &&
      keysInserzione.every((key) =>
        asteState[key] === ""
          ? true
          : asta[key].includes(asteState[key])
      ) &&
      keysProcedura.every((key) =>
        asteState[key] === ""
          ? true
          : asta.datiProcedura.proceduraGiudiziaria[
              key
            ].includes(asteState[key])
      ) &&
      keysDatiVendita.every((key) =>
        asteState[key] === ""
          ? true
          : asta.datiVendita[key].includes(asteState[key])
      )
  );

  if (asteState.dataInizioVendita && asteState.dataFineVendita) {
    filterAsteLotto = filterAsteLotto.filter((asta) =>
      moment(asta.datiVendita.dataOraVendita).isBetween(
        asteState.dataInizioVendita,
        asteState.dataFineAsta
      )
    );
  }

  const chunkSize = 10;
  let filterAsteFinale = [];
  for (let i = 0; i < filterAsteLotto.length; i += chunkSize) {
    const chunk = filterAsteLotto.slice(i, i + chunkSize);
    filterAsteFinale.push(chunk);
  }

  return (
    <div className="container">
      <Helmet>
        <title> ASTALEX | Vendite giudiziarie immobiliari</title>
        <meta
          name="description"
          content="Acquista su ASTALEX il portale per beni derivanti da vendite giudiziarie e aste fallimentari. 
          Migliaia di annunci, appartamenti, uffici, garage e terreni. Trova l'immobile che cerchi e aggiudicatelo!"
        />
        <link rel="canonical" href="/" />
      </Helmet>

      <FilterRicercaGenere />
      <FormRicercaInserzione />
      <div className="title_inserzioni">
        <h2>{asteState.genere}</h2>
      </div>

      {filterAsteFinale.map((aste,index) => (
        <MultiCarousel inserzioni={aste} key={index} />
      ))}
    </div>
  );
};

export default Homepage;
