import moment from "moment";
import axios from "axios";
import atob from "atob"
//STATE
export const STATI_ASTA = {
  AVVISO: "AVVISO",
  ISCRIZIONI_IN_CORSO: "ISCRIZIONI_IN_CORSO",
  IN_ATTESA_DI_AVVIO: "IN_ATTESA_DI_AVVIO",
  IN_CORSO: "IN_CORSO",
  SOSPESA: " SOSPESA",
  IN_PAUSA: "IN_PAUSA",
  CANCELLATA: "CANCELLATA",
  DESERTA: "DESERTA",
  TERMINATA_NO_INVIO: "TERMINATA_NO_INVIO",
  TERMINATA_OK_INVIO: "TERMINATA_OK_INVIO",
};

//FORMATTER
export const formatter = new Intl.NumberFormat("it-IT", {
  style: "currency",
  currency: "EUR",
});

export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

//MOMENT
export const convertDateInIta = (date) => {
  moment.locale("it");
  const dateItaliano = moment(date).format("LLL");
  return dateItaliano;
};

//AXIOS
export const getDateAstalex = () =>
  new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_DATA_SERVICE_URL}/getDate`, {
        params: {
          password: process.env.REACT_APP_DATA_SERVICE_PASSWORD
        },
      })
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  );

export const getPdf = (id) =>
  new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_DATA_SERVICE_URL}/getPdf`, {
        id: id,
        password: process.env.REACT_APP_DATA_SERVICE_PASSWORD,
      })
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  );

  
export const convertBase64ToBlob=(b64Data)=>{
  const byteCharacters = atob(b64Data);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], {type: "application/pdf"});

  return blob

}
