import React from "react";
import { useState, useCallback } from "react";

//@mui
import { Modal, Box, Grid, Button } from "@mui/material";

//components
import CustomModal from "../../../../utils/CustomModal/CustomModal";

//hooks
import { useAsta } from "../../../../context/useAsta";

//utils
import { convertDateInIta } from "../../../../utils/Utils";
import { memo } from "react";
import { terminaAstaUnicoOfferente } from "../../../../services/firebase";
import { toast } from "react-toastify";

const ModalUnicaOfferta = memo(({ isOpen, handleClose }) => {
  const { buste } = useAsta();

  const [modalConferma, setModalConferma] = useState(false);
  const [modalNonAggiudicare, setModalNonAggiudicare] = useState(false);

  const busta = buste[0];

  const { presentatore } = busta.offertaRequest.offIntegrale;
  const { offerta } = busta.offertaRequest.offIntegrale;

  const closeModalDatConferma = useCallback(() => {
    setModalConferma(false);
  }, []);

  const closeModalNonAggiudicare = useCallback(() => {
    setModalNonAggiudicare(false);
  }, []);

  const confermaAggiudicazioneHandler = async () => {
    try {

      await terminaAstaUnicoOfferente(
        busta.idInserzioneEspVendita,
        busta.offertaRequest.offIntegrale.offerta.importoOfferta,
        busta.credenziali.username
      );

      handleClose()
      toast.success("L'asta è stata aggiudicata!");
    
    } catch (error) {
      console.log(error);
    }
  };



  const nonConfermaAggiudicazioneHandler = () => {};

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box className="modal_unica_offerta">
        <div className="modal_unica_offerta_title">TERMINA GARA</div>

        <Box>
          <div>UNICO ISCRITTO:</div>
        </Box>

        <Grid container spacing={2} marginY={1}>
          <Grid item md={6} xs={12}>
            Presentatore:
          </Grid>
          <Grid item md={6} xs={12} fontWeight={600}>
            {`${presentatore.datiAnagrafici.nome} ${presentatore.datiAnagrafici.cognome} (${presentatore.datiAnagrafici.codiceFiscale})`}
          </Grid>
          <Grid item md={3} xs={12}>
            Offerta:
          </Grid>
          <Grid item md={3} xs={12} fontWeight={600}>
            {offerta.importoOfferta}€
          </Grid>
          <Grid item md={3} xs={12}>
            Cauzione:
          </Grid>
          <Grid item md={3} xs={12} fontWeight={600}>
            {offerta.importoCauzione}€
          </Grid>

          <Grid item md={3} xs={12}>
            Termine Saldo Pagamento:
          </Grid>
          <Grid item md={3} xs={12} fontWeight={600}>
            {convertDateInIta(offerta.termineDiPagamento)}
          </Grid>

          <Grid item md={3} xs={12}>
            Tipo Deposito Cauzione:
          </Grid>
          <Grid item md={3} xs={12} fontWeight={600}>
            {offerta.tipoDepositoCauzione}
          </Grid>
        </Grid>

        <div className="modal_unica_offerta_buttons">
          <Button variant="contained" onClick={() => setModalConferma(true)}>
            {" "}
            Conferma Aggiudicazione{" "}
          </Button>
          <Button
            variant="contained"
            onClick={() => setModalNonAggiudicare(true)}
          >
            {" "}
            Non Aggiudicare
          </Button>
        </div>

        {modalConferma && (
          <CustomModal
            title={"Sei sicuro di voler confermare l'aggiudicazione?"}
            content="Attenzione, una volta confermato non potrai più annullare l'operazione."
            openModal={modalConferma}
            handleClose={closeModalDatConferma}
            submitModal={confermaAggiudicazioneHandler}
            color={"green"}
          />
        )}

        {modalNonAggiudicare && (
          <CustomModal
            title={"Sei sicuro di voler non confermare l'aggiudicazione"}
            content="Attenzione, una volta confermato non potrai più annullare l'operazione."
            openModal={modalNonAggiudicare}
            handleClose={closeModalNonAggiudicare}
            submitModal={nonConfermaAggiudicazioneHandler}
            color={"#ff9800"}
          />
        )}
      </Box>
    </Modal>
  );
});

export default ModalUnicaOfferta;
