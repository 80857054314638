import React, { useState } from "react";

//components
import LoginForm from "../../components/Login/LoginForm";
import RecoverForm from "../../components/Login/RecoverForm";

//utils
import { Helmet } from "react-helmet-async";
import "./Login.css";

const Login = () => {
  const [forgotPassword, setForgotPassword] = useState(false);

  const forgotPasswordHandler = () => {
    setForgotPassword(!forgotPassword);
  };

  return (
    <div className="container">
      <Helmet>
        <title> ASTALEX | Login</title>
        <meta
          name="description"
          content="Entra in Astalex.it per partecipare ad un'asta."
        />
        <link rel="canonical" href="/login" />
      </Helmet>

      {!forgotPassword ? (
        <LoginForm forgotPasswordHandler={forgotPasswordHandler} />
      ) : (
        <RecoverForm forgotPasswordHandler={forgotPasswordHandler} />
      )}
    </div>
  );
};

export default Login;
