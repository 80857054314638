export const categoriaLottoData = [
  "IMMOBILE RESIDENZIALE",
  "IMMOBILE COMMERCIALE",
  "IMMOBILE INDUSTRIALE",
  "IMPIANTO SPORTIVO",
  "AUTOVEICOLI E CICLI",
  "NAUTICA",
  "MACCHINARI-UTENSILI-MATERIE PRIME",
  "INFORMATICA E ELETTRONICA",
  "ARREDAMENTO - ELETTRODOMESTICI",
  "ARTE -OREFICERIA - OROLOGERIA- ANTIQUARIATO",
  "ABBIGLIAMENTO E CALZATURE",
  "AZIONI-TITOLI",
  "CREDITI",
  "ALTRA CATEGORIA",
  "QUOTA SOCIETARIA",
  "CESSIONE-AFFITTO D'AZIENDA",
  "MARCHI",
  "BREVETTI",
];

export const tribunaliData = [
  "Tribunale di ALESSANDRIA",
  "Tribunale di ALESSANDRIA ex Tribunale di ACQUI TERME",
  "Tribunale di ALESSANDRIA ex Tribunale di TORTONA",
  "Tribunale di ANCONA",
  "Tribunale di AOSTA",
  "Tribunale di AREZZO",
  "Tribunale di ASCOLI PICENO",
  "Tribunale di ASTI",
  "Tribunale di ASTI ex Tribunale di ALBA",
  "Tribunale di AVELLINO",
  "Tribunale di AGRIGENTO",
  "Tribunale di AVELLINO ex Tribunale di SANT'ANGELO DEI LOMBARDI",
  "Tribunale di AVEZZANO",
  "Tribunale di BARCELLONA POZZO DI GOTTO",
  "Tribunale di BARI",
  "Tribunale di BELLUNO",
  "Tribunale di BENEVENTO",
  "Tribunale di BENEVENTO ex Tribunale di ARIANO IRPINO",
  "Tribunale di BERGAMO",
  "Tribunale di BIELLA",
  "Tribunale di BOLOGNA",
  "Tribunale di BOLZANO",
  "Tribunale di BRESCIA",
  "Tribunale di BRINDISI",
  "Tribunale di BUSTO ARSIZIO",
  "Tribunale di CAGLIARI",
  "Tribunale di CALTAGIRONE",
  "Tribunale di CALTANISSETTA",
  "Tribunale di CAMPOBASSO",
  "Tribunale di CASSINO",
  "Tribunale di CASTROVILLARI",
  "Tribunale di CASTROVILLARI ex Tribunale di ROSSANO",
  "Tribunale di CATANIA",
  "Tribunale di CATANZARO",
  "Tribunale di CHIETI",
  "Tribunale di CIVITAVECCHIA",
  "Tribunale di COMO",
  "Tribunale di COSENZA",
  "Tribunale di CREMONA",
  "Tribunale di CREMONA ex Tribunale di CREMA",
  "Tribunale di CROTONE",
  "Tribunale di CUNEO",
  "Tribunale di CUNEO ex Tribunale di MONDOVI",
  "Tribunale di CUNEO ex Tribunale di SALUZZO",
  "Tribunale di ENNA",
  "Tribunale di ENNA ex Tribunale di NICOSIA",
  "Tribunale di FERMO",
  "Tribunale di FERRARA",
  "Tribunale di FIRENZE",
  "Tribunale di FOGGIA",
  "Tribunale di FOGGIA ex Tribunale di LUCERA",
  "Tribunale di FORLI'",
  "Tribunale di FROSINONE",
  "Tribunale di GELA",
  "Tribunale di GENOVA",
  "Tribunale di GENOVA ex Tribunale di CHIAVARI",
  "Tribunale di GORIZIA",
  "Tribunale di GROSSETO",
  "Tribunale di IMPERIA",
  "Tribunale di IMPERIA ex Tribunale di SAN REMO",
  "Tribunale di ISERNIA",
  "Tribunale di IVREA",
  "Tribunale di LA SPEZIA",
  "Tribunale di LAGONEGRO",
  "Tribunale di LAGONEGRO ex Tribunale di SALA CONSILINA",
  "Tribunale di LAMEZIA TERME",
  "Tribunale di LANCIANO",
  "Tribunale di LANUSEI",
  "Tribunale di L'AQUILA",
  "Tribunale di LARINO",
  "Tribunale di LATINA",
  "Tribunale di LECCE",
  "Tribunale di LECCO",
  "Tribunale di LIVORNO",
  "Tribunale di LOCRI",
  "Tribunale di LODI",
  "Tribunale di LUCCA",
  "Tribunale di MACERATA",
  "Tribunale di MACERATA ex Tribunale di CAMERINO",
  "Tribunale di MANTOVA",
  "Tribunale di MARSALA",
  "Tribunale di MASSA",
  "Tribunale di MATERA",
  "Tribunale di MESSINA",
  "Tribunale di MILANO",
  "Tribunale di MODENA",
  "Tribunale di MONZA",
  "Tribunale di NAPOLI",
  "Tribunale di Napoli nord in Aversa",
  "Tribunale di NOCERA INFERIORE",
  "Tribunale di NOLA",
  "Tribunale di NOVARA",
  "Tribunale di NUORO",
  "Tribunale di ORISTANO",
  "Tribunale di PADOVA",
  "Tribunale di PALERMO",
  "Tribunale di PALMI",
  "Tribunale di PAOLA",
  "Tribunale di PARMA",
  "Tribunale di PATTI",
  "Tribunale di PATTI ex Tribunale di MISTRETTA",
  "Tribunale di PAVIA",
  "Tribunale di PAVIA ex Tribunale di VIGEVANO",
  "Tribunale di PAVIA ex Tribunale di VOGHERA",
  "Tribunale di PERUGIA",
  "Tribunale di PESARO",
  "Tribunale di PESCARA",
  "Tribunale di PIACENZA",
  "Tribunale di PISA",
  "Tribunale di PISTOIA",
  "Tribunale di PORDENONE",
  "Tribunale di POTENZA",
  "Tribunale di POTENZA ex Tribunale di MELFI",
  "Tribunale di PRATO",
  "Tribunale di RAGUSA",
  "Tribunale di RAGUSA ex Tribunale di MODICA",
  "Tribunale di RAVENNA",
  "Tribunale di REGGIO CALABRIA",
  "Tribunale di REGGIO EMILIA",
  "Tribunale di RIETI",
  "Tribunale di RIMINI",
  "Tribunale di ROMA",
  "Tribunale di ROVERETO",
  "Tribunale di ROVIGO",
  "Tribunale di SALERNO",
  "Tribunale di SANTA MARIA CAPUA VETERE",
  "Tribunale di SASSARI",
  "Tribunale di SAVONA",
  "Tribunale di SCIACCA",
  "Tribunale di SIENA",
  "Tribunale di SIENA ex Tribunale di MONTEPULCIANO",
  "Tribunale di SIRACUSA",
  "Tribunale di SONDRIO",
  "Tribunale di SPOLETO",
  "Tribunale di SULMONA",
  "Tribunale di TARANTO",
  "Tribunale di TEMPIO PAUSANIA",
  "Tribunale di TERAMO",
  "Tribunale di TERMINI IMERESE",
  "Tribunale di TERNI",
  "Tribunale di TERNI ex Tribunale di ORVIETO",
  "Tribunale di TIVOLI",
  "Tribunale di TORINO",
  "Tribunale di TORINO ex Tribunale di PINEROLO",
  "Tribunale di TORRE ANNUNZIATA",
  "Tribunale di TRANI",
  "Tribunale di TRAPANI",
  "Tribunale di TRENTO",
  "Tribunale di TREVISO",
  "Tribunale di TRIESTE",
  "Tribunale di UDINE",
  "Tribunale di UDINE ex Tribunale di TOLMEZZO",
  "Tribunale di URBINO",
  "Tribunale di VALLO DELLA LUCANIA",
  "Tribunale di VARESE",
  "Tribunale di VASTO",
  "Tribunale di VELLETRI",
  "Tribunale di VENEZIA",
  "Tribunale di VERBANIA",
  "Tribunale di VERCELLI",
  "Tribunale di VERCELLI ex Tribunale di CASALE MONTEFERRATO",
  "Tribunale di VERONA",
  "Tribunale di VIBO VALENTIA",
  "Tribunale di VICENZA",
  "Tribunale di VICENZA ex Tribunale di BASSANO DEL GRAPPA",
  "Tribunale di VITERBO",
];

export const tipologiaVenditaData = [
  "SENZA INCANTO",
  "CON INCANTO",
  "SENZA INCANTO - SEGUITO AUMENTO DI UN QUINTO",
  "COMPETITIVA",
  "TRAMITE COMMISSIONARIO",
];


export const modalitaVenditaData =[
"PRESSO IL VENDITORE",
"SINCRONA TELEMATICA",
"SINCRONA MISTA",
"ASINCRONA TELEMATICA",
]




export const partecipantiHead =[
  {
    title:"Presentatore",
    field:"presentatore"
  },
  {
    title:"Busta",
    field:""
  },
  {
    title:"Cauzione",
    field:"cauzione"
  },
  {
    title:"Stato Cauzione/Offerta",
    field:"stato cauzione/offerta"
  },
  {
    title:"Offerta",
    field:"offerta"
  },

  
]


export const tableDocumentiAnonimiHead =[
  {
    title:"Pseudonimo",
    field:"pseudonimo"
  },
  {
    title:"Documento Anonimo",
    field:"documentoAnonimo"
  },
]