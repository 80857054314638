import React, { memo } from "react";
//components
// import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
// import Geocode from "react-geocode";

//hooks
import { useAsta } from "../../context/useAsta";

//utils
import "../../pages/Asta/Asta.css"

// Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_APY);
// Geocode.setLanguage("en");

/* const containerStyle = {
  height: "300px",
  width: "100%",
  borderRadius: "1rem",
};


const Ubicazione = memo(() => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_APY,
  });
  const { asta } = useAsta();
  const {lotto} = asta.inserzioneEspVendita
  const { ubicazione } = lotto;

  const [map, setMap] = React.useState(null);
  const [center, setCenter] = React.useState({
    lat: 0,
    lng: 0,
  });

  const onLoad = React.useCallback(
    function callback(map) {
      Geocode.fromAddress(
        `${ubicazione.indirizzo}, ${ubicazione.civicoEsponente} - ${ubicazione.capZipCode} ${ubicazione.citta}`
      ).then((response) => {
        const { lat, lng } = response.results[0].geometry.location;

        const centerData = {
          lat: lat,
          lng: lng,
        };
        setCenter(centerData);
        const bounds = new window.google.maps.LatLngBounds(centerData);
        map.fitBounds(bounds);
        setMap(map);
      });
    },
    [
      ubicazione.indirizzo,
      ubicazione.citta,
      ubicazione.civicoEsponente,
      ubicazione.capZipCode,
    ]
  );

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return isLoaded ? (
    <div className="ubicazione_container"> 
      <div className="ubicazione_title">Ubicazione</div>
      <div style={{margin:"0.2rem 0"}}>
        {`${ubicazione?.indirizzo}  ${ubicazione?.citta}`}
      </div>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}

        
        zoom={10}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        <></>
      </GoogleMap>
    </div>
  ) : (
    <></>
  );
}); */

//@todo inserire mappa - da erroe di abilitazione account fatturazione

const Mock = () => {
  const { asta } = useAsta();
  const {lotto} = asta
  const { ubicazione } = lotto;

  return (
    <>
      {JSON.stringify(ubicazione)}
    </>
  )

}


export default Mock;
