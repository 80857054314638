/*
Il componente GaraPanelReferente è progettato per il pannello del referente di un'asta. 
Offre la funzionalità di visualizzare le offerte attive, con la possibilità di estromettere partecipanti e 
gestire offerte per aste miste. 
Include una tabella di offerte valide, con opzioni per estromettere partecipanti e per offrire in modalità sincrona mista. 
Integra anche la cronologia delle offerte e un conto alla rovescia per monitorare il tempo rimanente dell'asta. 
Il componente utilizza modali personalizzati per confermare azioni come l'estromissione di un partecipante.
*/



import React from "react";
import { useState, useCallback } from "react";

//@mui
import {
  Grid,
  TableBody,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  Table,
  Box,
  Button,
} from "@mui/material";

//components
import CountDown from "../../../utils/CountDown";
import CronologiaOfferteReferente from "./CronologiaOfferteReferente";
import ModalOffriSincronaMista from "./ModalOffriSincronaMista";
import CustomModal from "../../../utils/CustomModal/CustomModal";

//hooks
import { useAsta } from "../../../context/useAsta";

//utils
import "./ReferentePanel.css";
import accounting from "accounting";
import { convertDateInIta } from "../../../utils/Utils";
import { toast } from "react-toastify";
import { updateEstromettiPartecipante } from "../../../services/firebase";

const GaraPanelReferente = () => {

  const { asta, buste, setBuste } = useAsta();
  
  const [modalOffriSincronaMista, setModalOffriSincronaMista] = useState({
    state: false,
    busta: null,
  });
  const [modalEstrometti, setModalEstrometti] = useState({
    state: false,
    busta: null,
  });





  const validBuste =buste && buste.filter((busta) => busta.cauzioneVersata && busta.offertaValida);


  const estromettiHandler = async () => {
    try {
      await updateEstromettiPartecipante(modalEstrometti.busta.idRef);
      closeModalEstrometti();
      const newBuste = buste.filter(
        (busta) => busta.idRef !== modalEstrometti.busta.idRef
      );
      setBuste(newBuste);
      toast.success("L'utente è stato estromesso");
    } catch (error) {
      console.log(error);
      toast.error("Si è verificato un errore");
    }
  };

  const closeModalEstrometti = useCallback(() => {
    setModalEstrometti({
      state: false,
      busta: null,
    });
  }, []);

  const closeModalOffriSincronaMista = useCallback(() => {
    setModalOffriSincronaMista({
      state: false,
      busta: null,
    });
  }, []);



  return (
    <div className="gara_referente_container">
      <div className="gara_referente_title"> GARA IN CORSO</div>

      <div className="gara_box_info">
        <Grid container>
          <Grid item padding={2} md={6} xs={12}>
            <div>
              INIZIO:{" "}
              <div
                style={{
                  fontWeight: 500,
                  display: "inline",
                  color: "rgba(0, 0, 0, 0.87)",
                }}
              >
                {asta.datiVendita.modalitaVendita ===
                "ASINCRONA TELEMATICA"
                  ? convertDateInIta(asta.dataInizioVendita)
                  : convertDateInIta(asta.dataFineVendita)}
              </div>
            </div>
          </Grid>
          <Grid item padding={2} md={6} xs={12}>
            <div>
              FINE:{" "}
              <div style={{ fontWeight: 500, display: "inline" }}>
                {" "}
                {convertDateInIta(asta.dataFineVenditaRaw)}
              </div>
            </div>
          </Grid>
          <Grid item padding={2} md={6} xs={12}>
            <div>
              TRIBUNALE:{" "}
              <div style={{ fontWeight: 500, display: "inline" }}>
                {
                  asta.datiProcedura.proceduraGiudiziaria
                    .tribunale
                }
              </div>
            </div>
          </Grid>
          <Grid item padding={2} md={6} xs={12}>
            <div>
              RITO:{" "}
              <div style={{ fontWeight: 500, display: "inline" }}>
                {
                  asta.datiProcedura.proceduraGiudiziaria
                    .rito
                }
              </div>
            </div>
          </Grid>
        </Grid>
      </div>

      <div className="gara_box_offerte">
        <div className="gara_box_offerte_title"> OFFERENTI</div>
        <TableContainer className="referente_table_container active">
          <Table sx={{ minWidth: 650 }} className="referente_table">
            <TableHead>
              <TableRow>
                <TableCell> Presentatore</TableCell>
                <TableCell> Pseudonimo</TableCell>
                <TableCell> Estrometti</TableCell>
                <TableCell> Tipo Busta</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {validBuste.map((busta, index) => (
                <TableRow key={index}>
                  <TableCell>
                    {`${busta.offertaRequest.offIntegrale.presentatore.datiAnagrafici.nome} ${busta.offertaRequest.offIntegrale.presentatore.datiAnagrafici.cognome}`}
                  </TableCell>
                  <TableCell>{busta.credenziali.username}</TableCell>
                  <TableCell>
                    {
                      <Button
                        color="error"
                        variant="contained"
                        onClick={() =>
                          setModalEstrometti({
                            state: true,
                            busta: busta,
                          })
                        }
                      >
                        {" "}
                        Estrometti{" "}
                      </Button>
                    }
                  </TableCell>
                  <TableCell>
                    {busta.isTelematica ? (
                      <Box sx={{ fontWeight: 600 }}>Telematica</Box>
                    ) : (
                      <Box
                        sx={{ fontWeight: 600 }}
                        display={"flex"}
                        alignItems="center"
                      >
                        <div>Cartacea</div>
                        <Button
                          variant="contained"
                          sx={{ marginLeft: "1rem" }}
                          onClick={() =>
                            setModalOffriSincronaMista({
                              state: true,
                              busta: busta,
                            })
                          }
                        >
                          {" "}
                          Offri
                        </Button>
                      </Box>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Grid container>
        {/************  INFO OFFERTE  **********/}
        <Grid item md={6} xs={12}>
          <div className="gara_text_info">
            Tempo rimanente:{" "}
            <div style={{ fontWeight: 600, display: "inline" }}>
              <CountDown />
            </div>
          </div>

          <div className="gara_text_info">
            {" "}
            Prezzo corrente:{" "}
            <div style={{ fontWeight: 600, display: "inline" }}>
              {" "}
              {accounting.formatMoney(asta.miglioreOfferta, "€")}
            </div>
          </div>
          <div className="gara_text_info">
            {" "}
            Rialzo minimo :{" "}
            <div style={{ fontWeight: 600, display: "inline" }}>
              {" "}
              {accounting.formatMoney(asta.rilancioMinimo, "€")}
            </div>
          </div>

          <div className="gara_text_info">
            {" "}
            Importo minimo :{" "}
            <div style={{ fontWeight: 600, display: "inline" }}>
              {accounting.formatMoney(
                Number(asta.rilancioMinimo) + Number(asta.miglioreOfferta),
                "€"
              )}
            </div>
          </div>
          <div className="gara_text_info">
            {" "}
            Miglior Offerente :{" "}
            <div style={{ fontWeight: 600, display: "inline" }}>
              {asta.miglioreOfferente}
            </div>
          </div>
          {/*      {onTimeLeft > 0 ? (
                <>
                  <NumberFormat
                    value={rilancio}
                    name={"rilancio"}
                    customInput={TextField}
                    type="text"
                    prefix={"€"}
                    thousandSeparator={true}
                    label="Rilancio"
                    onValueChange={({ value: v }) => setRilancio(v)}
                  />
                  <Button type="submit" variant="contained">
                    Offri
                  </Button>
                </>
              ) : (
                <div style={{ color: "red" }}>
                  Attenzione il tempo è scaduto!
                </div>
              )} */}
        </Grid>


        {/************  CRONOLOGIA OFFERTE  *************/}
        <Grid item md={6} xs={12}>
          <CronologiaOfferteReferente />
        </Grid>
      </Grid>


      
        
      {modalOffriSincronaMista.state && (
        <ModalOffriSincronaMista
          handleClose={closeModalOffriSincronaMista}
          open={modalOffriSincronaMista.state}
          busta={modalOffriSincronaMista.busta}
        />
      )}

      {modalEstrometti.state && (
        <CustomModal
          title={`Sei sicuro di voler estromettere ${modalEstrometti.busta.offertaRequest.offIntegrale.presentatore.datiAnagrafici.nome} ${modalEstrometti.busta.offertaRequest.offIntegrale.presentatore.datiAnagrafici.cognome} `}
          content="Attenzione, una volta confermato non potrai più annullare l'operazione."
          openModal={modalEstrometti.state}
          handleClose={closeModalEstrometti}
          submitModal={estromettiHandler}
          color={"green"}
        />
      )}
    </div>
  );
};

export default GaraPanelReferente;
