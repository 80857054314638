import React from "react";
import { useAsta } from "../../context/useAsta";
import { convertDateInIta } from "../../utils/Utils";

//@mui
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

const DatiPvp = () => {
  const { asta } = useAsta();

  // console.log ('ASTA da INFOASTA.jsx: ' + JSON.stringify(asta))


  if (asta && 
    asta.idInserzioneEspVendita && 
    asta.datiProcedura &&
    asta.datiVendita

    ) {
    const { inserzioneEspVendita } = asta;

    return (
      <React.Fragment>
        <div className="datiPvp_title">
          DATI RICEVUTI DAL PORTALE DELLE VENDITE PUBBLICHE
        </div>
        <div className="datiPvp_top_section">
          <div className="datiPvp_section datiPvp_grey">
            <div style={{ fontWeight: 600 }}>
              Link inserzione ministeriale:{" "}
            </div>
            <div style={{ marginLeft: "2rem" }}>n.d.</div>
          </div>

          <div className="datiPvp_section">
            <div style={{ fontWeight: 600 }}>ID inserzione: </div>
            <div style={{ marginLeft: "2rem" }}>
              {asta.idInserzioneEspVendita}
            </div>
          </div>
          <div className="datiPvp_section datiPvp_grey">
            <div style={{ fontWeight: 600 }}>ID Messaggio: </div>
            <div style={{ marginLeft: "2rem" }}>
              {asta.messageId}
            </div>
          </div>
          <div className="datiPvp_section">
            <div style={{ fontWeight: 600 }}>Tipo inserzione: </div>
            <div style={{ marginLeft: "2rem" }}>
              {asta.tipologiaInserzione}
            </div>
          </div>
          <div
            className="datiPvp_section datiPvp_grey"
            style={{ marginBottom: "1rem" }}
          >
            <div style={{ fontWeight: 600 }}>Data pubblicazione: </div>
            <div style={{ marginLeft: "2rem" }}>
              {convertDateInIta(asta.dataPubblicazione)}
            </div>
          </div>

          {/************** DATI PROCEDURA ************/}
          <Accordion className="datiPvp_accordition">
            <AccordionSummary expandIcon={<ExpandMore />}>
              <div style={{ fontWeight: 600 }}>DATI PROCEDURA</div>
            </AccordionSummary>
            <AccordionDetails>
              <div className="datiPvp_section datiPvp_grey">
                <div style={{ fontWeight: 600 }}>ID procedura:</div>
                <div style={{ marginLeft: "2rem" }}>
                  {asta.datiProcedura.idProcedura}
                </div>
              </div>
              <div className="datiPvp_section ">
                <div style={{ fontWeight: 600 }}>Tipo Procedura:</div>
                <div style={{ marginLeft: "2rem" }}>
                  {asta.datiProcedura?.tipoProcedura}
                </div>
              </div>
              <div className="datiPvp_section  datiPvp_grey">
                <div style={{ fontWeight: 600 }}>Altra Vendita:</div>
                <div style={{ marginLeft: "2rem" }}>
                  {asta.datiProcedura?.altraVendita}
                </div>
              </div>
              <div className="datiPvp_section ">
                <div style={{ fontWeight: 600 }}>ID Tribunale:</div>
                <div style={{ marginLeft: "2rem" }}>
                  {
                    asta.datiProcedura.proceduraGiudiziaria
                      .idTribunale
                  }
                </div>
              </div>
              <div className="datiPvp_section  datiPvp_grey">
                <div style={{ fontWeight: 600 }}>Tribunale:</div>
                <div style={{ marginLeft: "2rem" }}>
                  {
                    asta.datiProcedura.proceduraGiudiziaria
                      .tribunale
                  }
                </div>
              </div>
              <div className="datiPvp_section ">
                <div style={{ fontWeight: 600 }}>ID Registro:</div>
                <div style={{ marginLeft: "2rem" }}>
                  {
                    asta.datiProcedura.proceduraGiudiziaria
                      .idRegistro
                  }
                </div>
              </div>
              <div className="datiPvp_section  datiPvp_grey">
                <div style={{ fontWeight: 600 }}>Registro:</div>
                <div style={{ marginLeft: "2rem" }}>
                  {
                    asta.datiProcedura.proceduraGiudiziaria
                      .registro
                  }
                </div>
              </div>
              <div className="datiPvp_section ">
                <div style={{ fontWeight: 600 }}>ID Rito:</div>
                <div style={{ marginLeft: "2rem" }}>
                  {
                    asta.datiProcedura.proceduraGiudiziaria
                      .idRito
                  }
                </div>
              </div>
              <div className="datiPvp_section  datiPvp_grey">
                <div style={{ fontWeight: 600 }}>Rito:</div>
                <div style={{ marginLeft: "2rem" }}>
                  {
                    asta.datiProcedura.proceduraGiudiziaria
                      .registro
                  }
                </div>
              </div>
              <div className="datiPvp_section  ">
                <div style={{ fontWeight: 600 }}>Anno Procedura:</div>
                <div style={{ marginLeft: "2rem" }}>
                  {
                    asta.datiProcedura.proceduraGiudiziaria
                      .annoProcedura
                  }
                </div>
              </div>
              <div className="datiPvp_section  datiPvp_grey ">
                <div style={{ fontWeight: 600 }}>Numero Procedura:</div>
                <div style={{ marginLeft: "2rem" }}>
                  {
                    asta.datiProcedura.proceduraGiudiziaria
                      .numeroProcedura
                  }
                </div>
              </div>
            </AccordionDetails>
          </Accordion>

          {/************** SOGGETTI ************/}
          <Accordion className="datiPvp_accordition">
            <AccordionSummary expandIcon={<ExpandMore />}>
              <div style={{ fontWeight: 600 }}>SOGGETTI</div>
            </AccordionSummary>
            <AccordionDetails>
              {asta.datiProcedura.soggetti.map(
                (soggetto, index) => (
                  <div key={index} style={{ marginBottom: "15px" }}>
                    <div>{soggetto.tipo}</div>

                    <div className="datiPvp_section datiPvp_grey">
                      <div style={{ fontWeight: 600 }}>Nome: </div>
                      <div style={{ marginLeft: "2rem" }}>{soggetto?.nome}</div>
                    </div>
                    <div className="datiPvp_section ">
                      <div style={{ fontWeight: 600 }}>Cognome: </div>
                      <div style={{ marginLeft: "2rem" }}>
                        {soggetto.cognome}
                      </div>
                    </div>
                    <div className="datiPvp_section datiPvp_grey">
                      <div style={{ fontWeight: 600 }}>Cod. Fiscale: </div>
                      <div style={{ marginLeft: "2rem" }}>
                        {soggetto?.codiceFiscale}
                      </div>
                    </div>
                    <div className="datiPvp_section ">
                      <div style={{ fontWeight: 600 }}>Email: </div>
                      <div style={{ marginLeft: "2rem" }}>
                        {soggetto?.email}
                      </div>
                    </div>
                    <div className="datiPvp_section  datiPvp_grey ">
                      <div style={{ fontWeight: 600 }}>ID Anagrafica: </div>
                      <div style={{ marginLeft: "2rem" }}>
                        {soggetto.idAnagrafica}
                      </div>
                    </div>
                    <div className="datiPvp_section  ">
                      <div style={{ fontWeight: 600 }}>Cellulare: </div>
                      <div style={{ marginLeft: "2rem" }}>
                        {soggetto?.cellulare}
                      </div>
                    </div>
                    <div className="datiPvp_section datiPvp_grey ">
                      <div style={{ fontWeight: 600 }}>Telefono: </div>
                      <div style={{ marginLeft: "2rem" }}>
                        {soggetto?.telefono}
                      </div>
                    </div>
                    <div className="datiPvp_section  ">
                      <div style={{ fontWeight: 600 }}>
                        Procede alle operazioni di vendita:{" "}
                      </div>
                      <div style={{ marginLeft: "2rem" }}>
                        {soggetto?.procedeOpVendita === true ? "SI" : "NO"}
                      </div>
                    </div>
                    <div className="datiPvp_section datiPvp_grey ">
                      <div style={{ fontWeight: 600 }}>
                        Soggetto visita bene:{" "}
                      </div>
                      <div style={{ marginLeft: "2rem" }}>
                        {soggetto?.soggVisitaBene === true ? "SI" : "NO"}
                      </div>
                    </div>
                  </div>
                )
              )}
            </AccordionDetails>
          </Accordion>

          {/************** LOTTO ************/}
          <Accordion className="datiPvp_accordition">
            <AccordionSummary expandIcon={<ExpandMore />}>
              <div style={{ fontWeight: 600 }}>LOTTO</div>
            </AccordionSummary>
            <AccordionDetails>
              <div className="datiPvp_section datiPvp_grey ">
                <div style={{ fontWeight: 600 }}>ID Lotto: </div>
                <div style={{ marginLeft: "2rem" }}>
                  {asta.lotto.idLotto}
                </div>
              </div>
              <div className="datiPvp_section  ">
                <div style={{ fontWeight: 600 }}>Primo identificativo: </div>
                <div style={{ marginLeft: "2rem" }}>
                  {asta.lotto.primoIdentificativoLotto}
                </div>
              </div>
              <div className="datiPvp_section  datiPvp_grey">
                <div style={{ fontWeight: 600 }}>Codice: </div>
                <div style={{ marginLeft: "2rem" }}>
                  {asta.lotto.codice}
                </div>
              </div>
              <div className="datiPvp_section  ">
                <div style={{ fontWeight: 600 }}>Genere: </div>
                <div style={{ marginLeft: "2rem" }}>
                  {asta.lotto.genere}
                </div>
              </div>
              <div className="datiPvp_section  datiPvp_grey">
                <div style={{ fontWeight: 600 }}>Categoria: </div>
                <div style={{ marginLeft: "2rem" }}>
                  {asta.lotto.categoria}
                </div>
              </div>
              <div className="datiPvp_section  ">
                <div style={{ fontWeight: 600 }}>Descrizione IT: </div>
                <div style={{ marginLeft: "2rem" }}>
                  {asta.lotto.descrizioneIT}
                </div>
              </div>

              {asta.lotto.ubicazione && (
                <React.Fragment>
                  <div style={{ marginTop: "1rem" }}> Ubicazione</div>
                  <div className="datiPvp_section  datiPvp_grey">
                    <div style={{ fontWeight: 600 }}>Indirizzo: </div>
                    <div style={{ marginLeft: "2rem" }}>
                      {asta.lotto.ubicazione?.indirizzo}
                    </div>
                  </div>
                  <div className="datiPvp_section ">
                    <div style={{ fontWeight: 600 }}>Cap/Zip: </div>
                    <div style={{ marginLeft: "2rem" }}>
                      {asta.lotto.ubicazione?.capZipCode}
                    </div>
                  </div>
                  <div className="datiPvp_section datiPvp_grey">
                    <div style={{ fontWeight: 600 }}>Città: </div>
                    <div style={{ marginLeft: "2rem" }}>
                      {asta.lotto.ubicazione?.citta}
                    </div>
                  </div>
                  <div className="datiPvp_section ">
                    <div style={{ fontWeight: 600 }}>Nazione: </div>
                    <div style={{ marginLeft: "2rem" }}>
                      {asta.lotto.ubicazione?.nazione}
                    </div>
                  </div>
                  <div className="datiPvp_section datiPvp_grey">
                    <div style={{ fontWeight: 600 }}>Provincia: </div>
                    <div style={{ marginLeft: "2rem" }}>
                      {asta.lotto.ubicazione?.provincia}
                    </div>
                  </div>
                  <div className="datiPvp_section ">
                    <div style={{ fontWeight: 600 }}>Regione: </div>
                    <div style={{ marginLeft: "2rem" }}>
                      {asta.lotto.ubicazione?.regione}
                    </div>
                  </div>
                </React.Fragment>
              )}
            </AccordionDetails>
          </Accordion>

          {/************** BENI ************/}

          <Accordion className="datiPvp_accordition">
            <AccordionSummary expandIcon={<ExpandMore />}>
              <div style={{ fontWeight: 600 }}>BENI</div>
            </AccordionSummary>
            <AccordionDetails>
              {asta.lotto.beni.map((bene, index) => (
                <div key={index}>
                  <div className="datiPvp_section datiPvp_grey ">
                    <div style={{ fontWeight: 600 }}>ID Bene: </div>
                    <div style={{ marginLeft: "2rem" }}>{bene.idBene}</div>
                  </div>
                  <div className="datiPvp_section ">
                    <div style={{ fontWeight: 600 }}>
                      Primo Identificativo:{" "}
                    </div>
                    <div style={{ marginLeft: "2rem" }}>
                      {bene.primoIdentificativoBene}
                    </div>
                  </div>
                  <div className="datiPvp_section datiPvp_grey ">
                    <div style={{ fontWeight: 600 }}>Tipologia: </div>
                    <div style={{ marginLeft: "2rem" }}>{bene.tipologia}</div>
                  </div>
                  <div className="datiPvp_section  ">
                    <div style={{ fontWeight: 600 }}>Categoria: </div>
                    <div style={{ marginLeft: "2rem" }}>{bene.categoria}</div>
                  </div>
                  <div className="datiPvp_section datiPvp_grey ">
                    <div style={{ fontWeight: 600 }}>Descrizione IT: </div>
                    <div style={{ marginLeft: "2rem" }}>
                      {bene.descrizioneIT}
                    </div>
                  </div>

                  {bene?.ubicazione && (
                    <>
                      <div style={{ marginTop: "1.2rem" }}> Ubicazione </div>
                      <div className="datiPvp_section datiPvp_grey ">
                        <div style={{ fontWeight: 600 }}>Indirizzo: </div>
                        <div style={{ marginLeft: "2rem" }}>
                          {bene?.ubicazione?.indirizzo}
                        </div>
                      </div>

                      <div className="datiPvp_section  ">
                        <div style={{ fontWeight: 600 }}>Cap/Zip: </div>
                        <div style={{ marginLeft: "2rem" }}>
                          {bene?.ubicazione?.capZipCode}
                        </div>
                      </div>

                      <div className="datiPvp_section datiPvp_grey ">
                        <div style={{ fontWeight: 600 }}>Città: </div>
                        <div style={{ marginLeft: "2rem" }}>
                          {bene?.ubicazione?.citta}
                        </div>
                      </div>

                      <div className="datiPvp_section ">
                        <div style={{ fontWeight: 600 }}>Nazione: </div>
                        <div style={{ marginLeft: "2rem" }}>
                          {bene?.ubicazione?.nazione}
                        </div>
                      </div>

                      <div className="datiPvp_section datiPvp_grey ">
                        <div style={{ fontWeight: 600 }}>Provincia: </div>
                        <div style={{ marginLeft: "2rem" }}>
                          {bene?.ubicazione?.provincia}
                        </div>
                      </div>

                      <div className="datiPvp_section  ">
                        <div style={{ fontWeight: 600 }}>Regione: </div>
                        <div style={{ marginLeft: "2rem" }}>
                          {bene?.ubicazione?.regione}
                        </div>
                      </div>
                    </>
                  )}

                  {bene?.beneImmobile && (
                    <>
                      <div style={{ marginTop: "1.2rem" }}>Bene Immobile: </div>

                      <div className="datiPvp_section datiPvp_grey ">
                        <div style={{ fontWeight: 600 }}>Disponibilità: </div>
                        <div style={{ marginLeft: "2rem" }}>
                          {bene?.beneImmobile.disponibilita}
                        </div>
                      </div>

                      <div className="datiPvp_section  ">
                        <div style={{ fontWeight: 600 }}>Mq: </div>
                        <div style={{ marginLeft: "2rem" }}>
                          {bene?.beneImmobile?.mq}
                        </div>
                      </div>

                      <div className="datiPvp_section  datiPvp_grey ">
                        <div style={{ fontWeight: 600 }}>Vani: </div>
                        <div style={{ marginLeft: "2rem" }}>
                          {bene?.beneImmobile?.vani}
                        </div>
                      </div>

                      <div className="datiPvp_section  ">
                        <div style={{ fontWeight: 600 }}>Piano: </div>
                        <div style={{ marginLeft: "2rem" }}>
                          {bene?.beneImmobile?.piano}
                        </div>
                      </div>

                      {bene?.beneImmobile?.datiCatastali &&
                        bene?.beneImmobile?.datiCatastali.map(
                          (catasto, index) => (
                            <div key={index}>
                              <div style={{ marginTop: "1.2rem" }}>
                                Dati Catasto:
                              </div>

                              <div className="datiPvp_section datiPvp_grey ">
                                <div style={{ fontWeight: 600 }}>Sezione: </div>
                                <div style={{ marginLeft: "2rem" }}>
                                  {catasto.sezione}
                                </div>
                              </div>

                              <div className="datiPvp_section  ">
                                <div style={{ fontWeight: 600 }}>Foglio: </div>
                                <div style={{ marginLeft: "2rem" }}>
                                  {catasto.foglio}
                                </div>
                              </div>

                              <div className="datiPvp_section datiPvp_grey ">
                                <div style={{ fontWeight: 600 }}>
                                  Particella:{" "}
                                </div>
                                <div style={{ marginLeft: "2rem" }}>
                                  {catasto.particella}
                                </div>
                              </div>

                              <div className="datiPvp_section  ">
                                <div style={{ fontWeight: 600 }}>
                                  Sub Particella:{" "}
                                </div>
                                <div style={{ marginLeft: "2rem" }}>
                                  {catasto.subparticella}
                                </div>
                              </div>

                              <div className="datiPvp_section datiPvp_grey ">
                                <div style={{ fontWeight: 600 }}>
                                  Sub Alterno:{" "}
                                </div>
                                <div style={{ marginLeft: "2rem" }}>
                                  {catasto.subalterno}
                                </div>
                              </div>

                              <div className="datiPvp_section  ">
                                <div style={{ fontWeight: 600 }}>
                                  Sub Alterno 2:{" "}
                                </div>
                                <div style={{ marginLeft: "2rem" }}>
                                  {catasto.subalterno2}
                                </div>
                              </div>

                              <div className="datiPvp_section datiPvp_grey ">
                                <div style={{ fontWeight: 600 }}>
                                  Graffato:{" "}
                                </div>
                                <div style={{ marginLeft: "2rem" }}>
                                  {catasto.graffato}
                                </div>
                              </div>
                            </div>
                          )
                        )}

                      {!bene?.beneImmobile?.datiDenuncia && (
                        <>
                          <div style={{ marginTop: "1.2rem" }}>
                            Dati Denuncia
                          </div>

                          <div className="datiPvp_section datiPvp_grey ">
                            <div style={{ fontWeight: 600 }}> Tipologia: </div>
                            <div style={{ marginLeft: "2rem" }}>
                              {bene?.beneImmobile?.denuncia?.tipologia}{" "}
                            </div>
                          </div>

                          <div className="datiPvp_section  ">
                            <div style={{ fontWeight: 600 }}> Numero: </div>
                            <div style={{ marginLeft: "2rem" }}>
                              {bene?.beneImmobile?.denuncia?.numero}{" "}
                            </div>
                          </div>

                          <div className="datiPvp_section datiPvp_grey ">
                            <div style={{ fontWeight: 600 }}> Anno: </div>
                            <div style={{ marginLeft: "2rem" }}>
                              {bene?.beneImmobile?.denuncia?.anno}{" "}
                            </div>
                          </div>

                          <div className="datiPvp_section ">
                            <div style={{ fontWeight: 600 }}> Data: </div>
                            <div style={{ marginLeft: "2rem" }}>
                              {convertDateInIta(
                                bene?.beneImmobile?.denuncia?.data
                              )}{" "}
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}

                  {bene?.beneMobile && (
                    <>
                      <div style={{ marginTop: "1.2rem" }}>Bene Immobile: </div>

                      <div className="datiPvp_section   datiPvp_grey ">
                        <div style={{ fontWeight: 600 }}>
                          Modalità Consegna:{" "}
                        </div>
                        <div style={{ marginLeft: "2rem" }}>
                          {bene?.beneMobile?.modalitaConsegna}
                        </div>
                      </div>

                      <div style={{ marginTop: "1.2rem" }}>Bene Immobile: </div>

                      <div className="datiPvp_section  ">
                        <div style={{ fontWeight: 600 }}>
                          Modalità Consegna:{" "}
                        </div>
                        <div style={{ marginLeft: "2rem" }}>
                          {bene?.beneMobile?.luogoVisioneBene?.indirizzo}
                        </div>
                      </div>

                      <div className="datiPvp_section   datiPvp_grey ">
                        <div style={{ fontWeight: 600 }}>Cap/Zip: </div>
                        <div style={{ marginLeft: "2rem" }}>
                          {bene?.beneMobile?.luogoVisioneBene?.capZipCode}
                        </div>
                      </div>

                      <div className="datiPvp_section  ">
                        <div style={{ fontWeight: 600 }}>Città: </div>
                        <div style={{ marginLeft: "2rem" }}>
                          {bene?.beneMobile?.luogoVisioneBene?.citta}
                        </div>
                      </div>

                      <div className="datiPvp_section  datiPvp_grey ">
                        <div style={{ fontWeight: 600 }}>Provincia: </div>
                        <div style={{ marginLeft: "2rem" }}>
                          {bene?.beneMobile?.luogoVisioneBene?.provincia}
                        </div>
                      </div>

                      <div className="datiPvp_section  ">
                        <div style={{ fontWeight: 600 }}>Regione: </div>
                        <div style={{ marginLeft: "2rem" }}>
                          {bene?.beneMobile?.luogoVisioneBene?.regione}
                        </div>
                      </div>

                      <div className="datiPvp_section  datiPvp_grey ">
                        <div style={{ fontWeight: 600 }}>Nazione: </div>
                        <div style={{ marginLeft: "2rem" }}>
                          {bene?.beneMobile?.luogoVisioneBene?.nazione}
                        </div>
                      </div>
                    </>
                  )}

                  {bene?.beneAzienda && (
                    <>
                      <div style={{ marginTop: "1.2rem" }}>Bene Azienda: </div>
                      {bene?.beneAzienda?.categorieMerceologicheAteco.map(
                        (categoria) => (
                          <div
                            key={categoria}
                            className="datiPvp_section  datiPvp_grey "
                          >
                            <div style={{ fontWeight: 600 }}>
                              Categoria Merceologica:{" "}
                            </div>
                            <div style={{ marginLeft: "2rem" }}>
                              <div>{bene?.beneMobile?.categoria}</div>
                            </div>
                          </div>
                        )
                      )}
                    </>
                  )}
                </div>
              ))}
            </AccordionDetails>
          </Accordion>

          {/************** DATI VENDITA ************/}

          <Accordion className="datiPvp_accordition">
            <AccordionSummary expandIcon={<ExpandMore />}>
              <div style={{ fontWeight: 600 }}>DATI VENDITA</div>
            </AccordionSummary>
            <AccordionDetails>
              <div className="datiPvp_section datiPvp_grey ">
                <div style={{ fontWeight: 600 }}>Data ora vendita: </div>
                <div style={{ marginLeft: "2rem" }}>
                  {convertDateInIta(
                    asta.datiVendita.dataOraVendita
                  )}
                </div>
              </div>
              <div className="datiPvp_section ">
                <div style={{ fontWeight: 600 }}>Tipologia vendita: </div>
                <div style={{ marginLeft: "2rem" }}>
                  {asta.datiVendita.tipologiaVendita}
                </div>
              </div>
              <div className="datiPvp_section  datiPvp_grey">
                <div style={{ fontWeight: 600 }}>Modalità vendita: </div>
                <div style={{ marginLeft: "2rem" }}>
                  {asta.datiVendita.modalitaVendita}
                </div>
              </div>
              <div className="datiPvp_section ">
                <div style={{ fontWeight: 600 }}>Prezzo Base (€): </div>
                <div style={{ marginLeft: "2rem" }}>
                  {asta.datiVendita.prezzoValoreBase}
                </div>
              </div>
              <div className="datiPvp_section  datiPvp_grey">
                <div style={{ fontWeight: 600 }}>Offerta Minima (€): </div>
                <div style={{ marginLeft: "2rem" }}>
                  {asta.datiVendita.offertaMinima}
                </div>
              </div>
              <div className="datiPvp_section ">
                <div style={{ fontWeight: 600 }}>Rialzo Minimo (€): </div>
                <div style={{ marginLeft: "2rem" }}>
                  {asta.datiVendita.rialzoMinimo}
                </div>
              </div>
              <div className="datiPvp_section  datiPvp_grey">
                <div style={{ fontWeight: 600 }}>Deposito Cauzionale: </div>
                <div style={{ marginLeft: "2rem" }}>
                  {asta.datiVendita.depositoCauzionale}
                </div>
              </div>
              <div className="datiPvp_section ">
                <div style={{ fontWeight: 600 }}>Deposito Conto Spese: </div>
                <div style={{ marginLeft: "2rem" }}>
                  {asta.datiVendita.depositoContoSpese}
                </div>
              </div>
              <div className="datiPvp_section  datiPvp_grey">
                <div style={{ fontWeight: 600 }}>
                  Termine Presentazione Offerte:{" "}
                </div>
                <div style={{ marginLeft: "2rem" }}>
                  {convertDateInIta(
                    asta.datiVendita.terminePresentazioneOfferte
                  )}
                </div>
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion className="datiPvp_accordition">
            <AccordionSummary expandIcon={<ExpandMore />}>
              <div style={{ fontWeight: 600 }}>PAGAMENTO CONTRIBUITO</div>
            </AccordionSummary>
            <AccordionDetails>
              <div className="datiPvp_section  datiPvp_grey">
                <div style={{ fontWeight: 600 }}>Spesa Prenotata Debito: </div>
                <div style={{ marginLeft: "2rem" }}>
                  {asta?.datiVendita?.spesaPrenotataDebito ===
                  true
                    ? "SI"
                    : "NO"}
                </div>
              </div>

              <div className="datiPvp_section ">
                <div style={{ fontWeight: 600 }}>Contributo Non Dovuto: </div>
                <div style={{ marginLeft: "2rem" }}>
                  {asta?.datiVendita?.contributoNonDovuto ===
                  true
                    ? "SI"
                    : "NO"}
                </div>
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion className="datiPvp_accordition">
            <AccordionSummary expandIcon={<ExpandMore />}>
              <div style={{ fontWeight: 600 }}>SITI</div>
            </AccordionSummary>
            <AccordionDetails>
              {asta.siti.map((sito) => (
                <div key={sito.idSito} style={{ marginBottom: "10px" }}>
                  <div className="datiPvp_section  datiPvp_grey">
                    <div style={{ fontWeight: 600 }}>Id sito: </div>
                    <div style={{ marginLeft: "2rem" }}>{sito?.idSito}</div>
                  </div>

                  <div className="datiPvp_section  ">
                    <div style={{ fontWeight: 600 }}>Tipologia: </div>
                    <div style={{ marginLeft: "2rem" }}>{sito?.tipologia}</div>
                  </div>

                  <div className="datiPvp_section  datiPvp_grey">
                    <div style={{ fontWeight: 600 }}>Nominativo: </div>
                    <div style={{ marginLeft: "2rem" }}>{sito?.nominativo}</div>
                  </div>

                  <div className="datiPvp_section  ">
                    <div style={{ fontWeight: 600 }}>Urlo: </div>
                    <div style={{ marginLeft: "2rem" }}>{sito?.url}</div>
                  </div>
                </div>
              ))}
            </AccordionDetails>
          </Accordion>
        </div>
      </React.Fragment>
    );
  }

  return null;
};

export default DatiPvp;
