// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/********************** DATI PRESENTATORE *******************/

.modal_title_presentatore {
  font-size: 1.2rem;
}


/********************** DATI OFFERENTI   *******************/

.box_nessun_offerente{
  display: flex;
  align-items: center;
  justify-content: center;
  padding:3rem;
  border:1px solid black;
  border-radius: 0.5rem;
  margin-top: 1rem;
}

/********************** MODAL OFFERTA CARTACE *******************/

.modal_offerte_cartacee {
  position: absolute;
  top: 30%;
  left: 50%;
  margin-top: 10rem;
  width: 60vw;
  height: 80vh;
  transform: translate(-50%, -50%);
  overflow: scroll;
  background-color: white;
  border-radius: 1rem;
  outline: none;
  padding: 2rem;
  scroll-behavior: smooth;
}

.modal_offerte_cartacee_title {
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

@media screen and (max-width: 900px) {
  .modal_offerte_cartacee {
    position: absolute;
    width: auto;
    left: 0;
    right: 0;
    top: 15%;
    height: 60vh;
    overflow: auto;
    transform: translate(0);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Asta/ReferentePanel/OfferteCartacee/OfferteCartacee.css"],"names":[],"mappings":"AAAA,6DAA6D;;AAE7D;EACE,iBAAiB;AACnB;;;AAGA,4DAA4D;;AAE5D;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;EACZ,sBAAsB;EACtB,qBAAqB;EACrB,gBAAgB;AAClB;;AAEA,iEAAiE;;AAEjE;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,iBAAiB;EACjB,WAAW;EACX,YAAY;EACZ,gCAAgC;EAChC,gBAAgB;EAChB,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE;IACE,kBAAkB;IAClB,WAAW;IACX,OAAO;IACP,QAAQ;IACR,QAAQ;IACR,YAAY;IACZ,cAAc;IACd,uBAAuB;EACzB;AACF","sourcesContent":["/********************** DATI PRESENTATORE *******************/\n\n.modal_title_presentatore {\n  font-size: 1.2rem;\n}\n\n\n/********************** DATI OFFERENTI   *******************/\n\n.box_nessun_offerente{\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding:3rem;\n  border:1px solid black;\n  border-radius: 0.5rem;\n  margin-top: 1rem;\n}\n\n/********************** MODAL OFFERTA CARTACE *******************/\n\n.modal_offerte_cartacee {\n  position: absolute;\n  top: 30%;\n  left: 50%;\n  margin-top: 10rem;\n  width: 60vw;\n  height: 80vh;\n  transform: translate(-50%, -50%);\n  overflow: scroll;\n  background-color: white;\n  border-radius: 1rem;\n  outline: none;\n  padding: 2rem;\n  scroll-behavior: smooth;\n}\n\n.modal_offerte_cartacee_title {\n  font-size: 1.2rem;\n  font-weight: 500;\n  margin-bottom: 1rem;\n}\n\n@media screen and (max-width: 900px) {\n  .modal_offerte_cartacee {\n    position: absolute;\n    width: auto;\n    left: 0;\n    right: 0;\n    top: 15%;\n    height: 60vh;\n    overflow: auto;\n    transform: translate(0);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
