import React, { memo } from "react";

//hooks
import { useFormik } from "formik";

//@mui
import { Button, TextField, Grid, MenuItem } from "@mui/material";

//utils
import moment from "moment";
import * as Yup from "yup";
import NumberFormat from "react-number-format";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import accounting from "accounting";
import { Box } from "@mui/system";
import { useEffect } from "react";

const DatiOfferta = memo(({ datiOfferta, setDatiOfferta, nextPage }) => {
  const initialValues = {
    importoOfferta: accounting.unformat(datiOfferta.importoOfferta, ",") || "",
    importoCauzione:
      accounting.unformat(datiOfferta.importoCauzione, ",") || "",
    dataTermineDiPagamento: datiOfferta.termineDiPagamento || "",
    orarioTermineDiPagamento:
      moment(datiOfferta.termineDiPagamento).format("HH:mm") || "",
    tipoDepositoCauzione: datiOfferta.tipoDepositoCauzione || "",

    //Bonifico
    iban: datiOfferta.iban || "",
    cro: datiOfferta.cro || "",
    ibanCauzione: datiOfferta.ibanCauzione || "",
    dataCroMock: datiOfferta.dataCro || "",
    oraCro: datiOfferta.dataCro
      ? moment(datiOfferta.dataCro).format("HH:mm")
      : "",

    //Carta di credito
    tipoCarta: datiOfferta.tipoCarta || "",
    idTransazione: datiOfferta.idTransazione || "",
  };

  const validationSchema = Yup.object({
    importoOfferta: Yup.string().required("Inserisci l'importo dell'offerta!"),
    importoCauzione: Yup.string().required(
      "Inserisci l'importo della cauzione!"
    ),
    dataTermineDiPagamento: Yup.string().required(
      "Inserisci termine pagamento"
    ),
    orarioTermineDiPagamento: Yup.string().required(
      "Inserisci orario termine di pagamento"
    ),
    tipoDepositoCauzione: Yup.string().required("Inserisci tipo cauzione"),
    iban: Yup.string().when("tipoDepositoCauzione", {
      is: (tipoDepositoCauzione) => tipoDepositoCauzione === "bonifico",
      then: Yup.string().required("Inserisci iban destinatario"),
    }),
    cro: Yup.string().when("tipoDepositoCauzione", {
      is: (tipoDepositoCauzione) => tipoDepositoCauzione === "bonifico",
      then: Yup.string().required("Inserisci cro"),
    }),
    ibanCauzione: Yup.string(),
    dataCroMock: Yup.string().when("tipoDepositoCauzione", {
      is: (tipoDepositoCauzione) => tipoDepositoCauzione === "bonifico",
      then: Yup.string().required("Inserisci la data del bonifico"),
    }),
    oraCro: Yup.string().when("tipoDepositoCauzione", {
      is: (tipoDepositoCauzione) => tipoDepositoCauzione === "bonifico",
      then: Yup.string().required("Inserisci l'ora del bonifico"),
    }),

    tipoCarta: Yup.string().when("tipoDepositoCauzione", {
      is: (tipoDepositoCauzione) => tipoDepositoCauzione === "carta di credito",
      then: Yup.string().required("Inserisci il tipo della carta"),
    }),
  });

  const submitHandler = (values) => {
    const dateTermineDiPagamentoOnly = moment(
      values.dataTermineDiPagamento
    ).format();
    const termineDiPagamento = moment(dateTermineDiPagamentoOnly)
      .add(values.orarioTermineDiPagamento)
      .format();

    let dataCro = "";
    if (values.tipoDepositoCauzione === "bonifico") {
      const dateCROonly = moment(values.dataCroMock).format();
      dataCro = moment(dateCROonly).add(values.oraCro).format();
    }

    const newValues = { ...values };
    delete newValues.dataCroMock;
    delete newValues.oraCro;
    delete newValues.dataTermineDiPagamento;
    delete newValues.orarioTermineDiPagamento;

    const offerta = {
      ...newValues,
      importoCauzione: accounting.formatMoney(
        values.importoCauzione,
        "",
        "2",
        ".",
        ","
      ),
      importoOfferta: accounting.formatMoney(
        values.importoOfferta,
        "",
        "2",
        ".",
        ","
      ),
      termineDiPagamento,
      dataCro,
    };

    setDatiOfferta(offerta);
    nextPage();
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: submitHandler,
  });

  const { setFieldValue } = formik;

  useEffect(() => {
    if (
      formik.values.tipoDepositoCauzione !== datiOfferta.tipoDepositoCauzione
    ) {
      setFieldValue("iban", "");
      setFieldValue("cro", "");
      setFieldValue("ibanCauzione", "");
      setFieldValue("dataCroMock", "");
      setFieldValue("oraCro", "");
      setFieldValue("tipoCarta", "");
      setFieldValue("idTransazione", "");
    }
  }, [
    formik.values.tipoDepositoCauzione,
    datiOfferta.tipoDepositoCauzione,
    setFieldValue,
  ]);

  return (
    <form onSubmit={formik.handleSubmit} style={{ position: "relative" }}>
      <div style={{ marginBottom: "1rem" }}>
        Si prega di compilare i campi richiesti per inserire correttamente
        l’offerta.
      </div>

      <Grid container padding={3} spacing={3}>
        <Grid item xs={12} md={6}>
          <NumberFormat
            value={formik.values.importoOfferta}
            name={"importoOfferta"}
            customInput={TextField}
            type="text"
            prefix={"€"}
            thousandSeparator={true}
            label="Importo Offerta"
            onValueChange={({ value: v }) =>
              formik.setFieldValue("importoOfferta", v)
            }
            error={
              formik.touched.importoOfferta &&
              Boolean(formik.errors.importoOfferta)
            }
            helperText={
              formik.touched.importoOfferta && formik.errors.importoOfferta
            }
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <NumberFormat
            value={formik.values.importoCauzione}
            name={"importoCauzione"}
            customInput={TextField}
            type="text"
            prefix={"€"}
            thousandSeparator={true}
            label="Importo Cauzione"
            onValueChange={({ value: v }) =>
              formik.setFieldValue("importoCauzione", v)
            }
            error={
              formik.touched.importoCauzione &&
              Boolean(formik.errors.importoCauzione)
            }
            helperText={
              formik.touched.importoCauzione && formik.errors.importoCauzione
            }
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DatePicker
            label="Data Termine Pagamento"
            inputFormat="dd/MM/yyyy"
            name="dataTermineDiPagamento"
            toolbarPlaceholder="Termine di pagamento"
            id="dataTermineDiPagamento"
            value={formik.values.dataTermineDiPagamento}
            onChange={(val) =>
              formik.setFieldValue("dataTermineDiPagamento", val)
            }
            renderInput={(params) => (
              <TextField
                {...params}
                error={
                  formik.touched.dataTermineDiPagamento &&
                  Boolean(formik.errors.dataTermineDiPagamento)
                }
                fullWidth
                helperText={
                  formik.touched.dataTermineDiPagamento &&
                  formik.errors.dataTermineDiPagamento
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            type="time"
            label="Orario Termine Pagamento"
            name="orarioTermineDiPagamento"
            InputLabelProps={{ shrink: true }}
            value={formik.values.orarioTermineDiPagamento}
            onChange={formik.handleChange}
            helperText={
              formik.touched.orarioTermineDiPagamento &&
              formik.errors.orarioTermineDiPagamento
            }
            error={
              formik.touched.orarioTermineDiPagamento &&
              Boolean(formik.errors.orarioTermineDiPagamento)
            }
            fullWidth
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <TextField
            select
            className="select"
            label="Tipo Deposito Cauzione"
            name="tipoDepositoCauzione"
            value={formik.values.tipoDepositoCauzione}
            onChange={formik.handleChange}
            helperText={
              formik.touched.tipoDepositoCauzione &&
              formik.errors.tipoDepositoCauzione
            }
            error={
              formik.touched.tipoDepositoCauzione &&
              Boolean(formik.errors.tipoDepositoCauzione)
            }
            fullWidth
          >
            <MenuItem value={"bonifico"}>Bonifico</MenuItem>
            <MenuItem value={"carta di credito"}>Carta di credito</MenuItem>
            <MenuItem value={"fideiussione"}>Fideiussione</MenuItem>
            <MenuItem value={"altro"}>Altro</MenuItem>
          </TextField>
        </Grid>

        {/***********************  TIPO DEPOSITO  =========== BONIFICO **************************/}
        {formik.values.tipoDepositoCauzione === "bonifico" && (
          <>
            <Grid item xs={12} md={12}>
              <TextField
                id="ibanCauzione"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.ibanCauzione}
                label="IBAN restituzione cauzione"
                error={
                  formik.touched.ibanCauzione &&
                  Boolean(formik.errors.ibanCauzione)
                }
                helperText={
                  formik.touched.ibanCauzione && formik.errors.ibanCauzione
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="iban"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.iban}
                label="IBAN del Beneficiario"
                error={formik.touched.iban && Boolean(formik.errors.iban)}
                helperText={formik.touched.iban && formik.errors.iban}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="cro"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.cro}
                label="CRO"
                error={formik.touched.cro && Boolean(formik.errors.cro)}
                helperText={formik.touched.cro && formik.errors.cro}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DatePicker
                label="Data del Bonifico"
                inputFormat="dd/MM/yyyy"
                name="dataCroMock"
                toolbarPlaceholder="Data del Bonifico"
                id="dataCroMock"
                value={formik.values.dataCroMock}
                onChange={(val) => formik.setFieldValue("dataCroMock", val)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={
                      formik.touched.dataCroMock &&
                      Boolean(formik.errors.dataCroMock)
                    }
                    fullWidth
                    helperText={
                      formik.touched.dataCroMock && formik.errors.dataCroMock
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                type="time"
                label="Ora del Bonifico"
                name="oraCro"
                InputLabelProps={{ shrink: true }}
                value={formik.values.oraCro}
                onChange={formik.handleChange}
                helperText={formik.touched.oraCro && formik.errors.oraCro}
                error={formik.touched.oraCro && Boolean(formik.errors.oraCro)}
                fullWidth
              />
            </Grid>
          </>
        )}

        {/***********************  TIPO DEPOSITO  =========== CARTA DI CREDITO **************************/}

        {formik.values.tipoDepositoCauzione === "carta di credito" && (
          <>
            <Grid item xs={12} md={12}>
              <TextField
                select
                className="select"
                label="Tipo Carta"
                name="tipoCarta"
                value={formik.values.tipoCarta}
                onChange={formik.handleChange}
                fullWidth
                helperText={formik.touched.tipoCarta && formik.errors.tipoCarta}
                error={
                  formik.touched.tipoCarta && Boolean(formik.errors.tipoCarta)
                }
              >
                <MenuItem value={"visa"}>Visa</MenuItem>
                <MenuItem value={"mastercard"}>Mastercard</MenuItem>
                <MenuItem value={"diners"}>Diners</MenuItem>
                <MenuItem value={"american express"}>America Express</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                id="idTransazione"
                type="text"
                rows={4}
                onChange={formik.handleChange}
                value={formik.values.idTransazione}
                label="Riferimento del versamento"
                error={
                  formik.touched.idTransazione &&
                  Boolean(formik.errors.idTransazione)
                }
                helperText={
                  formik.touched.idTransazione && formik.errors.idTransazione
                }
                fullWidth
              />
            </Grid>
          </>
        )}

        {/***********************  TIPO DEPOSITO  =========== ALTRO**************************/}

        {formik.values.tipoDepositoCauzione === "altro" && (
          <>
            <Grid item xs={12} md={12}>
              <TextField
                id="idTransazione"
                type="text"
                rows={4}
                onChange={formik.handleChange}
                value={formik.values.idTransazione}
                label="Riferimento del versamento"
                error={
                  formik.touched.idTransazione &&
                  Boolean(formik.errors.idTransazione)
                }
                helperText={
                  formik.touched.idTransazione && formik.errors.idTransazione
                }
                fullWidth
              />
            </Grid>
          </>
        )}
      </Grid>
      <Box display={"flex"} justifyContent="flex-end">
        <Button type="submit" variant="contained">
          Prosegui
        </Button>
      </Box>
    </form>
  );
});

export default DatiOfferta;
