// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login_form {
  width: 100%;
  min-height: calc(100vh - 66px);
  
  display: flex;
  justify-content: center;
  align-items: center;
}

.login_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 350px;
  padding: 2rem;
  gap: 2.5rem;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.login_container p{
  padding:0;
  cursor: pointer;

}

.login_title {
  background-color: var(--success);
  padding: 1rem 0;
  border-radius: 0.5rem;
  width: 100%;
  color: white;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
}


.login_warning {
  background-color: var(--warning);
  padding: 1rem 0;
  border-radius: 0.5rem;
  width: 100%;
  color: white;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
}






.login_error {
  background-color: var(--error);
  padding: 1rem 0;
  border-radius: 0.5rem;
  width: 100%;
  color: white;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
}


@media screen and (max-width: 600px) {
  .login_container {
    margin: 1rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Login/Login.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,8BAA8B;;EAE9B,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;EACtB,YAAY;EACZ,aAAa;EACb,WAAW;EACX,uBAAuB;EACvB,qBAAqB;EACrB,qDAAqD;AACvD;;AAEA;EACE,SAAS;EACT,eAAe;;AAEjB;;AAEA;EACE,gCAAgC;EAChC,eAAe;EACf,qBAAqB;EACrB,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,gBAAgB;EAChB,kBAAkB;AACpB;;;AAGA;EACE,gCAAgC;EAChC,eAAe;EACf,qBAAqB;EACrB,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,gBAAgB;EAChB,kBAAkB;AACpB;;;;;;;AAOA;EACE,8BAA8B;EAC9B,eAAe;EACf,qBAAqB;EACrB,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,gBAAgB;EAChB,kBAAkB;AACpB;;;AAGA;EACE;IACE,YAAY;EACd;AACF","sourcesContent":[".login_form {\n  width: 100%;\n  min-height: calc(100vh - 66px);\n  \n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.login_container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  width: 350px;\n  padding: 2rem;\n  gap: 2.5rem;\n  background-color: white;\n  border-radius: 0.5rem;\n  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;\n}\n\n.login_container p{\n  padding:0;\n  cursor: pointer;\n\n}\n\n.login_title {\n  background-color: var(--success);\n  padding: 1rem 0;\n  border-radius: 0.5rem;\n  width: 100%;\n  color: white;\n  text-transform: uppercase;\n  font-weight: 600;\n  text-align: center;\n}\n\n\n.login_warning {\n  background-color: var(--warning);\n  padding: 1rem 0;\n  border-radius: 0.5rem;\n  width: 100%;\n  color: white;\n  text-transform: uppercase;\n  font-weight: 600;\n  text-align: center;\n}\n\n\n\n\n\n\n.login_error {\n  background-color: var(--error);\n  padding: 1rem 0;\n  border-radius: 0.5rem;\n  width: 100%;\n  color: white;\n  text-transform: uppercase;\n  font-weight: 600;\n  text-align: center;\n}\n\n\n@media screen and (max-width: 600px) {\n  .login_container {\n    margin: 1rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
