import React, { useState } from 'react'

//hooks
import { useCallback, } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../context/useAuth'

//components
import { Button, Typography, Avatar } from '@mui/material'

//@mui
import HomeIcon from '@mui/icons-material/Home';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';

//hooks 
import { useBreakPoints } from '../../utils/useBreakPoints'

//utils
import "./Header.css"
import userAvatar from "../../assets/userAvatar.png"
import { toast } from "react-toastify";
import { motion } from "framer-motion"


const Header = () => {

  const navigate = useNavigate()
  const [showMenu, setShowMenu] = useState(false)
  const { user, logoutHandler } = useAuth()
  const {md} = useBreakPoints()


  const navigateLoginHandler = useCallback(() => {
    navigate("/login")
  }, [navigate])

  const navigateHomeHandler = useCallback(() => {
    navigate("/")
  }, [navigate])

  const navigateSignUpHandler = useCallback(() => {
    navigate("/registrati")
  }, [navigate])


  const showMenuHandler = () => {
    setShowMenu(!showMenu)
  }


  const logout = () => {
    const response = logoutHandler()
    if (response.error) {
      toast.success("Logout effettuato")
      navigate("/")
    } else {
      console.log("Si è verificato un errore")
    }

  }



  return (
    <div className='header_container'>
      <div className='header_flexbox'>
        <div >
          <Typography variant="h1" fontSize={ md ? 25 : 20} fontWeight={600} color="white" ml={2} sx={{ cursor: "pointer" }} onClick={navigateHomeHandler}>
         { md ?  "ASTALEX | Vendite giudiziarie immobiliari" : "ASTALEX"}
          </Typography>
        </div>
        {!user ? <div className='header_login_button' >
          <Button variant='contained' color='neutral' onClick={navigateLoginHandler}>
            Login
          </Button>

          <Button variant='contained' sx={{marginLeft:"1rem"}} color='neutral' onClick={navigateSignUpHandler}>
            Registrati
          </Button>
        </div> 
        

        :
          <div className='header_avatar' onClick={showMenuHandler}>
            <Avatar src={userAvatar} style={{ width: "55px", height: "55px", cursor: "pointer" }} />

            {showMenu && <motion.div initial={{ opacity: 0 }} exit={{ opacity: 1 }} animate={{ opacity: 1 }} className="box_menu">
              <ul>
                <motion.li   whileHover={{ scale: 1.1 }}

                >
                  <HomeIcon />
                  <div className="text_menu">
                    Home
                  </div>

                </motion.li>

                <motion.li   whileHover={{ scale: 1.1 }} onClick={logout}>
                  <MeetingRoomIcon />
                  <div className="text_menu">
                    Logout
                  </div>
                </motion.li>
              </ul>
            </motion.div>
            }
          </div>
        }



      </div>


    </div>
  )
}

export default Header