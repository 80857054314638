/*
Il componente GaraPanel gestisce l'interfaccia per un partecipante in un'asta. 
Include un conto alla rovescia, informazioni sulla gara corrente, un form per 
effettuare offerte e una cronologia delle offerte precedenti. 
Utilizza varie funzioni per aggiornare le offerte e i log relativi al partecipante e al referente. 
Mostra inoltre un modal di conferma prima di inviare un'offerta. 
*/



import React from "react";
import { useState, useCallback } from "react";

//components
import { Grid, TextField, Button } from "@mui/material";
import CustomModal from "../../../utils/CustomModal/CustomModal";
import CountDown from "../../../utils/CountDown";
import CronologiaOffertePartecipante from "./CronologiaOffertePartecipante";

//hooks
import { useAsta } from "../../../context/useAsta";

//utils
import {
  updateRilanciAstaSincrona,
  updateLogOffertePartecipante,
  updateLogOfferteReferenti,
  updateRilanciAstaAsincronaNormal,
  updateRilanciAstaAsincronaNotNormal,
} from "../../../services/firebase";
import moment from "moment";
import NumberFormat from "react-number-format";
import { convertDateInIta, getDateAstalex } from "../../../utils/Utils";
import "./PartecipantePanel.css";
import accounting from "accounting";
import { toast } from "react-toastify";

import { sendNotifications } from "../../../services/sendInfoRilanci"

const GaraPanel = () => {
  // Hook per accedere allo stato dell'asta e impostare il rilancio e il modal
  const { asta, onTimeLeft, aulaVirtuale } = useAsta();
  const [rilancio, setRilancio] = useState("");
  const [modalRilancio, setModalRilancio] = useState(false);

  // Funzione per chiudere il modal di conferma
  const closeModalHandler = useCallback(() => {
    setModalRilancio(false);
  }, []);


  // Gestisce la sottomissione del form di rilancio
  const submitHandler = (e) => {
    e.preventDefault();
    if (Number(asta.rilancioMinimo) + Number(asta.miglioreOfferta) > rilancio)
      return toast.error("Attenzione l'offerta è  troppo bassa");

    setModalRilancio(true);
  };








  // @todoprod rilancio - mandare pec e sms a tutti
  // Funzioni per gestire il rilancio in aste asincrone 
  const rilancioHandlerAsincrona = async () => {
    /****************************CHECK SE IL TEMPO E' SCADUTO ********************************************/
    if (onTimeLeft === 0) 
        return toast.error("Attenzione il tempo è scaduto!");

    try {
        const response = await getDateAstalex();
        const finalInterval = moment(asta.dataFineVenditaRaw).subtract(15, "m");

        /*************** CHECK SE L'ASTA RIMANE NELL'ULTIMO INTERVALLO DI TEMPO **********************/

        if (
          moment(response.data.date).isBetween(
            finalInterval,
            asta.dataFineVenditaRaw
          )
        ) {
          if (asta.maxRilanci < 8) {
            //Timer in ms
            const timerMs = asta.timer * 60000;

            // timeToAdd rappresenta uno slot di tempo, ovvero l'ultimo timer dell'asta MENO il tempo rimasto 
            const timeToAdd = timerMs - onTimeLeft;

            //Tempo Nuovo da aggiungere al Db.
            const newDate = moment(asta.dataFineVenditaRaw)
              .add(timeToAdd)
              .format("YYYY-MM-DDTHH:mm:ss");

            const newRilanci = [
              ...asta.rilanci,
              {
                rilancio,
                offerente: aulaVirtuale.nomeUtente,
                dataOfferta: response.data.date,
              },
            ];

            await updateRilanciAstaAsincronaNotNormal(
              asta.idInserzioneEspVendita,
              rilancio,
              newRilanci,
              newDate,
              aulaVirtuale.nomeUtente
            );

            const stringaOffertaPartecipante = `L'offerente ${
              aulaVirtuale.nomeUtente
            } ha offerto ${accounting.formatMoney(
              rilancio,
              "€"
            )}  in data  ${convertDateInIta(response.data.date)}`;

            await updateLogOffertePartecipante(
              asta.idInserzioneEspVendita,
              stringaOffertaPartecipante
            );

            const stringaOffertaReferente = `L'offerente ${
              aulaVirtuale.nomeReale
            }(${aulaVirtuale.nomeUtente})  ha offerto ${accounting.formatMoney(
              rilancio,
              "€"
            )}  in data  ${convertDateInIta(response.data.date)}`;

            await updateLogOfferteReferenti(
              asta.idInserzioneEspVendita,
              stringaOffertaReferente
            );




            toast.success("Complimenti hai rilanciato per questa asta!");
          } else {
            toast.warning("Il numero di rilanci è superiore  ");
          }
      } 
      
      
      // in tal caso non siamo nell'ultimo intervallo di tempo, quindi non è necessario andare a cambiare la data di vendita
      // e a tracciare il numero max di rilanci
      else {
        const newRilanci = [
          ...asta.rilanci,
          {
            rilancio,
            offerente: aulaVirtuale.nomeUtente,
            dataOfferta: response.data.date,
          },
        ];

        await updateRilanciAstaAsincronaNormal(
          asta.idInserzioneEspVendita,
          rilancio,
          newRilanci,
          aulaVirtuale.nomeUtente
        );

        const stringaOffertaPartecipante = `L'offerente ${
          aulaVirtuale.nomeUtente
        } ha offerto ${accounting.formatMoney(
          rilancio,
          "€"
        )}  in data  ${convertDateInIta(response.data.date)}`;
        await updateLogOffertePartecipante(
          asta.idInserzioneEspVendita,
          stringaOffertaPartecipante
        );

        const stringaOffertaReferente = `L'offerente ${
          aulaVirtuale.nomeReale
        }(${aulaVirtuale.nomeUtente})  ha offerto ${accounting.formatMoney(
          rilancio,
          "€"
        )}  in data  ${convertDateInIta(response.data.date)}`;

        await updateLogOfferteReferenti(
          asta.idInserzioneEspVendita,
          stringaOffertaReferente
        );

        toast.success("Complimenti hai rilanciato per questa asta!");
      }

      setModalRilancio(false);

      sendNotifications(asta.idInserzioneEspVendita, rilancio)


    } catch (error) {
      toast.error("Si è verificato un errore");
      console.log(error);
    }
  };



  // @todoprod rilancio - mandare pec e sms a tutti
  // Funzioni per gestire il rilancio in aste sincrone
  const rilancioHandlerSincrona = async () => {
    if (
      asta.datiVendita.modalitaVendita ===
        "SINCRONA MISTA" ||
      asta.datiVendita.modalitaVendita ===
        "SINCRONA TELEMATICA"
    ) {
      //Check se il controllo è scaduto
      if (onTimeLeft === 0)
        return toast.error("Attenzione il tempo è scaduto!");

      //Timer in ms
      const timerMs = asta.timer * 60000;
      const timeToAdd = timerMs - onTimeLeft;

      //Tempo Nuovo da aggiungere al Db.
      const newDate = moment(asta.dataFineVenditaRaw)
        .add(timeToAdd)
        .format("YYYY-MM-DDTHH:mm:ss");

      const response = await getDateAstalex();

      const newRilanci = [
        ...asta.rilanci,
        {
          rilancio,
          offerente: aulaVirtuale.nomeUtente,
          dataOfferta: response.data.date,
        },
      ];

      await updateRilanciAstaSincrona(
        asta.idInserzioneEspVendita,
        rilancio,
        newRilanci,
        newDate,
        aulaVirtuale.nomeUtente
      );

      const stringaOffertaPartecipante = `L'offerente ${
        aulaVirtuale.nomeUtente
      } ha offerto ${accounting.formatMoney(
        rilancio,
        "€"
      )}  in data  ${convertDateInIta(response.data.date)}`;
      await updateLogOffertePartecipante(
        asta.idInserzioneEspVendita,
        stringaOffertaPartecipante
      );

      const stringaOffertaReferente = `L'offerente ${aulaVirtuale.nomeReale}(${
        aulaVirtuale.nomeUtente
      })  ha offerto ${accounting.formatMoney(
        rilancio,
        "€"
      )}  in data  ${convertDateInIta(response.data.date)}`;

      await updateLogOfferteReferenti(
        asta.idInserzioneEspVendita,
        stringaOffertaReferente
      );

      toast.success("Complimenti hai rilanciato per questa asta!");

      sendNotifications(asta.idInserzioneEspVendita, rilancio)

    }

    setModalRilancio(false);
  };




  // Rendering del componente
  return (
    <>
      <div className="gara_container">
        <div className="gara_panel_title"> GARA IN CORSO </div>

        <div className="gara_box_info">
          <Grid container>
            <Grid item padding={2} md={6} xs={12}>
              <div>
                INIZIO:{" "}
                <div style={{ fontWeight: 600, display: "inline" }}>
                  {asta.datiVendita.modalitaVendita ===
                  "ASINCRONA TELEMATICA"
                    ? convertDateInIta(asta.dataInizioVendita)
                    : convertDateInIta(asta.dataFineVendita)}
                </div>
              </div>
            </Grid>
            <Grid item padding={2} md={6} xs={12}>
              <div>
                FINE:{" "}
                <div style={{ fontWeight: 600, display: "inline" }}>
                  {" "}
                  {convertDateInIta(asta.dataFineVenditaRaw)}
                </div>
              </div>
            </Grid>
            <Grid item padding={2} md={6} xs={12}>
              <div>
                TRIBUNALE:{" "}
                <div style={{ fontWeight: 600, display: "inline" }}>
                  {
                    asta.datiProcedura.proceduraGiudiziaria.tribunale &&
                    asta.datiProcedura.proceduraGiudiziaria.tribunale
                  }
                </div>
              </div>
            </Grid>
            <Grid item padding={2} md={6} xs={12}>
              <div>
                RITO:{" "}
                <div style={{ fontWeight: 600, display: "inline" }}>
                  {
                    asta.datiProcedura.proceduraGiudiziaria.rito
                  }
                </div>
              </div>
            </Grid>
          </Grid>
        </div>

        <Grid container>
          {/************  INFO OFFERTE  **********/}
          <Grid item md={6} xs={12}>
            <div className="gara_text_info">
              Tempo rimanente:{" "}
              <div style={{ fontWeight: 600, display: "inline" }}>
                <CountDown />
              </div>
            </div>

            <div className="gara_text_info">
              {" "}
              Prezzo corrente:{" "}
              <div style={{ fontWeight: 600, display: "inline" }}>
                {" "}
                {accounting.formatMoney(asta.miglioreOfferta, "€")}
              </div>
            </div>
            <div className="gara_text_info">
              {" "}
              Rialzo minimo :{" "}
              <div style={{ fontWeight: 600, display: "inline" }}>
                {" "}
                {accounting.formatMoney(asta.rilancioMinimo, "€")}
              </div>
            </div>

            <div className="gara_text_info">
              {" "}
              Importo minimo :{" "}
              <div style={{ fontWeight: 600, display: "inline" }}>
                {accounting.formatMoney(
                  Number(asta.rilancioMinimo) + Number(asta.miglioreOfferta),
                  "€"
                )}
              </div>
            </div>
            <div className="gara_text_info">
              {" "}
              Miglior Offerente :{" "}
              <div style={{ fontWeight: 600, display: "inline" }}>
                {asta.miglioreOfferente}
              </div>
            </div>

            {asta.datiVendita.modalitaVendita ===
              "ASINCRONA TELEMATICA" && (
              <div className="gara_text_info">
                {" "}
                Numero di rilanci effettuati:{" "}
                <div style={{ fontWeight: 600, display: "inline" }}>
                  {asta.maxRilanci}
                </div>
              </div>
            )}

            <form className="gara_form_container" onSubmit={submitHandler}>
              {onTimeLeft > 0 ? (
                <>
                  <NumberFormat
                    value={rilancio}
                    name={"rilancio"}
                    customInput={TextField}
                    type="text"
                    prefix={"€"}
                    thousandSeparator={true}
                    label="Rilancio"
                    onValueChange={({ value: v }) => setRilancio(v)}
                  />
                  <Button type="submit" variant="contained">
                    Offri
                  </Button>
                </>
              ) : (
                <div style={{ color: "red" }}>
                  Attenzione il tempo è scaduto!
                </div>
              )}
            </form>
          </Grid>

          {/************  CRONOLOGIA OFFERTE  *************/}
          <Grid item md={6} xs={12}>
            <CronologiaOffertePartecipante />
          </Grid>
        </Grid>
      </div>

      <CustomModal
        title={
          "Sei sicuro di voler offrire " + accounting.formatMoney(rilancio, "€")
        }
        content="Attenzione, una volta confermato non potrai più annullare l'operazione."
        openModal={modalRilancio}
        handleClose={closeModalHandler}
        submitModal={
          asta.datiVendita.modalitaVendita ===
          "ASINCRONA TELEMATICA"
            ? rilancioHandlerAsincrona
            : rilancioHandlerSincrona
        }
        color={"green"}
      />
    </>
  );
};

export default GaraPanel;
