// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.maintenance_container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap:5rem;
  align-items: center;
  justify-content: center;
}

.maintenance_title{
    font-weight: 500;
    font-size: xx-large;
    color:var(--main-color)
}

.maintenance_container img {
  object-fit: contain;
  height: 500px;
}

.maintenance_container img {
  object-fit: contain;
  height: 500px;
}

@media screen and (max-width: 600px) {
  .maintenance_container img {
    object-fit: contain;
    height: 200px;
  }


  .maintenance_title{
    font-weight: 500;
    font-size: large;
    color:var(--main-color)
}

}
`, "",{"version":3,"sources":["webpack://./src/pages/Maintenance/Maintenance.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,QAAQ;EACR,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;IACnB;AACJ;;AAEA;EACE,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE;IACE,mBAAmB;IACnB,aAAa;EACf;;;EAGA;IACE,gBAAgB;IAChB,gBAAgB;IAChB;AACJ;;AAEA","sourcesContent":[".maintenance_container {\n  width: 100%;\n  height: 100vh;\n  display: flex;\n  flex-direction: column;\n  gap:5rem;\n  align-items: center;\n  justify-content: center;\n}\n\n.maintenance_title{\n    font-weight: 500;\n    font-size: xx-large;\n    color:var(--main-color)\n}\n\n.maintenance_container img {\n  object-fit: contain;\n  height: 500px;\n}\n\n.maintenance_container img {\n  object-fit: contain;\n  height: 500px;\n}\n\n@media screen and (max-width: 600px) {\n  .maintenance_container img {\n    object-fit: contain;\n    height: 200px;\n  }\n\n\n  .maintenance_title{\n    font-weight: 500;\n    font-size: large;\n    color:var(--main-color)\n}\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
