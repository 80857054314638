import React from 'react'
import imageManutenzione from "../../assets/manutenzione.png"
import "./Maintenance.css"


const Maintenance = () => {




  return (
    <div className='maintenance_container'>

        <div className='maintenance_title'> SITO ATTUALMENTE  IN MANUTENZIONE </div>
        <img src={imageManutenzione} alt="Manutenzione Astalex"  />


    </div>
  )
}

export default Maintenance