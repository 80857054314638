/*
Il componente CronologiaOffertePartecipante in React è progettato per visualizzare la cronologia delle offerte 
effettuate da un partecipante in un'asta specifica. A differenza del componente CronologiaGara, 
che mostra un log generale degli eventi dell'asta, CronologiaOffertePartecipante si concentra esclusivamente 
sulle offerte effettuate da un partecipante. 
Utilizza useParams per ottenere l'ID dell'asta e recupera i dati dalla collezione "LogOffertePartecipanti" su Firebase. 
Il componente non ha un'opzione per espandere o comprimere la visualizzazione; mostra direttamente la lista delle 
offerte effettuate dal partecipante in quella specifica asta.
*/

import React, { useState,memo,useEffect, } from "react";

import { useParams } from "react-router-dom";

//firebase
import { db } from "../../../services/firebase";


const CronologiaOffertePartecipante = memo(() => {


  const [data, setData] = useState(null);
  const params = useParams();

  useEffect(() => {
    db.collection("LogOffertePartecipanti")
      .doc(params.id)
      .onSnapshot((docRef) => {
        setData(docRef.data());

      });

    return () => {
      setData([]);
    };
  }, [setData, params.id]);

  return (
    <React.Fragment>
      <div className="partecipante_dropdown_container">
        <div style={{ fontWeight: 500 }}> Cronologia Offerte</div>
      </div>

        <div className="cronologia_gara_offerte_box">
          {data &&
            data?.logs?.reverse().map((log, index) => <div key={index} style={{marginTop:"0.3rem"}}>{log}</div>)}
        </div>
  
    </React.Fragment>
  );
});

export default CronologiaOffertePartecipante;
