/*
Il componente CronologiaOfferteReferente è progettato per visualizzare la cronologia delle offerte 
effettuate in un'asta dal punto di vista del referente. 
Utilizza l'hook useParams per ottenere l'ID dell'asta dall'URL e recupera i dati dalla collezione "LogOfferteReferenti" su Firebase. 
Il componente visualizza un elenco di log che rappresentano le azioni effettuate nel contesto dell'asta, 
come le offerte fatte dai partecipanti. La visualizzazione avviene in ordine cronologico inverso, fornendo un resoconto dettagliato e aggiornato delle attività all'interno dell'asta.
*/


import React, { useState, memo, useEffect } from "react";
import { useParams } from "react-router-dom";

//firebase
import { db } from "../../../services/firebase";

const CronologiaOfferteReferente = memo(() => {
  const [data, setData] = useState(null);
  const params = useParams();

  useEffect(() => {
    db.collection("LogOfferteReferenti")
      .doc(params.id)
      .onSnapshot((docRef) => {
        setData(docRef.data());
      });

    return () => {
      setData([]);
    };
  }, [setData, params.id]);

  return (
    <React.Fragment>
      <div className="partecipante_dropdown_container">
        <div style={{ fontWeight: 500 }}> Cronologia Offerte</div>
      </div>

      <div className="cronologia_gara_offerte_box">
        {data &&
          data?.logs?.reverse().map((log, index) => (
            <div key={index} style={{ marginTop: "0.3rem" }}>
              {log}
            </div>
          ))}
      </div>
    </React.Fragment>
  );
});

export default CronologiaOfferteReferente;
