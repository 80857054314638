import React from "react";
import { useState } from "react";
import { memo } from "react";

//components
import DatiOfferta from "./DatiOfferta";
import DatiPresentatore from "./DatiPresentatore";
import DatiOfferente from "./DatiOfferente";
import DatiOfferenti from "./DatiOfferenti";

//@mui
import { Modal, Box } from "@mui/material";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import Tab from "@mui/material/Tab";
import TabList from "@mui/lab/TabList";

//hooks
import { useParams } from "react-router-dom";

//utils
import "./OfferteCartacee.css";
import randomId from "random-id";
import { addBustaCartacea } from "../../../../services/firebase";
import { toast } from "react-toastify";

const ModalOfferteCartacee = memo(({ open, onClose }) => {
  const params = useParams();
  const [tabs, setTabs] = useState([]);
  const [tabIndex, setTabIndex] = useState(3);
  const [selectedTab, setSelectedTab] = useState("0");

  //Dati offerta e gestione degli offerenti.
  const [datiOfferta, setDatiOfferta] = useState("");

  //Dati Presentatore
  const [datiPresentatore, setDatiPresentatore] = useState("");
  const [offerenti, setOfferenti] = useState([]);

  //Aggiungi un offerente!
  const aggiungiOfferente = (offerente) => {
    setOfferenti((prevValue) => {
      return [...prevValue, offerente];
    });
  };

  //Aggiorna offerente!
  const aggiornaOfferente = (offerente, tabValue) => {
    const newOfferenti = offerenti.map((offerenteArray, index) => {
      if (tabValue === index) {
        return offerente;
      } else {
        return offerenteArray;
      }
    });

    setOfferenti(newOfferenti);
  };

  //Aggiungi nuovo tab
  const createNewOfferente = () => {
    const newTab = {
      value: `${tabIndex}`,
      label: `Offerente ${tabIndex - 2}`,
    };

    setTabs([...tabs, newTab]);
    setTabIndex(tabIndex + 1);
  };

  //Salva dati Offerta su firestore
  const salvaDatiOfferta = async () => {
    var len = 10;
    var pattern = "aA0";
    var id = randomId(len, pattern);

    const data = {
      credenziali: {
        username: id,
      },
      isTelematica: false,
      offertaValida: true,
      cauzioneVersata: true,
      idInserzioneEspVendita: params.id,
      offertaRequest: {
        offIntegrale: {
          idOfferta: "",
          presentatore: datiPresentatore.presentatore,
          offerenti: offerenti,
          offerta: datiOfferta,
        },
      },
    };

    try {
      await addBustaCartacea(data);
      
      toast.success("E' stata aggiunta una nuova busta cartacea!");
    } catch (error) {
      console.log(error);
      toast.error("Si è verificato un errore!");
    }
    onClose();
  };

  const handleChange = (e, newValue) => {
    setSelectedTab(newValue);
  };

  const nextPage = () => {
    setSelectedTab((prevValue) => String(Number(prevValue) + 1));
  };

  const prevPage = () => {
    setSelectedTab((prevValue) => String(Number(prevValue) - 1));
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box className="modal_offerte_cartacee">
        <div className="modal_offerte_cartacee_title">
          Aggiungi Offerta Cartacea
        </div>
        <TabContext value={selectedTab}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange}>
              <Tab label="Dati Offerta" value="0" />
              {datiOfferta && <Tab label="Dati Presentatore" value="1" />}
              {datiPresentatore && <Tab label="Dati Offerenti" value="2" />}
              {tabs.map((tab) => (
                <Tab label={tab.label} key={tab.value} value={tab.value} />
              ))}
            </TabList>
          </Box>

          <TabPanel value="0">
            <DatiOfferta
              datiOfferta={datiOfferta}
              setDatiOfferta={setDatiOfferta}
              nextPage={nextPage}
            />
          </TabPanel>

          <TabPanel value="1">
            <DatiPresentatore
              datiPresentatore={datiPresentatore}
              setDatiPresentatore={setDatiPresentatore}
              nextPage={nextPage}
              prevPage={prevPage}
            />
          </TabPanel>

          <TabPanel value="2">
            <DatiOfferenti
              offerenti={offerenti}
              nextPage={nextPage}
              prevPage={prevPage}
              createNewOfferente={createNewOfferente}
              salvaDatiOfferta={salvaDatiOfferta}
            />
          </TabPanel>

          {tabs.map((tab) => (
            <TabPanel value={tab.value} key={tab.value}>
              <DatiOfferente
                offerenti={offerenti}
                aggiungiOfferente={aggiungiOfferente}
                aggiornaOfferente={aggiornaOfferente}
                tabValue={tab.value}
                setSelectedTab={setSelectedTab}
              />
            </TabPanel>
          ))}
        </TabContext>
      </Box>
    </Modal>
  );
});

export default ModalOfferteCartacee;
